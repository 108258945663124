import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Link, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { Recipient, Bundle, BillOfMaterialsComponent, Permission, Module, CampaignShippingDestination, CampaignOrderLimit, Product, ApiMetadata } from '../../../types'
import RecipientEditor from '../../../components/Profile/RecipientEditor'
import {
  deleteRecipientAddress,
  editRecipientAddress, resetRecipientError, resetRecipientMessage
} from '../../../store/reducers/api/recipientReducer'
import {
  addCampaignRecipient, getCampaignBundles,
  getCampaignById, getCampaignRecipients, createCampaignBundle, resetCampaignMessage,
  createCampaignShippingDestination,
  resetCampaignMessageShippingDestinations
} from '../../../store/reducers/api/campaignReducer'
import Progress from '../../../components/loaders/Progress'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import BillOfMaterialSelector from '../../../components/BillOfMaterial/BillOfMaterialSelector'
import {
  deleteBundleById, resetBundleError,
  resetBundleMessage, updateBundleById
} from '../../../store/reducers/api/bundleReducer'
import * as userRoles from '../../../constants/userRoles'
import Pagination from '../../../components/Pagination'
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal'
import OrderAddressEditor from '../../../components/editors/OrderAddressEditor'
import ItemListView from '../../../components/ItemListView/ItemListView'
import BundleEditor from '../../../components/Orders/BundleEditor'
import { setToast } from '../../../store/reducers/toastReducer'
import { resetPictureMessage } from '../../../store/reducers/api/pictureReducer'
import { dismissModal } from '../../../utils/dismissModal'
import Terms from '../../../components/Terms'
import { getCompanyLegalTexts } from '../../../store/reducers/api/companyReducer'
import * as appModules from '../../../constants/appModules'
import hasPermission from '../../../utils/checkPermissions'
import { READ, READWRITE } from '../../../constants/permissions'
import BulkImporter from '../../../components/Orders/BulkImporter'
import { useCallbackPrompt } from '../../../utils/hooks/useCallbackPrompt'
import DialogBox from '../../../components/modals/DialogBox'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import { BreadcrumbIcon } from '../../../components/icons/BreadcrumbIcon'
import OrderLimit from '../../../components/Orders/OrderLimit/OrderLimit'
import {
  BUNDLE_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_INACTIVE_MESSAGE, CAMPAIGN_ORDER_LIMIT_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_QUOTA_EXCEEDED_MESSAGE, RECIPIENT_ADDITION_SUCCESS_MESSAGE, SHIPPING_DESTINATION_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'
import ShippingDestinationEditor from '../../../components/editors/ShippingDestinationEditor'
import { deleteCampaignShippingDestination, resetCampaignShippingDestinationError, resetCampaignShippingDestinationMessage } from '../../../store/reducers/api/campaignShippingDestinationReducer'
import { deleteCampaignOrderLimit, resetCampaignOrderLimitError, resetCampaignOrderLimitMessage } from '../../../store/reducers/api/campaignOrderLimitReducer'
import DetailsLoader from './DetailsLoader'
import { openModal } from '../../../utils/openModal'
import useDebounce from '../../../utils/hooks/useDebounce'
import BundleSelector from '../../../components/Orders/BundleSelector'
import AdditionalArticleViewer from '../../../components/Orders/AdditionalArticleViewer'
import Cart from '../../../components/Orders/Cart'
import GreetingCards from '../../../components/Orders/GreetingCards'
import AddressPicker from '../../../components/Recipients/AddressPicker'
import { debounce } from '../../../utils/debounce'
import { loadCostCenterOptions } from '../../../utils/loadCostCenterOptions'
import CompanyService from '../../../services/api/CompanyService'

dayjs.extend(localizedFormat)

const CampaignDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)
  const isLoadingCampaignRecipients = useAppSelector((state) => state.apiCampaign.isLoadingRecipients)
  const isLoadingCampaignBundles = useAppSelector((state) => state.apiCampaign.isLoadingBundles)
  const isLoadingCampaignOrderLimits = useAppSelector((state) => state.apiCampaign.isLoadingOrderLimits)
  const isLoadingCampaignShippingDestinations = useAppSelector((state) => state.apiCampaign.isLoadingShippingDestinations)
  const recipient = useAppSelector((state) => state.apiCampaign.recipient)
  const recipients = useAppSelector((state) => state.apiCampaign.recipients)
  const recipientsMetadata = useAppSelector((state) => state.apiCampaign.metadata)
  const recipientMessage = useAppSelector((state) => state.apiRecipient.message)
  const isLoadingRecipients = useAppSelector((state) => state.apiRecipient.isLoading)
  const recipientError = useAppSelector((state) => state.apiRecipient.error)
  const campaign = useAppSelector((state) => state.apiCampaign.campaign)
  const campaignOrderLimits = useAppSelector((state) => state.apiCampaign.campaign?.campaignOrderLimits)
  const messageCampaign = useAppSelector((state) => state.apiCampaign.message)
  const messageCampaignShippingDestination = useAppSelector((state) => state.apiCampaign.shippingDestinationsMessage)
  const bundle = useAppSelector((state) => state.apiCampaign.bundle)
  const bundles = useAppSelector((state) => state.apiCampaign.bundles)
  const bundlesMetadata = useAppSelector((state) => state.apiCampaign.bundlesMetadata)
  const isLoadingBundle = useAppSelector((state) => state.apiBundle.isLoading)
  const messageBundle = useAppSelector((state) => state.apiBundle.message)
  const bundleError = useAppSelector((state) => state.apiBundle.error)
  const messagePicture = useAppSelector((state) => state.apiPicture.message)
  const isLoadingPicture = useAppSelector((state) => state.apiPicture.isLoading)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)
  const isLoadingShippingDestination = useAppSelector((state) => state.apiCampaignShippingDestination.isLoading)
  const campaignShippingDestinationMessage = useAppSelector((state) => state.apiCampaignShippingDestination.message)
  const campaignShippingDestinationError = useAppSelector((state) => state.apiCampaignShippingDestination.error)
  const isLoadingOrderLimit = useAppSelector((state) => state.apiCampaignOrderLimit.isLoading)
  const campaignOrderLimitMessage = useAppSelector((state) => state.apiCampaignOrderLimit.message)
  const campaignOrderLimitError = useAppSelector((state) => state.apiCampaignOrderLimit.error)

  const storedRecipientPage = 1
  const storedRecipientPerPage = 5

  const [initialRecipient, setInitialRecipient] = useState<Recipient>({
    companyName: '',
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    street: '',
    zip: '',
    addressAddition: '',
    costCenter: ''
  })
  const [initialShippingDestination, setInitialShippingDestination] = useState<Partial<CampaignShippingDestination>>({
    country: ''
  })
  const [initialOrderLimit, setInitialOrderLimit] = useState<Partial<CampaignOrderLimit>>({
    role: undefined,
    limit: 1
  })
  const [editMode, setEditMode] = useState(false)
  const [editModeShippingDestination, setEditModeShippingDestination] = useState(false)
  const [editModeOrderLimit, setEditModeOrderLimit] = useState(false)
  const [perPageRecipient, setPerPageRecipient] = useState(storedRecipientPerPage)
  const [pageRecipient, setPageRecipient] = useState(storedRecipientPage)
  const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null)
  const [bundleEditMode, setBundleEditMode] = useState(false)
  const [isBulkCreate, setIsBulkCreate] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTermBundle: string = useDebounce<string>(searchTerm, 800)
  const [selectedBundleIndex, setSelectedBundleIndex] = useState(0)
  const [showPrompt, confirmNavigation, cancelNavigation, confirmedNavigation] = useCallbackPrompt(showDialog)
  const [selectedCartBundle, setSelectedCartBundle] = useState<Bundle | null>(null)
  const [templateTypeFilter, setTemplateTypeFilter] = useState<'general' | 'company'>('general')

  const [searchTermRecipients, setSearchTermRecipients] = useState<string>('')
  const debouncedSearchTermRecipients: string = useDebounce<string>(searchTermRecipients, 800)
  const [isAddingNewRecipient, setIsAddingNewRecipient] = useState(false)

  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  const storedBundlePage = 1
  const storedBundlePerPage = 5
  const selectableBundles = bundles
    .map(bundle => ({
      ...bundle,
      specifications: {
        ...bundle.specifications,
        isBatch: false,
        isBestBefore: false,
        isBillOfMaterials: false,
        isDivisible: false,
        isSerialNumber: false,
        billOfMaterialsComponents: bundle.specifications?.billOfMaterialsComponents
          .map(billOfMaterialsComponent => ({ ...billOfMaterialsComponent, isCustomItem: false, isDeleted: false })) ?? []
      },
      quantity: 1
    }))

  const [activeBundles, setActiveBundles] = useState<Bundle[]>([])
  const [originalActiveBundles, setOriginalActiveBundles] = useState<Bundle[]>([])
  const [additionalArticles, setAdditionalArticles] = useState<Product[]>([])
  const [cancelPressed, setCancelPressed] = useState(false)
  const [perPageBundle, setPerPageBundle] = useState(storedBundlePerPage)
  const [pageBundle, setPageBundle] = useState(storedBundlePage)
  const [selectedRecipient, setSelectedRecipient] = useState<Partial<Recipient> | null>(null)

  const [perPageProduct, setPerPageProduct] = useState(4)
  const [pageProduct, setPageProduct] = useState(1)
  const [productSearchTerm, setProductSearchTerm] = useState<string>('')
  const debouncedProductSearchTerm: string = useDebounce<string>(productSearchTerm, 800)
  const [products, setProducts] = useState<Product[]>([])
  const [isLoadingCompanyProducts, setIsLoadingCompanyProducts] = useState(false)
  const [productMetadata, setProductMetadata] = useState<ApiMetadata>({
    page: 1,
    pageCount: 1,
    perPage: 4,
    total: 0
  })
  const [, setError] = useState(null)

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const token = currentUser?.token
  const userId = profile?.id
  const role = profile?.role || userRoles.USER
  const companyId = profile?.company?.id
  const companyOwnerId = profile?.company?.owner?.id
  const isOwner = companyOwnerId && userId === companyOwnerId
  const isAdmin = (role === userRoles.ADMIN)

  const dispatch = useAppDispatch()

  const { campaignId } = useParams()

  const handleFilter = (type: 'general' | 'company') => {
    setTemplateTypeFilter(type)
  }

  const handleRecipientShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageRecipient(1)
    setPerPageRecipient(Number(event.target.value))
  }

  const handleRecipientPageChange = (page: number) => {
    setPageRecipient(page)
  }

  const addItem = (item: BillOfMaterialsComponent, bundle: Bundle) => {
    const foundBundleIndex = activeBundles.findIndex(activeBundle => activeBundle.jfsku === bundle.jfsku)
    if (foundBundleIndex !== -1 && activeBundles[foundBundleIndex]?.specifications) {
      const updatedActiveBundleItems = [...activeBundles[foundBundleIndex]?.specifications?.billOfMaterialsComponents ?? [], item]

      const foundItem = activeBundles[foundBundleIndex]?.specifications?.billOfMaterialsComponents?.find((activeBundleItem: BillOfMaterialsComponent) => activeBundleItem.jfsku === item.jfsku)
      if (!foundItem) {
        const updatedActiveBundles = [...activeBundles]
        updatedActiveBundles[foundBundleIndex] = {
          ...updatedActiveBundles[foundBundleIndex],
          specifications: {
            isBatch: false,
            isBestBefore: false,
            isBillOfMaterials: false,
            isDivisible: false,
            isSerialNumber: false,
            billOfMaterialsComponents: updatedActiveBundleItems
          }
        }
        setActiveBundles(updatedActiveBundles)
      }
    }
  }

  const deleteItem = (jfsku: string, bundle: Bundle) => {
    const foundBundleIndex = activeBundles.findIndex(activeBundle => activeBundle.jfsku === bundle.jfsku)
    if (foundBundleIndex !== -1 && activeBundles[foundBundleIndex]) {
      const updatedActiveBundleItems = activeBundles[foundBundleIndex]?.specifications?.billOfMaterialsComponents.filter((activeBundleItem: BillOfMaterialsComponent) => activeBundleItem.jfsku !== jfsku)

      const updatedActiveBundles = [...activeBundles]
      updatedActiveBundles[foundBundleIndex] = {
        ...updatedActiveBundles[foundBundleIndex],
        specifications: {
          isBatch: false,
          isBestBefore: false,
          isBillOfMaterials: false,
          isDivisible: false,
          isSerialNumber: false,
          billOfMaterialsComponents: updatedActiveBundleItems ?? []
        }
      }
      setActiveBundles(updatedActiveBundles)
    }
  }

  const updateItemQuantity = (activeBundle: Bundle, jfsku: string, quantity: number) => {
    if (activeBundle) {
      const billOfMaterialsComponents = activeBundle.specifications?.billOfMaterialsComponents || []
      const foundBillOfMaterialsComponentsItemIndex = billOfMaterialsComponents.findIndex(item => item.jfsku === jfsku)

      if (foundBillOfMaterialsComponentsItemIndex !== -1) {
        const updatedBillOfMaterialsComponents = [...billOfMaterialsComponents]
        updatedBillOfMaterialsComponents[foundBillOfMaterialsComponentsItemIndex] = {
          ...updatedBillOfMaterialsComponents[foundBillOfMaterialsComponentsItemIndex],
          quantity
        }
        const foundActiveBundleIndex = activeBundles.findIndex(bundle => bundle.id === activeBundle.id)
        if (foundActiveBundleIndex !== -1) {
          const updatedActiveBundles = [...activeBundles]
          updatedActiveBundles[foundActiveBundleIndex] = {
            ...updatedActiveBundles[foundActiveBundleIndex],
            specifications: {
              isBatch: false,
              isBestBefore: false,
              isBillOfMaterials: false,
              isDivisible: false,
              isSerialNumber: false,
              billOfMaterialsComponents: updatedBillOfMaterialsComponents ?? []
            }
          }
          setActiveBundles(updatedActiveBundles)
        }
      }
    }
  }

  const setItemDeletedStatus = (activeBundle: Bundle, jfsku: string, isDeleted: boolean) => {
    if (activeBundle) {
      const billOfMaterialsComponents = activeBundle.specifications?.billOfMaterialsComponents || []
      const foundBillOfMaterialsComponentsItemIndex = billOfMaterialsComponents.findIndex(item => item.jfsku === jfsku)

      if (foundBillOfMaterialsComponentsItemIndex !== -1) {
        const updatedBillOfMaterialsComponents = [...billOfMaterialsComponents]
        updatedBillOfMaterialsComponents[foundBillOfMaterialsComponentsItemIndex] = {
          ...updatedBillOfMaterialsComponents[foundBillOfMaterialsComponentsItemIndex],
          isDeleted
        }
        const foundActiveBundleIndex = activeBundles.findIndex(bundle => bundle.id === activeBundle.id)
        if (foundActiveBundleIndex !== -1) {
          const updatedActiveBundles = [...activeBundles]
          updatedActiveBundles[foundActiveBundleIndex] = {
            ...updatedActiveBundles[foundActiveBundleIndex],
            specifications: {
              isBatch: false,
              isBestBefore: false,
              isBillOfMaterials: false,
              isDivisible: false,
              isSerialNumber: false,
              billOfMaterialsComponents: updatedBillOfMaterialsComponents ?? []
            }
          }
          setActiveBundles(updatedActiveBundles)
        }
      }
    }
  }

  const addAdditionalArticle = (product: Product) => {
    const updatedAdditionalArticles = [...additionalArticles, product]
    setAdditionalArticles(updatedAdditionalArticles)
  }
  const removeAdditionalArticle = (product: Product) => {
    const updatedAdditionalArticles = additionalArticles.filter(additionalArticle => additionalArticle.id !== product.id)
    setAdditionalArticles(updatedAdditionalArticles)
  }

  const updateAdditionalArticleQuantity = (product: Product, quantity: number) => {
    const foundBundleIndex = additionalArticles.findIndex(additionalArticle => additionalArticle.id === product.id)
    if (foundBundleIndex !== -1) {
      const updatedAdditionalArticles = [...additionalArticles]
      updatedAdditionalArticles[foundBundleIndex] = {
        ...updatedAdditionalArticles[foundBundleIndex],
        quantity
      }
      setAdditionalArticles(updatedAdditionalArticles)
    }
  }

  const handleBundleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageBundle(1)
    setPerPageBundle(Number(event.target.value))
  }

  const handleBundlePageChange = (page: number) => {
    setPageBundle(page)
  }

  const addBundle = (bundle: Bundle) => {
    const updatedActiveBundles = [...activeBundles, bundle]
    const updatedOriginalActiveBundles = [...originalActiveBundles, bundle]
    setActiveBundles(updatedActiveBundles)
    setOriginalActiveBundles(updatedOriginalActiveBundles)
  }
  const removeBundle = (bundle: Bundle) => {
    const updatedActiveBundles = activeBundles.filter(activeBundle => activeBundle.id !== bundle.id)
    const updatedOriginalActiveBundles = originalActiveBundles.filter(originalActiveBundle => originalActiveBundle.id !== bundle.id)
    setActiveBundles(updatedActiveBundles)
    setOriginalActiveBundles(updatedOriginalActiveBundles)
  }

  const updateBundleQuantity = (activeBundle: Bundle, quantity: number) => {
    const foundBundleIndex = activeBundles.findIndex(bundle => bundle.id === activeBundle.id)
    if (foundBundleIndex !== -1) {
      const updatedActiveBundles = [...activeBundles]
      updatedActiveBundles[foundBundleIndex] = {
        ...updatedActiveBundles[foundBundleIndex],
        quantity
      }
      setActiveBundles(updatedActiveBundles)
    }
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleBundlesRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && campaignId && isAllowed(appModules.BUNDLES)) {
      const search = debouncedSearchTermBundle || undefined
      dispatch(getCampaignBundles({ id: campaignId, token, perPage: perPageBundle, page: pageBundle, signal, search }))
    }
  }

  const handleRecipientsAddressesRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && campaignId && isAllowed(appModules.RECIPIENTS)) {
      dispatch(getCampaignRecipients({ id: campaignId, token, perPage: perPageRecipient, page: pageRecipient, signal }))
    }
  }

  const isOrderCreationDisabled = (): boolean => {
    const allowedRoles = [userRoles.ADMIN]
    const totalUsedQuota = (campaign?.usedQuota || 0) + (campaign?.correctionQuota || 0)

    if (allowedRoles.includes(role)) {
      return false
    }
    return Boolean(campaign?.isQuotaEnabled && !campaign.isExceedQuotaEnabled && totalUsedQuota >= campaign.quota)
  }
  const isCampaignInActive = () => {
    const allowedRoles = [userRoles.ADMIN]
    if (allowedRoles.includes(role)) {
      return false
    }
    return Boolean(!campaign?.isActive)
  }

  const loadCostCenterOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: { value: string, label: string }[]) => void) => {
      campaign?.company?.id &&
      loadCostCenterOptions(campaign.company.id, 1, 1000, String(token), inputValue, isAllowed(appModules.COSTCENTERS))
        .then(options => callback(options))
    }, 800),
    [campaign]
  )

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
      dispatch(getCampaignById({ id: campaignId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowed(appModules.RECIPIENTS)) {
      dispatch(getCampaignRecipients({ id: campaignId, token, perPage: perPageRecipient, page: pageRecipient, signal, search: debouncedSearchTermRecipients }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageRecipient, pageRecipient, debouncedSearchTermRecipients])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (isAddingNewRecipient && messageCampaign === RECIPIENT_ADDITION_SUCCESS_MESSAGE) {
      setIsAddingNewRecipient(false)
      openModal('recipientsModal')
    }

    if (recipient && (token && campaignId) && isAllowed(appModules.RECIPIENTS)) {
      dismissModal('recipientAddressModal')
      dispatch(getCampaignRecipients({ id: campaignId, token, perPage: perPageRecipient, page: pageRecipient, signal, search: debouncedSearchTermRecipients }))
    }

    return () => {
      controller.abort()
    }
  }, [recipient])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (recipientMessage && token && campaignId && isAllowed(appModules.RECIPIENTS)) {
      const payload = {
        title: 'Success',
        message: recipientMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getCampaignRecipients({ id: campaignId, token, perPage: perPageRecipient, page: pageRecipient, signal }))
      dispatch(resetRecipientMessage())
      dismissModal('recipientAddressModal')
      dismissModal('recipientConfirmationModal')
    }
  }, [recipientMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowed(appModules.BUNDLES)) {
      const search = debouncedSearchTermBundle || undefined
      dispatch(getCampaignBundles({ id: campaignId, token, perPage: perPageBundle, page: pageBundle, signal, search }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageBundle, pageBundle, debouncedSearchTermBundle])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const allowedCampaignMessages = [BUNDLE_CREATION_SUCCESS_MESSAGE, RECIPIENT_ADDITION_SUCCESS_MESSAGE]

    if (messageCampaign && allowedCampaignMessages.includes(messageCampaign)) {
      const payload = {
        title: 'Success',
        message: messageCampaign,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignMessage())
    }
    if (bundle) {
      if (token && campaignId && isAllowed(appModules.BUNDLES)) {
        dispatch(getCampaignBundles({ id: campaignId, token, perPage: perPageBundle, page: pageBundle, signal }))
        dismissModal('billOfMaterialModal')
        dismissModal('bundleEditorModal')
      }
    }
  }, [bundle, messageCampaign])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (messageCampaignShippingDestination === SHIPPING_DESTINATION_UPDATE_SUCCESS_MESSAGE) {
      const payload = {
        title: 'Success',
        message: messageCampaignShippingDestination,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
        dispatch(getCampaignById({ id: campaignId, token, signal }))
      }
      dispatch(resetCampaignMessageShippingDestinations())
      dismissModal('shippingDestinationModal')
    }
  }, [messageCampaignShippingDestination])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((token && campaignId) && (messagePicture || messageBundle) && isAllowed(appModules.BUNDLES)) {
      const payload = {
        title: 'Success',
        message: messageBundle || messagePicture,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getCampaignBundles({ id: campaignId, token, perPage: perPageBundle, page: pageBundle, signal }))
      dispatch(resetBundleMessage())
      dispatch(resetPictureMessage())
      dismissModal('bundleEditorModal')
      dismissModal('bundleDeleteConfirmationModal')
    }
  }, [messagePicture, messageBundle])

  useEffect(() => {
    const controller = new AbortController()

    if (bundleError && bundleError?.errors?.message) {
      const payload = {
        title: 'Error',
        message: bundleError?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetBundleError())
    }

    return () => {
      controller.abort()
    }
  }, [bundleError])

  useEffect(() => {
    const controller = new AbortController()

    if (recipientError && recipientError?.errors?.message) {
      const payload = {
        title: 'Error',
        message: recipientError?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetRecipientError())
    }

    return () => {
      controller.abort()
    }
  }, [recipientError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const filter = 'filter[type]=terms'

    if (companyId && token && isAllowed(appModules.LEGALTEXTS)) {
      dispatch(getCompanyLegalTexts({ id: companyId, token, perPage: 10, page: 1, filter, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [companyId])

  useEffect(() => {
    if (cancelPressed) {
      setShowDialog(false)
      setCancelPressed(false)
    }

    if (confirmedNavigation) {
      dismissModal('bulkCreateOrderModal')
      dismissModal('createOrderModal')
    }
  }, [showDialog, showPrompt, cancelPressed, confirmedNavigation])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((token && campaignId) && (campaignShippingDestinationMessage) && isAllowed(appModules.CAMPAIGNS)) {
      const payload = {
        title: 'Success',
        message: campaignShippingDestinationMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getCampaignById({ id: campaignId, token, signal }))
      dispatch(resetCampaignShippingDestinationMessage())
      dismissModal('deleteShippingDestinationConfirmationModal')
    }
  }, [campaignShippingDestinationMessage])

  useEffect(() => {
    const controller = new AbortController()

    if (campaignShippingDestinationError && campaignShippingDestinationError?.errors?.message) {
      const payload = {
        title: 'Error',
        message: campaignShippingDestinationError?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignShippingDestinationError())
    }

    return () => {
      controller.abort()
    }
  }, [campaignShippingDestinationError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((token && campaignId) && (campaignOrderLimitMessage) && isAllowed(appModules.CAMPAIGNS)) {
      const payload = {
        title: 'Success',
        message: campaignOrderLimitMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getCampaignById({ id: campaignId, token, signal }))
      dispatch(resetCampaignOrderLimitMessage())
      dismissModal('deleteOrderLimitConfirmationModal')
    }
  }, [campaignOrderLimitMessage])

  useEffect(() => {
    const controller = new AbortController()

    if (campaignOrderLimitError && campaignOrderLimitError?.errors?.message) {
      const payload = {
        title: 'Error',
        message: campaignOrderLimitError?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignOrderLimitError())
    }

    return () => {
      controller.abort()
    }
  }, [campaignOrderLimitError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (messageCampaign === CAMPAIGN_ORDER_LIMIT_CREATION_SUCCESS_MESSAGE && (token) && (isAllowed(appModules.CAMPAIGNS))) {
      const payload = {
        title: 'Success',
        message: messageCampaign,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
        dispatch(getCampaignById({ id: campaignId, token, signal }))
      }
      dispatch(resetCampaignMessage())
      dismissModal('orderLimitModal')
    }
  }, [messageCampaign])

  useEffect(() => {
    setIsLoadingCompanyProducts(true)
    const controller = new AbortController()
    const signal = controller.signal
    const campaignCompanyId = campaign?.company?.id

    if (token && campaignCompanyId && isAllowed(appModules.PRODUCTS)) {
      setIsLoadingCompanyProducts(true)
      CompanyService.getCompanyProducts(campaignCompanyId, token, perPageProduct, pageProduct, debouncedProductSearchTerm, signal).then((res: any) => {
        setProducts(res.data.products)
        setProductMetadata(res.data.meta)
        setIsLoadingCompanyProducts(false)
      }).catch((error: any) => {
        if (error.message !== 'canceled') {
          setError(error)
          setProducts([])
          setIsLoadingCompanyProducts(false)
        }
      })
    }
    return () => {
      controller.abort()
    }
  }, [pageProduct, perPageProduct, debouncedProductSearchTerm, campaign])

  return (
    (isLoadingCampaign || !campaign)
      ? (<DetailsLoader />)
      : <main>
      <div className="container-fluid px-4 py-4">
      {
        (campaign && isOrderCreationDisabled()) && <div className="alert alert-danger" role="alert">{CAMPAIGN_QUOTA_EXCEEDED_MESSAGE}</div>
      }
      {
        (campaign && isCampaignInActive()) && <div className="alert alert-danger" role="alert">{CAMPAIGN_INACTIVE_MESSAGE}</div>
      }
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand d-flex flex-column flex-sm-row">
              <p className="h5" title={`${campaign?.name || '---'} at ${campaign?.company?.name || '----'}`}>
                <i className="bi bi-info-square me-2"></i>{campaign?.name || '---'} at {campaign?.company?.name || '----'}
              </p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              {
                isAllowed(appModules.ORDERS, READWRITE) && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#bulkCreateOrderModal"
                      onClick={() => {
                        setIsBulkCreate(true)
                        setShowDialog(true)
                      }}
                      title="Bulk Create Order"
                      disabled={isOrderCreationDisabled() || isCampaignInActive()}
                    >
                      <i className="bi bi-folder-plus"></i>
                      <span className="ms-1">Bulk Create</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#createOrderModal"
                      onClick={() => {
                        setIsBulkCreate(false)
                        setShowDialog(true)
                      }}
                      title="Create Order"
                      disabled={isOrderCreationDisabled() || isCampaignInActive()}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1">Create Order</span>
                    </button>
                  </>
                )
              }
              {
                (role === userRoles.ADMIN && campaign?.cardSetting?.isEnabled) &&
                <Link
                  className="btn btn-outline-primary btn-sm text-nowrap ms-2 "
                  to={{
                    pathname: role === userRoles.ADMIN
                      ? (`/all-campaigns/${campaign?.id}/canvas`)
                      : (`/my-campaigns/${campaign?.id}/canvas`)
                  }}
                  role="button"
                >
                  <div className="">
                    <i className="bi bi-postcard"></i>
                    <span className="d-none d-md-inline-block ms-1">Create Card</span>
                  </div>
                </Link>
              }
              </ul>
            </div>
          </div>
        </div>
        {
          isAllowed(appModules.ORDERS) && (
            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mb-3">
                  <p className="h5 text-success"><i className="bi bi-list-ul me-2"></i>Confirmed Campaign Orders</p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="list-group">
                      {
                        bundles.length > 0
                          ? (
                            <Link
                              className="list-group-item list-group-item-action"
                              to={{
                                pathname: role === userRoles.ADMIN
                                  ? (`/all-campaigns/${campaign?.id}/orders`)
                                  : (`/my-campaigns/${campaign?.id}/orders`)
                              }}
                              state={{
                                jfskus: (bundles.map(bundle => bundle.jfsku))
                              }}
                            >
                              <div className="d-flex justify-content-between">
                                View Confirmed Campaign Orders <i className="bi bi-chevron-double-right"></i>
                              </div>
                            </Link>
                            )
                          : (
                            <li className="list-group-item list-group-item-info">Add a bundle to view orders</li>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {isAllowed(appModules.BUNDLES) &&
        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
              <p className="h5"><i className="bi bi-box-seam me-2"></i>Bundles</p>
              {
                role === userRoles.ADMIN && (
                  <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm me-2 me-md-3 text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#billOfMaterialModal"
                      title="Add Item Bundle"
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Bundle</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#bundleEditorModal"
                      title="Create Custom Bundle"
                      onClick={() => {
                        setBundleEditMode(false)
                        setSelectedBundle(null)
                      }}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1"><span className="d-none d-md-inline-block me-1">Create</span>Custom Bundle</span>
                    </button>
                    <button
                      type="button"
                      title="Refresh"
                      aria-label="Refresh"
                      className="btn btn-outline-dark ms-2 ms-md-3"
                      onClick={() => handleBundlesRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </ul>
                )
              }
            </div>
            {isLoadingCampaignBundles ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Updated</th>
                    <th scope="col" className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    bundles.length > 0
                      ? (
                          bundles.map((bundle) => (
                            <tr key={bundle.id} className={selectedBundle?.id === bundle.id ? 'table-primary' : ''}>
                              <td className='pt-3'>
                                <span
                                  title={bundle.merchantSku ? `Click to copy merchant sku - ${bundle.merchantSku}` : `Click to copy bundle id - ${bundle.id}`}
                                  onClick={() => navigator.clipboard.writeText(String(bundle.merchantSku || bundle.id))}
                                  className="user-select-all"
                                >
                                  {bundle.merchantSku || (bundle.id).substring(0, 8)}
                                </span>
                              </td>
                              <td className='pt-3'>
                                <span
                                  title={'Click to copy name'}
                                  onClick={() => navigator.clipboard.writeText(String(bundle.name))}
                                  className="user-select-all"
                                >
                                  {`${bundle.name}`}
                                </span>
                              </td>
                              <td className='pt-3'>
                                {dayjs(bundle.updatedAt).format('ll')}
                              </td>
                              <td className="text-center">
                                <div className="d-flex flex-row float-end" role="group" aria-label="Bundle Action Buttons">
                                  <Link
                                    className={`btn btn-round btn-outline-dark me-2 ${!bundle.jfsku && 'disabled'}`}
                                    title="View orders"
                                    to={{
                                      pathname: role === userRoles.ADMIN
                                        ? `/all-campaigns/${campaign?.id}/orders/${bundle.jfsku}`
                                        : `/my-campaigns/${campaign?.id}/orders/${bundle.jfsku}`
                                    }}
                                    role="button"
                                  >
                                    <BreadcrumbIcon/>
                                  </Link>
                                  {
                                    role === userRoles.ADMIN && (
                                      <button
                                        type="button"
                                        title="Edit Bundle"
                                        className="btn btn-outline-dark btn-round me-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#bundleEditorModal"
                                        onClick={() => {
                                          setBundleEditMode(true)
                                          setSelectedBundle(bundle)
                                        }}
                                      >
                                        <PencilIcon/>
                                      </button>
                                    )
                                  }
                                  {
                                    role === userRoles.ADMIN && (
                                      <button
                                        type="button"
                                        title="Delete Bundle"
                                        className="btn btn-outline-danger btn-round"
                                        data-bs-toggle="modal"
                                        data-bs-target="#bundleDeleteConfirmationModal"
                                        onClick={() => {
                                          setBundleEditMode(false)
                                          setSelectedBundle(bundle)
                                        }}
                                      >
                                        <TrashIcon/>
                                      </button>
                                    )
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                        )
                      : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No bundles available yet
                          </td>
                        </tr>
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingCampaignBundles}
              metadata={{
                limit: bundlesMetadata.perPage,
                total: bundlesMetadata.total,
                offset: ((bundlesMetadata.page - 1) * (bundlesMetadata.perPage))
              }}
              page={pageBundle}
              perPage={perPageBundle}
              handlePageChange={handleBundlePageChange}
              handleShowEntries={handleBundleShowEntries}
              module="bundles"
              perPageOptions={[5, 10, 25]}
            />
          </div>
        </div>}

        {isAdmin && <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-list-check me-2"></i>Order Limits</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                {isAdmin &&
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  title="Order Limit"
                  data-bs-toggle="modal"
                  data-bs-target="#orderLimitModal"
                  onClick={() => {
                    setEditModeOrderLimit(false)
                  }}
                >
                  <i className="bi bi-plus-circle"></i>
                  <span className="ms-1 d-none d-md-inline-block">Add Order limit</span>
                </button>
                }
              </ul>
            </div>
            {isLoadingCampaign ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Order Limit</th>
                    <th scope="col">Role</th>
                    <th scope="col">UpdatedAt</th>
                    {isAdmin &&
                    <th scope="col" className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingCampaign
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={isAdmin ? 5 : 4} actionQuantity={isAdmin ? 2 : 0} />)
                        )
                      : (
                          campaignOrderLimits && campaignOrderLimits?.length > 0
                            ? (campaignOrderLimits.map((orderLimit: CampaignOrderLimit) => (
                              <tr key={orderLimit.id} className={initialOrderLimit.id === orderLimit.id ? 'table-primary' : ''}>
                                <td title={String(orderLimit.id)} className="small user-select-all" onClick={() => navigator.clipboard.writeText(String(orderLimit.id))}>
                                  {(orderLimit.id).substring(0, 8)}
                                </td>
                                <td>{orderLimit.limit || '-'}</td>
                                <td>{`${userRoles.rolesObject[orderLimit.role] || '-'}`}</td>
                                <td>
                                  {dayjs(orderLimit.updatedAt).format('ll')}
                                </td>
                                {isAdmin && <td className="text-center">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-dark btn-round me-2"
                                      type="button"
                                      title="Edit Order Limit"
                                      data-bs-toggle="modal"
                                      data-bs-target="#orderLimitModal"
                                      onClick={() => {
                                        setInitialOrderLimit(orderLimit)
                                        setEditModeOrderLimit(true)
                                      }}
                                    >
                                      <PencilIcon/>
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Order Limit"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteOrderLimitConfirmationModal"
                                      onClick={() => {
                                        setInitialOrderLimit(orderLimit)
                                      }}
                                    >
                                      <TrashIcon/>
                                    </button>
                                  </div>
                                </td>}
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={isAdmin ? 5 : 4} className="text-center">
                                  No order limits available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <DeleteConfirmationModal
              modalName='deleteOrderLimitConfirmationModal'
              isLoading = {isLoadingOrderLimit}
              deleteById={deleteCampaignOrderLimit}
              id={String(initialOrderLimit.id)}
              name={<>order limit for <span className="fw-bold">{`'${initialOrderLimit.role ? userRoles.rolesObject[initialOrderLimit.role] : ''}`}</span>?</>}
              token={String(token)}
              autoDismiss={false}
            />
          </div>
        </div>}

        {isAllowed(appModules.CAMPAIGNS) &&
        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-geo-alt me-2"></i>Shipping Destinations</p>
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  {isAdmin &&
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    title="Add Shipping Destination"
                    data-bs-toggle="modal"
                    data-bs-target="#shippingDestinationModal"
                    onClick={() => {
                      setEditModeShippingDestination(false)
                      setInitialShippingDestination({
                        country: ''
                      })
                    }}
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1 d-none d-md-inline-block">Add Shipping Destinations</span>
                  </button>
                  }
                </ul>
            </div>
            {isLoadingCampaign ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Country</th>
                    <th scope="col">Updated</th>
                    {isAdmin &&
                    <th scope="col" className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingCampaign
                      ? (
                          Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={isAdmin ? 4 : 3} actionQuantity={isAdmin ? 1 : 0} />)
                        )
                      : (

                          campaign && campaign.campaignShippingDestinations.length > 0
                            ? (campaign.campaignShippingDestinations.map((destination: CampaignShippingDestination) => (
                              <tr key={destination.id} className={initialShippingDestination.id === destination.id ? 'table-primary' : ''}>
                                <td title={String(destination.id)} className="small user-select-all" onClick={() => navigator.clipboard.writeText(String(destination.id))}>
                                  {(destination.id).substring(0, 8)}
                                </td>
                                <td>{destination.country}</td>
                                <td>
                                  {dayjs(destination.updatedAt).format('ll')}
                                </td>
                                {isAdmin && <td className="text-center">
                                  <div className="btn-group float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Shipping Destination"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteShippingDestinationConfirmationModal"
                                      onClick={() => {
                                        setInitialShippingDestination(destination)
                                      }}
                                    >
                                      <TrashIcon/>
                                    </button>
                                  </div>
                                </td>}
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={isAdmin ? 4 : 3} className="text-center">
                                  No shipping destinations available yet. <br />
                                  <span className="small">Shipping destinations limit the countries available for selection while creating or updating orders.</span>
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <DeleteConfirmationModal
              modalName='deleteShippingDestinationConfirmationModal'
              isLoading = {isLoadingShippingDestination}
              deleteById={deleteCampaignShippingDestination}
              id={String(initialShippingDestination.id)}
              name={<>shipping destination for <span className="fw-bold">{`'${initialShippingDestination.country ?? ''}`}</span>?</>}
              token={String(token)}
              autoDismiss={false}
            />
          </div>
        </div>}

        {isAllowed(appModules.RECIPIENTS) &&
        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-truck me-2"></i>Recipients</p>
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: searchTermRecipients
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTermRecipients(search)
                        if (pageRecipient !== 1) {
                          setPageRecipient(1)
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTermRecipients(search)
                                setPageRecipient(1)
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search &&
                                touched.search &&
                                errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearchRecipients"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearchRecipients"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  {isAllowed(appModules.RECIPIENTS, READWRITE) &&
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    title="Add Recipient Address"
                    data-bs-toggle="modal"
                    data-bs-target="#recipientAddressModal"
                    onClick={() => {
                      setEditMode(false)
                      setInitialRecipient({
                        companyName: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        country: '',
                        city: '',
                        street: '',
                        zip: ''
                      })
                    }}
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1 d-none d-md-inline-block">Add Recipient Address</span>
                  </button>
                  }
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark ms-2 ms-md-3"
                    onClick={() => handleRecipientsAddressesRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
            </div>
            {isLoadingCampaignRecipients ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Company</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Updated</th>
                    {isAllowed(appModules.RECIPIENTS, READWRITE) &&
                    <th scope="col" className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingCampaignRecipients
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={isAllowed(appModules.RECIPIENTS, READWRITE) ? 8 : 7} actionQuantity={isAllowed(appModules.RECIPIENTS, READWRITE) ? 2 : 0} />)
                        )
                      : (

                          recipients.length > 0
                            ? (recipients.map((recipient: Recipient, index: number) => (
                              <tr key={recipient.id} className={initialRecipient.id === recipient.id ? 'table-primary' : ''}>
                                <td>{index + 1}</td>
                                <td>{recipient.companyName || '-'}</td>
                                <td>{`${recipient.firstName || '-'} ${recipient.lastName || '-'}`}</td>
                                <td>{recipient.email || '-----'}</td>
                                <td>{recipient.phone || '-'}</td>
                                <td>
                                  {`${recipient.zip}, ${recipient.street}`}<br/>
                                  {`${recipient.city}, ${recipient.country}`}
                                </td>
                                <td>
                                  {dayjs(recipient.updatedAt).format('ll')}
                                </td>
                                {isAllowed(appModules.RECIPIENTS, READWRITE) && <td className="text-center">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-dark btn-round me-2"
                                      type="button"
                                      title="Edit Recipient Address"
                                      data-bs-toggle="modal"
                                      data-bs-target="#recipientAddressModal"
                                      onClick={() => {
                                        setEditMode(true)
                                        setInitialRecipient(recipient)
                                      }}
                                    >
                                      <PencilIcon/>
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Recipient Address"
                                      data-bs-toggle="modal"
                                      data-bs-target="#recipientConfirmationModal"
                                      onClick={() => {
                                        setInitialRecipient(recipient)
                                      }}
                                    >
                                      <TrashIcon/>
                                    </button>
                                  </div>
                                </td>}
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={isAllowed(appModules.RECIPIENTS, READWRITE) ? 8 : 7} className="text-center">
                                  No recipients available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingCampaignRecipients}
              metadata={{
                limit: recipientsMetadata.perPage,
                total: recipientsMetadata.total,
                offset: ((recipientsMetadata.page - 1) * (recipientsMetadata.perPage))
              }}
              page={pageRecipient}
              perPage={perPageRecipient}
              handlePageChange={handleRecipientPageChange}
              handleShowEntries={handleRecipientShowEntries}
              module="recipients"
              perPageOptions={[5, 10, 25, 50]}
            />
            <DeleteConfirmationModal
              isLoading = {isLoadingRecipients}
              deleteById={deleteRecipientAddress}
              id={String(initialRecipient.id)}
              name={<>recipient address for <span className="fw-bold">{`'${initialRecipient.firstName ?? initialRecipient.companyName ?? ''} ${initialRecipient.lastName ?? ''} - ${initialRecipient.email}'`}</span>?</>}
              token={String(token)}
              modalName="recipientConfirmationModal"
              autoDismiss={false}
            />
          </div>
        </div>}

        <div className="modal fade" id="orderLimitModal" tabIndex={-1} aria-labelledby="orderLimitModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="orderLimitModalLabel">
                  <i className={`bi ${editModeOrderLimit ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                  {`${editModeOrderLimit ? 'Edit' : 'Add'} Order limit`}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoadingCampaignOrderLimits || isLoadingOrderLimit) && <Progress />}
              <div className="modal-body">
                <OrderLimit initialOrderLimit={initialOrderLimit} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="recipientAddressModal" tabIndex={-1} aria-labelledby="recipientAddressModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="recipientAddressModalLabel">
                  <i className={`bi ${editMode ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                  {`${editMode ? 'Edit' : 'Add'} Recipient Address`}
                </h5>
                <button
                  onClick={() => {
                    if (isAddingNewRecipient) {
                      setIsAddingNewRecipient(false)
                      openModal('recipientsModal')
                    }
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                </button>
              </div>
              {(isLoadingCampaignRecipients || isLoadingRecipients) && <Progress />}
              <div className="modal-body">
                <RecipientEditor
                  id={editMode ? String(initialRecipient.id) : String(campaignId)}
                  initialRecipient={initialRecipient}
                  save={editMode ? editRecipientAddress : addCampaignRecipient}
                  loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                  isAddingNewRecipient={isAddingNewRecipient}
                  setIsAddingNewRecipient={setIsAddingNewRecipient}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="billOfMaterialModal" tabIndex={-1} aria-labelledby="billOfMaterialModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="billOfMaterialModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  Select A Bundle
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {isLoadingBundle && <Progress />}
              <div className="modal-body">
                <BillOfMaterialSelector campaign={campaign} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="bundleEditorModal" tabIndex={-1} aria-labelledby="bundleEditorModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="bundleEditorModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  {`${bundleEditMode ? 'Edit' : 'Create'} A Bundle`}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoadingBundle || isLoadingPicture) && <Progress />}
              <div className="modal-body">
                <BundleEditor
                  id={bundleEditMode ? selectedBundle?.id : campaignId}
                  bundle={selectedBundle}
                  editMode={bundleEditMode}
                  save={bundleEditMode ? updateBundleById : createCampaignBundle}
                  isAllowedToReadProducts={isAllowed(appModules.PRODUCTS)}
                />
              </div>
            </div>
          </div>
        </div>

        <DeleteConfirmationModal
          isLoading = {isLoadingBundle}
          deleteById={deleteBundleById}
          id={String(selectedBundle?.id)}
          name={<><span className="fw-bold">{`'${selectedBundle?.name}'`}</span> Bundle?</>}
          token={String(token)}
          modalName="bundleDeleteConfirmationModal"
          autoDismiss={false}
        />

        <div className="modal fade" id="createOrderModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="createOrderModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createOrderModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  Create Order - Choose A Bundle
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                    setSearchTerm('')
                    setSelectedBundleIndex(0)
                  }}
                  aria-label="Close">
                </button>
              </div>
              {isLoadingCampaignBundles && <Progress marginBottom={false} />}
              <div className="modal-body">
                <p className="text-muted">Select a bundle. Multiple selection is possible.</p>
                <div className="mb-4">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: ''
                    }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      setPageBundle(1)

                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              setPageBundle(1)
                            }}
                            maxLength={256}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search &&
                              touched.search &&
                              errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search campaign bundles..."
                            aria-label="Search campaign bundles"
                            aria-describedby="campaignBundlesSearch"
                            name="search"
                            autoComplete="on"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="campaignBundlesSearch"
                            type="submit"
                            title="Search"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>

                <BundleSelector
                  selectableBundles={selectableBundles}
                  activeBundles={activeBundles}
                  additionalArticles={additionalArticles}
                  removeAdditionalArticle={removeAdditionalArticle}
                  addBundle={addBundle}
                  removeBundle={removeBundle}
                  pageBundle={pageBundle}
                  perPageBundle={perPageBundle}
                  handleBundlePageChange={handleBundlePageChange}
                  handleBundleShowEntries={handleBundleShowEntries}
                  updateBundleQuantity={updateBundleQuantity}
                  setCancelPressed={setCancelPressed}
                  updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                  isBundleWriteAllowed={(role === userRoles.ADMIN)}
                  isExceedStockEnabled={campaign.isExceedStockEnabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="bulkCreateOrderModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="bulkCreateOrderModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="bulkCreateOrderModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  1. Bulk Create Order
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowDialog(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {campaign
                  ? <BulkImporter campaign={campaign} setShowDialog={setShowDialog} isAllowedToReadProducts={isAllowed(appModules.PRODUCTS)} isAllowedToReadBundles={isAllowed(appModules.BUNDLES)} />
                  : 'Create a campaign in order to do a bulk import'}
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="itemViewerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="itemViewerModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="itemViewerModalLabel">
                    <i className="bi-plus-circle me-1"></i>{' '}
                    Create Order - Add Custom Articles
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                    setSelectedBundleIndex(0)
                  }}
                  aria-label="Close"
                >
              </button>
              </div>
              <div className="modal-body">
                {campaign && activeBundles.length > 0
                  ? (<ItemListView
                    addItem={addItem}
                    deleteItem={deleteItem}
                    setItemDeletedStatus={setItemDeletedStatus}
                    bundle={activeBundles[selectedBundleIndex]}
                    originalActiveBundle={originalActiveBundles[selectedBundleIndex]}
                    selectedBundleIndex={selectedBundleIndex}
                    updateItemQuantity={updateItemQuantity}
                    totalBundles={activeBundles.length}
                    page={pageProduct}
                    perPage={perPageProduct}
                    setPage={setPageProduct}
                    setPerPage={setPerPageProduct}
                    metadata={productMetadata}
                    setSearchTerm={setProductSearchTerm}
                    isLoadingCompanyProducts={isLoadingCompanyProducts}
                    products={products}
                  />)
                  : (<p>Campaign or active bundles not available</p>)
              }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    if (selectedBundleIndex > 0) {
                      setSelectedBundleIndex(selectedBundleIndex - 1)
                    } else {
                      dismissModal('itemViewerModal')
                      openModal('createOrderModal')
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={activeBundles.length === 0}
                  onClick={() => {
                    if (selectedBundleIndex < activeBundles.length - 1) {
                      setSelectedBundleIndex(selectedBundleIndex + 1)
                    } else {
                      dismissModal('itemViewerModal')
                      openModal('cartModal')
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="additionalArticleViewerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="additionalArticleViewerModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="additionalArticleViewerModalLabel">
                    <i className="bi-plus-circle me-1"></i>{' '}
                    Create Order - Select Additional Articles <span className="badge bg-primary">{additionalArticles.length || 0}</span>
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                    setSelectedBundleIndex(0)
                  }}
                  aria-label="Close"
                >
                </button>
              </div>
              <div className="modal-body">
                {
                  campaign
                    ? (<AdditionalArticleViewer
                      campaign={campaign}
                      addAdditionalArticle={addAdditionalArticle}
                      removeAdditionalArticle={removeAdditionalArticle}
                      isAllowedToReadProducts={isAllowed(appModules.PRODUCTS)}
                      additionalArticles={additionalArticles}
                      updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                    />)
                    : (<p>Campaign not available</p>)
                }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    openModal('createOrderModal')
                    dismissModal('additionalArticleViewerModal')
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={activeBundles.length === 0 && additionalArticles.length === 0}
                  onClick={() => {
                    dismissModal('additionalArticleViewerModal')
                    if (activeBundles.length === 0) {
                      openModal('cartModal')
                    } else {
                      openModal('itemViewerModal')
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="cartModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cartModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="cartModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  Create Order - Shopping Cart
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                    setSelectedBundleIndex(0)
                  }}
                  aria-label="Close"
                >
                </button>
              </div>
              <div className="modal-body">
                <Cart
                  selectedAdditionalArticles={additionalArticles}
                  selectedBundles={activeBundles}
                  removeAdditionalArticle={removeAdditionalArticle}
                  updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                  deleteItem={deleteItem}
                  setItemDeletedStatus={setItemDeletedStatus}
                  updateItemQuantity={updateItemQuantity}
                  setSelectedCartBundle={setSelectedCartBundle}
                  updateBundle={updateBundleQuantity}
                  campaign={campaign}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('cartModal')
                    if (activeBundles.length > 0) {
                      setSelectedBundleIndex(activeBundles.length - 1)
                      openModal('itemViewerModal')
                    } else {
                      openModal('createOrderModal')
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={activeBundles.length === 0 && additionalArticles.length === 0}
                  onClick={() => {
                    dismissModal('cartModal')
                    openModal('orderAddressEditorModal')
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="greetingCardModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="greetingCardModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="greetingCardModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  Create Order - Select Greeting Card
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                  }}
                  aria-label="Close"
                >
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="tab-pane fade show active"
                  id="templates"
                  role="tabpanel"
                  aria-labelledby="templates-tab"
                  tabIndex={0}
                >
                  <h4>Select a Template</h4>
                  {companyId && <ul className="nav nav-pills mt-2 mb-2 border p-1 rounded" id="cardTemplateTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link ${templateTypeFilter === 'general' && 'active'}`} id="general-tab" type="button" role="tab" aria-controls="general-tab-pane" aria-selected="false" onClick={() => handleFilter('general')}>
                        General Templates
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link ${templateTypeFilter === 'company' && 'active'}`} id="company-tab" type="button" aria-controls="company-tab-pane" aria-selected="true" onClick={() => handleFilter('company')}>
                        Company Templates
                      </button>
                    </li>
                  </ul>}
                  <GreetingCards
                    selectedCartBundle={selectedCartBundle}
                    selectedBundles={activeBundles}
                    addItem={addItem}
                    removeItem={deleteItem}
                    companyId={companyId}
                    templateTypeFilter={templateTypeFilter}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('greetingCardModal')
                    openModal('cartModal')
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={activeBundles.length === 0 && additionalArticles.length === 0}
                  onClick={() => {
                    dismissModal('greetingCardModal')
                    openModal('cartModal')
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="orderAddressEditorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="orderAddressEditorLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createOrderModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                    Create Order - Your Delivery Address
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                  onClick={() => {
                    setShowDialog(false)
                    setSelectedBundleIndex(0)
                  }}
                  aria-label="Close"
                >
                </button>
              </div>
                {((activeBundles.length > 0 || additionalArticles.length > 0) && campaign)
                  ? (
                    <div className="modal-body">
                      <OrderAddressEditor
                        activeBundles={activeBundles}
                        campaign={campaign}
                        setShowDialog={setShowDialog}
                        additionalArticles={additionalArticles}
                        setActiveBundles={setActiveBundles}
                        setAdditionalArticles={setAdditionalArticles}
                        selectedRecipient={selectedRecipient}
                        setSelectedRecipient={setSelectedRecipient}
                        isAllowedToReadRecipients={isAllowed(appModules.RECIPIENTS)}
                        loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                      />
                    </div>
                    )
                  : (
                    <>
                      <div className="modal-body">
                        <p>Select a bundle</p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            openModal('bundleEditorModal')
                            dismissModal('orderAddressEditorModal')
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </>
                    )
                }
            </div>
          </div>
        </div>

        <div className="modal fade" id="recipientsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="recipientsModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="recipientsModalLabel">Create Order - Select Delivery Address</h5>
                <button type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    openModal('orderAddressEditorModal')
                    dismissModal('recipientsModal')
                  }}
                >
                </button>
              </div>
              {isLoadingCampaignRecipients && <Progress marginBottom={false} />}
              <div className="modal-body">
                <AddressPicker
                  recipientAddresses={recipients}
                  metadata={recipientsMetadata}
                  isLoading={isLoadingCampaignRecipients}
                  page={pageRecipient}
                  perPage={perPageRecipient}
                  handlePageChange={handleRecipientPageChange}
                  handleShowEntries={handleRecipientShowEntries}
                  selectedRecipient={selectedRecipient}
                  setSelectedRecipient={setSelectedRecipient}
                  setSearchTerm={setSearchTermRecipients}
                  searchTerm={searchTermRecipients}
                  setIsAddingNewRecipient={setIsAddingNewRecipient}
                  isAllowedToWriteRecipients={isAllowed(appModules.RECIPIENTS, READWRITE)}
                />
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSelectedRecipient(null)
                  }}
                  disabled={!selectedRecipient}
                >
                  Reset Selection
                </button>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      openModal('orderAddressEditorModal')
                      dismissModal('recipientsModal')
                      setSelectedRecipient(null)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      openModal('orderAddressEditorModal')
                      dismissModal('recipientsModal')
                    }}
                    disabled={!selectedRecipient}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="termsModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-3" id="termsModalLabel">Terms and Conditions</h1>
                <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target={isBulkCreate ? '#bulkCreateOrderModal' : '#orderAddressEditorModal'} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Terms terms={legalTexts.find(legalText => legalText.type === 'terms')?.template} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={isBulkCreate ? '#bulkCreateOrderModal' : '#orderAddressEditorModal'}>Understood</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="shippingDestinationModal" tabIndex={-1} aria-labelledby="shippingDestinationModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="shippingDestinationModalLabel">
                  <i className={`bi ${editModeShippingDestination ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                  {`${editModeShippingDestination ? 'Edit' : 'Add'} Shipping Destinations`}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoadingCampaignShippingDestinations || isLoadingShippingDestination) && <Progress />}
              <div className="modal-body">
                <ShippingDestinationEditor
                  id={String(campaignId)}
                  initialShippingDestinations={campaign.campaignShippingDestinations.map(destination => destination.country)}
                  save={createCampaignShippingDestination}
                />
              </div>
            </div>
          </div>
        </div>

        <DialogBox
          showDialog={typeof showPrompt === 'boolean' ? showPrompt : false}
          cancelNavigation={cancelNavigation}
          confirmNavigation={confirmNavigation}
        />
      </div>
    </main>
  )
}
export default CampaignDetails
