import dayjs from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { CampaignStatus } from '../../constants/campaignStatus'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  createCampaignCardSetting,
  deleteCampaignById, getAllCampaigns,
  resetCampaignMessage, updateCampaignById
} from '../../store/reducers/api/campaignReducer'
import { addCompanyCampaign, resetCompanyMessage } from '../../store/reducers/api/companyReducer'
import { Campaign, CardSetting, Module, Permission } from '../../types'
import * as userRoles from '../../constants/userRoles'
import { setToast } from '../../store/reducers/toastReducer'
import CampaignEditor from '../../components/Campaigns/CampaignEditor'
import { errorStatusCodes } from '../../constants/statusCodes'
import Pagination from '../../components/Pagination'
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal'
import { dismissModal } from '../../utils/dismissModal'
import * as appModules from '../../constants/appModules'
import hasPermission from '../../utils/checkPermissions'
import { READ, READWRITE } from '../../constants/permissions'
import { getQuotaClassName } from '../../utils/getQuotaClassName'
import CardSettingsEditor from '../../components/Campaigns/CardSettingsEditor'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import CampaignLandingModal from '../../components/Campaigns/Onboarding/CampaignLandingModal'
import { resetCampaignAddressError, resetCampaignAddressMessage } from '../../store/reducers/api/campaignAddressReducer'
import { useCallbackPrompt } from '../../utils/hooks/useCallbackPrompt'
import DialogBox from '../../components/modals/DialogBox'
import { calculateCampaignOnboardingProgress } from '../../utils/campaignOnboardingProgress'
import useDebounce from '../../utils/hooks/useDebounce'
import { getAllShippingMethods } from '../../store/reducers/api/shippingMethodReducer'

const AllCampaigns = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const campaigns = useAppSelector((state) => state.apiCampaign.campaigns)
  const campaign = useAppSelector((state) => state.apiCompany.campaign)
  const metadata = useAppSelector((state) => state.apiCampaign.campaignsMetadata)
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoading)
  const error = useAppSelector((state) => state.apiCompany.error)
  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)
  const message = useAppSelector((state) => state.apiCampaign.message)
  const companyMessage = useAppSelector((state) => state.apiCompany.message)
  const campaignAddressMessage = useAppSelector((state) => state.apiCampaignAddress.message)
  const campaignAddressError = useAppSelector((state) => state.apiCampaignAddress.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [initialCampaign, setInitialCampaign] = useState<Partial<Campaign>>({
    name: '',
    type: 'onboarding',
    status: 'draft',
    description: '',
    isQuotaEnabled: false,
    lastQuotaResetDate: null,
    quota: 0,
    correctionQuota: 0,
    shippingMethodType: undefined
  })

  const [initialCardSettings, setInitialCardSettings] = useState<Partial<CardSetting>>({
    isEnabled: false,
    isFrontSelectable: false,
    isRotationEnabled: false,
    isBackEditable: false,
    isAutoProcessingEnabled: false,
    exportOrientation: undefined,
    exportSides: undefined,
    supplierEmail: '',
    eanBarcode: undefined,
    upcBarcode: undefined
  })

  const [editMode, setEditMode] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [cancelPressed, setCancelPressed] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation, confirmedNavigation] = useCallbackPrompt(showDialog)

  const token = currentUser?.token
  const role = currentUser?.role || userRoles.USER
  const userId = currentUser?.id
  const companyOwnerId = currentUser?.company?.owner?.id

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const isOwner = companyOwnerId && userId === companyOwnerId

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleCampaignsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((token) && ((isAllowed(appModules.CAMPAIGNS)))) {
      dispatch(getAllCampaigns({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token) && ((isAllowed(appModules.CAMPAIGNS)))) {
      dispatch(getAllCampaigns({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (campaign && (token) && (isAllowed(appModules.CAMPAIGNS))) {
      dispatch(getAllCampaigns({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }

    if (companyMessage) {
      const payload = {
        title: 'Success',
        message: companyMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyMessage())
      dismissModal('campaignModal')
    }

    return () => {
      controller.abort()
    }
  }, [campaign, companyMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((message || campaignAddressMessage) && (token) && (isAllowed(appModules.CAMPAIGNS))) {
      const payload = {
        title: 'Success',
        message: message || campaignAddressMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getAllCampaigns({ token, perPage, page, signal, search: debouncedSearchTerm }))
      dispatch(resetCampaignMessage())
      dispatch(resetCampaignAddressMessage())
      dismissModal('campaignModal')
      dismissModal('confirmationModal')
      dismissModal('cardSettingsModal')
    }
  }, [message, campaignAddressMessage])

  useEffect(() => {
    if (error && error.errors) {
      const payload = {
        title: errorStatusCodes[error.statusCode],
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    if (campaignAddressError && campaignAddressError.errors) {
      const payload = {
        title: errorStatusCodes[campaignAddressError.statusCode],
        message: campaignAddressError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignAddressError())
    }
  }, [campaignAddressError])

  useEffect(() => {
    if (campaigns.length > 0 && initialCampaign) {
      const updatedCampaign = campaigns.find(campaign => campaign.id === initialCampaign.id)
      if (updatedCampaign) {
        setInitialCampaign(updatedCampaign)
      }
    }
  }, [campaigns])

  useEffect(() => {
    if (cancelPressed) {
      setShowDialog(false)
      setCancelPressed(false)
    }
    if (confirmedNavigation) {
      dismissModal('campaignLandingModal')
    }
  }, [showDialog, showPrompt, cancelPressed])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = 100
    const page = 1

    if (token) {
      dispatch(getAllShippingMethods({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-truck me-1"></i> All Campaigns</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: ''
                    }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      if (page !== 1) {
                        setPage(1)
                        navigate('')
                      }

                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            maxLength={24}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search &&
                              touched.search &&
                              errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="btnNavbarCampaignsSearch"
                            name="search"
                            autoComplete="on"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarCampaignsSearch"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              {
                (isAllowed(appModules.CAMPAIGNS, READWRITE)) && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      title="Add Campaign"
                      aria-label="Add Campaign"
                      data-bs-toggle="modal"
                      data-bs-target="#campaignModal"
                      onClick={() => {
                        setEditMode(false)
                        setInitialCampaign({
                          name: '',
                          type: 'onboarding',
                          status: 'draft',
                          description: '',
                          isQuotaEnabled: false,
                          lastQuotaResetDate: null,
                          quota: 0,
                          correctionQuota: 0,
                          shippingMethodType: undefined
                        })
                      }}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1 d-none d-md-inline-block">Add Campaign</span>
                    </button>
                    <button
                      type="button"
                      title="Refresh"
                      aria-label="Refresh"
                      className="btn btn-outline-dark ms-2 ms-md-3"
                      onClick={() => handleCampaignsRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </>
                )
              }
              </ul>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Quota</th>
                    <th scope="col">Company</th>
                    <th scope="col">Type</th>
                    <th scope="col" className="text-nowrap">Date Created</th>
                    <th scope="col">Active</th>
                    <th scope="col">Visible</th>
                    <th scope="col" className="text-end">
                      <div className="flex-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoading
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={10} actionQuantity={3} />)
                        )
                      : (
                          campaigns.length > 0
                            ? (campaigns.map((campaign: Campaign) => (
                              <tr key={campaign.id} className={`${initialCampaign.id === campaign.id ? 'table-primary' : ''}`}>
                                <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(campaign.id))}>
                                  {(String(campaign.id)).substring(0, 8)}
                                </td>
                                <td>
                                  <Link title="View Campaign" className="text-decoration-none" to={`/all-campaigns/${campaign.id}`}>
                                    {campaign.name}
                                  </Link>
                                </td>
                                <td>
                                  {
                                    campaign.status === 'draft'
                                      ? (
                                        <button
                                          className={`btn btn-sm fw-semibold position-relative text-bg-${CampaignStatus[campaign.status].colour}`}
                                          style={{
                                            padding: '0em 0.65em'
                                          }}
                                          type="button"
                                          title="Onboard Campaign"
                                          data-bs-toggle="modal"
                                          data-bs-target="#campaignLandingModal"
                                          onClick={() => {
                                            setInitialCampaign(campaign)
                                            setShowDialog(true)
                                          }}
                                        >
                                          {campaign.status}
                                          <span className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle badge-animation"></span>
                                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            {calculateCampaignOnboardingProgress(campaign).totalPendingTasks}
                                            <span className="visually-hidden">Onboarding Action</span>
                                          </span>
                                        </button>
                                        )
                                      : (
                                        <span className={`badge text-bg-${CampaignStatus[campaign.status].colour}`}>
                                          {campaign.status}
                                        </span>
                                        )
                                  }
                                </td>
                                {
                                  campaign.isQuotaEnabled
                                    ? (
                                      <td>
                                        <span className={`fw-bold badge ${getQuotaClassName(campaign.correctionQuota, campaign.quota, campaign.usedQuota)}`}>{`${campaign.usedQuota + campaign.correctionQuota}/${campaign.quota}`}</span>
                                      </td>
                                      )
                                    : (
                                      <td>-</td>
                                      )
                                }
                                <td>{campaign.company?.name}</td>
                                <td>{campaign.type}</td>
                                <td className='text-nowrap'>{dayjs(campaign.createdAt).format('ll')}</td>
                                <td className='text-center'><span>{campaign.isActive === true ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}</span></td>
                                <td className='text-center'><span>{campaign.isHidden === false ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}</span></td>
                                <td className="text-end">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  {
                                      role === userRoles.ADMIN && (
                                        <button
                                          className="btn btn-outline-dark btn-round me-2"
                                          type="button"
                                          title="Card Settings"
                                          data-bs-toggle="modal"
                                          data-bs-target="#cardSettingsModal"
                                          onClick={() => {
                                            setInitialCampaign(campaign)
                                            if (campaign.cardSetting) {
                                              setInitialCardSettings(campaign.cardSetting)
                                            } else {
                                              setInitialCardSettings({
                                                isEnabled: false,
                                                isFrontSelectable: false,
                                                isRotationEnabled: false,
                                                isBackEditable: false,
                                                isAutoProcessingEnabled: false,
                                                exportOrientation: undefined,
                                                exportSides: undefined,
                                                supplierEmail: '',
                                                eanBarcode: undefined,
                                                upcBarcode: undefined
                                              })
                                            }
                                          }}
                                        >
                                          <i className="bi bi-postcard"></i>
                                        </button>
                                      )
                                    }
                                    {(isAllowed(appModules.CAMPAIGNS, READWRITE)) &&
                                    <>
                                      <button
                                        className="btn btn-round btn-outline-dark me-2"
                                        type="button"
                                        title="Edit Campaign"
                                        data-bs-toggle="modal"
                                        data-bs-target="#campaignModal"
                                        onClick={() => {
                                          setEditMode(true)
                                          setInitialCampaign(campaign)
                                        }}
                                      >
                                        <PencilIcon/>
                                      </button>
                                      <button
                                        className="btn btn-outline-danger btn-round"
                                        type="button"
                                        title="Delete Campaign"
                                        data-bs-toggle="modal"
                                        data-bs-target="#confirmationModal"
                                        onClick={() => {
                                          setInitialCampaign(campaign)
                                        }}
                                      >
                                        <TrashIcon/>
                                      </button>
                                    </>}
                                  </div>
                                </td>
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={10} className="text-center">
                                  No campaigns available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>
        </div>
      </div>

      <div className="modal fade" id="campaignModal" tabIndex={-1} aria-labelledby="campaignModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="campaignModalLabel">
                <i className={`bi ${editMode ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                {`${editMode ? 'Edit' : 'Add'} Campaign`}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CampaignEditor
                id={String(initialCampaign.id)}
                initialCampaign={initialCampaign}
                save={editMode ? updateCampaignById : addCompanyCampaign}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        modalName="confirmationModal"
        isLoading = {isLoadingCampaign}
        deleteById={deleteCampaignById}
        id={String(initialCampaign.id)}
        name={<><span className="fw-bold">{`'${initialCampaign.name}'`}</span> campaign?</>}
        token={String(token)}
        autoDismiss={false}
      />
      <div className="modal fade" id="cardSettingsModal" tabIndex={-1} aria-labelledby="cardSettingsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="cardSettingsModalLabel">
                <i className="bi bi-postcard me-2"></i>Edit Card Settings
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CardSettingsEditor campaignId={String(initialCampaign.id)} initialCardSettings={initialCardSettings} save={createCampaignCardSetting} />
            </div>
          </div>
        </div>
      </div>
      <CampaignLandingModal campaign={initialCampaign} setShowDialog={setShowDialog} setCancelPressed={setCancelPressed} />
      <DialogBox
        showDialog={typeof showPrompt === 'boolean' ? showPrompt : false}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </main>
  )
}

export default AllCampaigns
