import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductService from '../../services/ProductService'
import { ProductsState } from '../../types'

const initialState: ProductsState = {
  isLoading: false,
  error: null,
  metadata: {
    limit: 10,
    offset: 0,
    total: 0
  },
  products: [],
  product: null
}

export const getAllProducts = createAsyncThunk('products/get', async ({ token, perPage, offset, filter, signal }: { token: string, perPage: number, offset: number, filter: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getAllProducts(token, perPage, offset, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.errors)
  }
})

export const getASingleProduct = createAsyncThunk('products/get/single', async ({ token, jfsku, signal, expand }: { token: string, jfsku: string, signal: AbortSignal, expand?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getASingleProduct(token, jfsku, signal, expand)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.errors)
  }
})

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProductsError: (state) => {
      state.error = null
    },
    resetProducts: (state) => {
      state.products = []
    },
    resetProductsData: (state) => {
      state.products = []
      state.product = null
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action.payload.items
        state.metadata = action.payload._page
        state.error = null
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getASingleProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getASingleProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload
        state.error = null
      })
      .addCase(getASingleProduct.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductsError, resetProducts, resetProductsData } = productSlice.actions

const { reducer } = productSlice
export default reducer
