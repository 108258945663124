import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Quill, { Range } from 'quill'
import 'quill/dist/quill.snow.css'
import JsBarcode from 'jsbarcode'

// Add sizes to whitelist and register them
const Size: any = Quill.import('formats/size')
Size.whitelist = ['small', 'medium', 'large', 'huge']
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font: any = Quill.import('formats/font')
Font.whitelist = ['serif', 'monospace', 'sans-serif', 'montserrat', 'stanford']
Quill.register(Font, true)

interface RichTextEditorProps {
  readOnly?: boolean
  textContent: string
  onTextChange: React.Dispatch<React.SetStateAction<string>>
  documentStageWidth: string
  documentStageHeight: string
  barcodeValue: string | null
  barcodeFormat?: 'itf14' | 'ean13' | 'ean8' | 'upc' | 'upce'
  placeholders?: string[]
}

const RichTextEditor = forwardRef<Quill | null, RichTextEditorProps>(
  ({
    readOnly = false,
    textContent,
    onTextChange,
    documentStageHeight,
    documentStageWidth,
    barcodeValue,
    barcodeFormat,
    placeholders = ['[firstname]', '[lastname]', '[salutation]']
  }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const barcodeRef = useRef(null)
    const [, setRange] = useState<Range>()
    const [, setPreviousRange] = useState<Range>()
    const [, setHighlightedText] = useState<string | undefined>(undefined)

    function placeholderDropdown (placeholder: string) {
      const quill = ref && 'current' in ref && ref.current

      if (placeholder && quill) {
        quill.focus()
        const cursorPosition = quill.getSelection()?.index || 0

        quill.insertText(cursorPosition, placeholder)
        quill.setSelection(cursorPosition + placeholder.length)
      }
    }

    const toolbarOptions = [
      [
        { font: ['sans-serif', 'serif', 'monospace', 'montserrat', 'stanford'] },
        { size: ['small', false, 'large', 'huge'] }
      ],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ script: 'super' }, { script: 'sub' }, 'blockquote'],
      [{ align: [] }, { color: [] }],
      [{ placeholderDropdown: placeholders }]
    ]

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.enable(!readOnly)
      }
    }, [ref, readOnly])

    useEffect(() => {
      const container = containerRef.current
      if (!container) return

      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      )
      const quill = new Quill(editorContainer, {
        placeholder: 'Compose a greeting card...',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              placeholderDropdown
            }
          },
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                handler: (range: Range) => {
                  const noBreakSpace: string = '\u00A0'
                  quill.insertText(range.index, noBreakSpace)
                  quill.setSelection(range.index + 1)
                  quill.setSelection(range.index + noBreakSpace.length)
                  return false // Prevent default behavior
                }
              }
            }
          }
        }
      })

      if (ref && 'current' in ref) {
        ref.current = quill
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        if (ref && 'current' in ref && ref.current) {
          const html = ref.current.root.innerHTML
          onTextChange(html)
        }
      })

      quill.on(Quill.events.SELECTION_CHANGE, (range, oldRange, source) => {
        if (range) {
          setRange(range)
          if (range.length !== 0) {
            if (ref && 'current' in ref && ref.current) {
              const text = ref.current?.getText(range.index, range.length)
              setHighlightedText(text)
            }
          }
        }
        if (oldRange) {
          setPreviousRange(oldRange)
        }
      })

      return () => {
        if (ref && 'current' in ref) {
          ref.current = null
        }
        container.innerHTML = ''
      }
    }, [ref])

    useEffect(() => {
      if (ref && 'current' in ref && ref.current && ref.current.root.innerHTML !== textContent) {
        ref.current.clipboard.dangerouslyPasteHTML(textContent)
        onTextChange(textContent)
      }
    }, [textContent])

    useEffect(() => {
      if (barcodeRef.current && barcodeValue) {
        try {
          JsBarcode(barcodeRef.current, barcodeValue, {
            format: barcodeFormat,
            displayValue: false,
            margin: 0,
            height: 16,
            flat: true
          })
        } catch (error) {}
      }
    }, [barcodeValue])

    return (
      <div>
        <div
          ref={containerRef}
          className="rounded"
          style={{
            width: documentStageWidth
          }}
        >
        </div>
        <div className="mt-2">
          <h5 className="text-primary fw-bold">Rendered card</h5>
          <div className="position-relative">
            <div className="ql-container">
              <div
                className="ql-editor border rounded"
                style={{
                  width: documentStageWidth,
                  height: documentStageHeight,
                  overflow: 'hidden'
                }}
                dangerouslySetInnerHTML={{ __html: textContent }}
              >
              </div>
            </div>
            {
              barcodeValue && (
                <div className="position-absolute" style={{ bottom: '16px', left: '16px' }}>
                  <svg ref={barcodeRef} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
)

RichTextEditor.displayName = 'RichTextEditor'

export default RichTextEditor
