import { createSlice } from '@reduxjs/toolkit'
import { ShoppingCartState } from '../../types'

const initialState: ShoppingCartState = {
  products: [],
  addresses: [],
  bundles: [],
  leftOverProducts: [],
  bundleConfiguratorMode: null,
  isBulkOrder: true
}

const shoppingCartSlice = createSlice({
  name: 'shopping-cart',
  initialState,
  reducers: {
    setShoppingCart: (state, action) => {
      const { products } = action.payload
      state.products = products
    },
    resetShoppingCart: (state) => {
      state.products = []
    },
    setShoppingAddresses: (state, action) => {
      const { addresses } = action.payload
      state.addresses = addresses
    },
    resetShoppingAddresses: (state) => {
      state.addresses = []
    },
    setBundleConfiguratorMode: (state, action) => {
      const { bundleConfiguratorMode } = action.payload
      state.bundleConfiguratorMode = bundleConfiguratorMode
    },
    resetBundleConfiguratorMode: (state) => {
      state.bundleConfiguratorMode = null
    },
    setIsBulkOrder: (state, action) => {
      const isBulkOrder = action.payload
      state.isBulkOrder = isBulkOrder
    },
    setShoppingCartBundles: (state, action) => {
      const { bundles } = action.payload
      state.bundles = bundles
    },
    resetShoppingCartBundles: (state) => {
      state.bundles = []
    },
    setShoppingCartLeftOverProducts: (state, action) => {
      const { leftOverProducts } = action.payload
      state.leftOverProducts = leftOverProducts
    },
    resetShoppingCartLeftOverProducts: (state) => {
      state.leftOverProducts = []
    }
  }
})

export const {
  setShoppingCart,
  resetShoppingCart,
  setShoppingAddresses,
  resetShoppingAddresses,
  setBundleConfiguratorMode,
  resetBundleConfiguratorMode,
  setShoppingCartBundles,
  resetShoppingCartBundles,
  setShoppingCartLeftOverProducts,
  resetShoppingCartLeftOverProducts,
  setIsBulkOrder
} = shoppingCartSlice.actions

const { reducer } = shoppingCartSlice
export default reducer
