import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_API_URL
const proxyServerURL = process.env.REACT_APP_PROXY_SERVER_URL

const apiClient: any = axios.create({
  baseURL: proxyServerURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Target-URL': `${baseURL}/api/v1/merchant`
  },
  timeout: 20000
})

export default {
  getAllProducts (token: string, perPage: number, offset: number, filter: string, signal: AbortSignal) {
    const params: {[key: string]: string | number} = {
      $top: perPage,
      $skip: offset,
      $orderBy: 'modificationInfo/createdAt desc',
      $filter: filter,
      $expand: 'stock'
    }

    for (const key of Object.keys(params)) {
      if (params[key] === '') {
        delete params[key]
      }
    }

    const config = {
      signal,
      params,
      headers: {
        'auth-token': token
      }
    }

    return apiClient.get('/products?$expand=specifications($expand=billOfMaterialsComponents($select=name,merchantSku))', config)
  },
  getASingleProduct (token: string, jfsku: string, signal: AbortSignal, expand?: string) {
    const params: {[key: string]: string | number} = {
      $expand: expand || ''
    }

    for (const key of Object.keys(params)) {
      if (params[key] === '') {
        delete params[key]
      }
    }
    const config = {
      signal,
      params,
      headers: {
        'auth-token': token
      }
    }
    return apiClient.get(`/products/${jfsku}`, config)
  }
}
