import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import AsyncSelect from 'react-select/async'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { createCampaignBundle } from '../../store/reducers/api/campaignReducer'
import * as userRoles from '../../constants/userRoles'
import ProductService from '../../services/ProductService'
import { product } from '../../types'
import { debounce } from '../../utils/debounce'

const BillOfMaterialSelector = ({ campaign }: { campaign: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const token = currentUser?.token

  const bundleSchema = object({
    bundle: object({
      value: object({
        name: string().required('Bundle is required'),
        jfsku: string().required('Bundle is required'),
        merchantSku: string().required('Bundle is required')
      })
    }).required('Bundle is required')
  })

  const dispatch = useAppDispatch()

  const [perPage] = useState(100)
  const [page] = useState(1)

  const mapBundles = (bundles: Array<product.Product>) => {
    return bundles.map(bundle => ({
      value: {
        name: bundle.name,
        jfsku: bundle.jfsku,
        merchantSku: bundle.merchantSku,
        specifications: bundle.specifications
      },
      label: bundle.name
    }))
  }

  const loadOptions = async (inputValue: string) => {
    const offset = (page - 1) * perPage
    const controller = new AbortController()
    const signal = controller.signal
    const filter = 'specifications/isBillOfMaterials eq true'

    if (token && currentUser.role === userRoles.ADMIN) {
      const searchString = `(contains(name, '${inputValue}') or contains(jfsku, '${inputValue}') or contains(merchantSku, '${inputValue}'))`

      const filterAndSearchString = [searchString, filter]
        .filter((filter: string) => filter)
        .join(' and ')

      try {
        const res = await ProductService.getAllProducts(token, perPage, offset, filterAndSearchString, signal)
        return mapBundles(res.data.items)
      } catch (error) {
        return mapBundles([])
      }
    } else {
      return mapBundles([])
    }
  }

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue).then(options => callback(options))
    }, 800),
    []
  )

  return (
    <div>
      <Formik
        validationSchema={bundleSchema}
        enableReinitialize
        initialValues={{
          bundle: {
            label: '',
            value: {
              name: '',
              jfsku: '',
              merchantSku: '',
              specifications: null
            }
          }
        }}
        onSubmit={({ bundle }, actions) => {
          const controller = new AbortController()
          const signal = controller.signal
          const campaignId = campaign.id

          const selectedBundle = bundle.value

          if (token && selectedBundle && campaignId) {
            const bundle = {
              name: selectedBundle.name,
              jfsku: selectedBundle.jfsku,
              merchantSku: selectedBundle.merchantSku,
              isLocked: true,
              isBillOfMaterials: true,
              specifications: selectedBundle.specifications
            }

            dispatch(
              createCampaignBundle({
                id: campaignId,
                token,
                bundle,
                signal
              })
            )
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="bundle" className="form-label">
                    Bundle
                  </label>
                  <AsyncSelect
                    className={`${
                      ((errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name) && touched.bundle)
                        ? 'is-invalid'
                        : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: ((errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name) && touched.bundle) ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          boxShadow: ((errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name) && touched.bundle) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                          borderColor: ((errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name) && touched.bundle) ? '#dc3545' : '#86b7fe'
                        }
                      })
                    }}
                    cacheOptions
                    isClearable
                    loadOptions={loadOptionsDebounced}
                    defaultOptions
                    value={values.bundle}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFieldValue('bundle', selectedOption)
                      } else {
                        resetForm()
                      }
                    }}
                    onBlur={handleBlur}
                    inputId="bundle"
                    name="bundle"
                  />
                  {(errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name) &&
                    <div className="text-danger small">
                      {(errors.bundle?.value?.jfsku || errors.bundle?.value?.merchantSku || errors.bundle?.value?.name)}
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default BillOfMaterialSelector
