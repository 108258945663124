import React from 'react'
import { ShoppingCartProduct } from '../../types'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { formatPrice } from '../../utils/formatPrice'
import Placeholder from '../../assets/images/placeholder.png'
import { setShoppingCart, setShoppingCartBundles, setShoppingCartLeftOverProducts } from '../../store/reducers/shoppingCartReducer'
import { TrashIcon } from '../icons/TrashIcon'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import { getMaximumProductOrderQuantity } from '../../utils/getMaximumProductOrderQuantity'
import { Tooltip } from '../Tooltip'

interface ShoppingCartItemProps {
  shoppingCartProduct: ShoppingCartProduct
}
const ShoppingCartItem = ({ shoppingCartProduct }: ShoppingCartItemProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const maximumQuantity = 1000

  const dispatch = useAppDispatch()

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))

  const calculateTotalQuantityInBundles = (): number => {
    return shoppingCartBundles.reduce((total, bundle) => {
      const bundleProduct = bundle.products.find(p => p.id === shoppingCartProduct.id)
      return total + (bundleProduct ? bundleProduct.quantity * bundle.quantity : 0)
    }, 0)
  }

  return (
    <tr key={shoppingCartProduct.id}>
      <th scope="row">
        <div className="row">
          <div className="col d-flex align-items-center">
            <div className="rounded">
              <img
                src={shoppingCartProduct?.pictures && shoppingCartProduct.pictures.length > 0 ? shoppingCartProduct.pictures[0].publicUrl : Placeholder}
                alt={`Cart Product - ${shoppingCartProduct.name}`}
                className="img-fluid rounded border h-100 p-1"
                style={{
                  objectFit: shoppingCartProduct?.pictures && shoppingCartProduct.pictures.length > 0 ? 'contain' : 'cover',
                  maxHeight: 60
                }}
                title={shoppingCartProduct.name}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <span className="small fw-semibold">{shoppingCartProduct.name}</span>
            </div>
            {
              shoppingCartProduct.isExceedStockEnabled === false && (
                <div className="row mb-1">
                  <span className="small fw-normal text-nowrap">
                    {Math.max((shoppingCartProduct.stock?.stockLevel || 0) - (shoppingCartProduct.stock?.stockLevelReserved || 0), 0) > 0
                      ? <span className='badge rounded-pill text-bg-success'>In Stock</span>
                      : <span className="badge rounded-pill text-bg-danger">Out of Stock</span>}
                  </span>
                </div>
              )
            }
            <div className="row">
              <div className="col text-md-end">
                <div className="d-flex">
                  <span className="small fw-light me-1 text-nowrap">Unit price:</span>
                  <span className="small">
                    {formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(shoppingCartProduct.graduatedPrices, shoppingCartProduct.quantity, shoppingCartProduct.netRetailPrice.amount))}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {
                  shoppingCartProduct.productColor?.name
                    ? (
                      <Tooltip text={shoppingCartProduct.productColor.name} placement="bottom">
                        <div
                          className="btn"
                          key={shoppingCartProduct.productColor.name}
                          title={shoppingCartProduct.productColor.name}
                          style={{
                            background: shoppingCartProduct.productColor.hexCode,
                            borderWidth: '2px',
                            borderColor: '#adb5bd',
                            borderRadius: '1.0rem',
                            width: '1.0rem',
                            height: '1.0rem '
                          }}
                        />
                      </Tooltip>
                      )
                    : (<span className="small">{shoppingCartProduct.productMaterial?.name || shoppingCartProduct.productSize?.name || ''}</span>)
                }
              </div>
            </div>
          </div>
        </div>
      </th>
      <td className="col-2">
        <div className="form-group col-12">
          <input
            type="number"
            min={Math.max(calculateTotalQuantityInBundles(), getMinimumProductOrderQuantity(shoppingCartProduct.minimumOrderQuantity, shoppingCartProduct.graduatedPrices), calculateTotalQuantityInBundles())}
            max={(shoppingCartProduct.isExceedStockEnabled ? getMaximumProductOrderQuantity(maximumQuantity, shoppingCartProduct.graduatedPrices) : Math.max((shoppingCartProduct.stock?.stockLevel || 0) - (shoppingCartProduct.stock?.stockLevelReserved || 0), 0) || 1)}
            value={shoppingCartProduct.quantity}
            onChange={(e) => {
              const max = Math.max((shoppingCartProduct.stock?.stockLevel || 0) - (shoppingCartProduct.stock?.stockLevelReserved || 0), 0) || 1
              let min = getMinimumProductOrderQuantity(shoppingCartProduct.minimumOrderQuantity, shoppingCartProduct.graduatedPrices)

              const totalShoppingCartProductInBundlesQuantity = calculateTotalQuantityInBundles()

              min = Math.max(min, totalShoppingCartProductInBundlesQuantity)
              const value = Number(e.target.value)
              const quantity = shoppingCartProduct.isExceedStockEnabled ? Math.max(Math.min(value, getMaximumProductOrderQuantity(maximumQuantity, shoppingCartProduct.graduatedPrices)), min) : Math.max(Math.min(value, max), min)
              const foundProductInCartIndex = shoppingCartProductsWithQuantity.findIndex(cartProduct => cartProduct.id === shoppingCartProduct.id)

              if (foundProductInCartIndex !== -1) {
                const updatedProductsInCart = [...shoppingCartProductsWithQuantity]
                updatedProductsInCart[foundProductInCartIndex] = {
                  ...updatedProductsInCart[foundProductInCartIndex],
                  quantity
                }
                const payload = {
                  products: updatedProductsInCart
                }
                dispatch(setShoppingCart(payload))
              }
            }}
            name="quantity"
            className="form-control"
            aria-label={'Cart Item Quantity'}
            autoComplete="off"
            disabled={false}
          />
        </div>
      </td>
      <td className="fw-bold text-end col-md-3">
        {formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(shoppingCartProduct.graduatedPrices, shoppingCartProduct.quantity, shoppingCartProduct.netRetailPrice.amount) * shoppingCartProduct.quantity)}
      </td>
      <td>
        <button
          type="button"
          title={`Remove ${shoppingCartProduct.name}`}
          className="btn btn-outline-danger btn-round-sm"
          onClick={() => {
            // Remove from shopping cart products
            const updatedShoppingCartProducts = shoppingCartProductsWithQuantity.filter(cartProduct => cartProduct.id !== shoppingCartProduct.id)
            const payload = {
              products: updatedShoppingCartProducts
            }
            dispatch(setShoppingCart(payload))
            // Remove from shopping cart bundles products
            const updatedShoppingCartBundles = shoppingCartBundles.map(bundle => ({
              ...bundle,
              products: bundle.products.filter(product => product.id !== shoppingCartProduct.id)
            }))
            dispatch(setShoppingCartBundles({ bundles: updatedShoppingCartBundles }))
            // Remove from shopping cart leftover products
            const updatedLeftOverProducts = shoppingCartLeftOverProducts.filter(product => product.id !== shoppingCartProduct.id)
            dispatch(setShoppingCartLeftOverProducts({ leftOverProducts: updatedLeftOverProducts }))
          }}
        >
          <TrashIcon />
        </button>
      </td>
    </tr>
  )
}

export default ShoppingCartItem
