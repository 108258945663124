import React, { useState, useEffect } from 'react'
import { ShoppingCartBundle, ShoppingCartProduct } from '../../../types'
import { formatPrice } from '../../../utils/formatPrice'
import { calculateGraduatedPrice } from '../../../utils/calculateGraduatedPrice'
import Placeholder from '../../../assets/images/placeholder.png'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setBundleConfiguratorMode, setShoppingCartBundles } from '../../../store/reducers/shoppingCartReducer'
import { resetBundleConfiguratorProducts } from '../../../store/reducers/api/productReducer'
import { dismissModal } from '../../../utils/dismissModal'
import { openModal } from '../../../utils/openModal'
import {
  allowedFillingMaterialTagNames,
  allowedGreetingCardsTagNames,
  allowedPackagingTagNames,
  allowedTissuePaperTagNames
} from '../../../utils/allowedBundleTags'

interface BundleConfiguratorItemProps {
  shoppingCartBundleId: string
  index: number
}

const BundleConfiguratorItem: React.FC<BundleConfiguratorItemProps> = ({ shoppingCartBundleId, index }) => {
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const [packagingProduct, setPackagingProduct] = useState<ShoppingCartProduct | null>(null)
  const [fillingMaterialProduct, setFillingMaterialProduct] = useState<ShoppingCartProduct | null>(null)
  const [greetingCardProduct, setGreetingCardProduct] = useState<ShoppingCartProduct | null>(null)
  const [tissuePaperProduct, setTissuePaperProduct] = useState<ShoppingCartProduct | null>(null)
  const [bundleContentProducts, setBundleContentProducts] = useState<ShoppingCartProduct[]>([])
  const [maximumBundleQuantity, setMaximumBundleQuantity] = useState(1)
  const [shoppingCartBundle, setShoppingCartBundle] = useState<ShoppingCartBundle | null>(null)

  const dispatch = useAppDispatch()

  const packagingCategory = 'packaging'
  const printProductsCategory = 'print products'

  const calculateTotalPrice = (cartProducts: ShoppingCartProduct[]) => {
    const total = cartProducts.reduce((accumulator, currentValue) => accumulator + (calculateGraduatedPrice(currentValue.graduatedPrices, currentValue.quantity, currentValue.netRetailPrice.amount) * currentValue.quantity), 0)
    return total
  }

  const getBundleConfiguration = () => {
    const shoppingCartBundle = shoppingCartBundles.find(bundle => bundle.id === shoppingCartBundleId)
    if (!shoppingCartBundle) {
      return
    }

    // Reset all products to null before processing
    setPackagingProduct(null)
    setFillingMaterialProduct(null)
    setGreetingCardProduct(null)
    setTissuePaperProduct(null)
    setBundleContentProducts([])

    const categoryMap = [
      { tags: allowedPackagingTagNames, setter: setPackagingProduct },
      { tags: allowedFillingMaterialTagNames, setter: setFillingMaterialProduct },
      { tags: allowedGreetingCardsTagNames, setter: setGreetingCardProduct },
      { tags: allowedTissuePaperTagNames, setter: setTissuePaperProduct }
    ]

    shoppingCartBundle.products.forEach(product => {
      const productTags = product.productTags.map(tag => tag.productCategoryTag.name.toLowerCase())

      const category = categoryMap.find(cat => productTags.some(tag => cat.tags.includes(tag)))

      if (category) {
        category.setter(product)
      } else {
        setBundleContentProducts(prevProducts => (
          prevProducts.some(p => p.id === product.id)
            ? prevProducts
            : [...prevProducts, product]
        ))
      }
    })
  }

  const handleRemoveBundle = () => {
    const updatedBundles = shoppingCartBundles.filter(bundle => bundle.id !== shoppingCartBundleId)
    dispatch(setShoppingCartBundles({ bundles: updatedBundles }))
  }

  useEffect(() => {
    setShoppingCartBundle(shoppingCartBundles.find(bundle => bundle.id === shoppingCartBundleId) ?? null)
  }, [shoppingCartBundles, shoppingCartBundleId])

  useEffect(() => {
    getBundleConfiguration()
  }, [shoppingCartBundle])

  useEffect(() => {
    if (shoppingCartProducts.length > 0) {
      setMaximumBundleQuantity(Math.min(...shoppingCartProducts.map(product => product.quantity)))
    }
  }, [shoppingCartProducts, shoppingCartBundles, shoppingCartLeftOverProducts])

  if (!shoppingCartBundle) {
    return null
  }

  return (
    <div className="border rounded p-2 mb-4">
      <div className="row">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-2 gap-md-3 mb-3">
          <div>
            <h6 className="m-0">Bundle {index + 1}</h6>
          </div>
          <div><span className="badge text-bg-dark">{formatPrice('EUR', navigator.language).format(calculateTotalPrice(shoppingCartBundle.products))} per bundle</span></div>
          <div>
            <div className="input-group">
              <span className="input-group-text">Bundle Quantity</span>
              <input
                type="number"
                min={1}
                max={maximumBundleQuantity}
                value={shoppingCartBundle.quantity}
                onChange={(e) => {
                  const max = maximumBundleQuantity
                  const min = 1
                  const value = Number(e.target.value)
                  const quantity = Math.max(Math.min(value, max), min)

                  const updatedBundle = {
                    ...shoppingCartBundle,
                    quantity
                  }
                  const updatedBundles = shoppingCartBundles.map(bundle =>
                    bundle.id === shoppingCartBundle.id ? updatedBundle : bundle
                  )
                  const payload = {
                    bundles: updatedBundles
                  }
                  dispatch(setShoppingCartBundles(payload))
                }}
                name="bundle-configurator-bundle-quantity"
                className="form-control"
                aria-label={'Bundle Quantity'}
                autoComplete="off"
                disabled={false}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row g-4">
            <div className="col">
              <div className="card shadow-none h-100">
                <div className="card-body">
                  <h6 className="card-title">Packaging</h6>
                  {
                    packagingProduct
                      ? (
                        <div>
                          <img
                            className="card-img-top h-100 rounded"
                            src={packagingProduct?.pictures && packagingProduct.pictures.length > 0 ? packagingProduct.pictures[0].publicUrl : Placeholder}
                            alt={`Cart Product - ${packagingProduct.name}`}
                            style={{
                              objectFit: packagingProduct?.pictures && packagingProduct.pictures.length > 0 ? 'contain' : 'cover',
                              maxHeight: 200
                            }}
                            title={packagingProduct.name}
                          />
                          <p className="card-title">{packagingProduct.name}</p>
                          <p className="card-text">{formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(packagingProduct.graduatedPrices, packagingProduct.quantity, packagingProduct.netRetailPrice.amount) * packagingProduct.quantity)}</p>
                        </div>
                        )
                      : (
                        <div
                          onClick={() => {
                            dispatch(resetBundleConfiguratorProducts())
                            dismissModal('shoppingCartModal')
                            openModal('bundleConfiguratorProductPickerModal')
                            const payload = {
                              bundleConfiguratorMode: {
                                category: packagingCategory,
                                tags: allowedPackagingTagNames
                              }
                            }
                            dispatch(setBundleConfiguratorMode(payload))
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              dispatch(resetBundleConfiguratorProducts())
                              dismissModal('shoppingCartModal')
                              openModal('bundleConfiguratorProductPickerModal')
                              const payload = {
                                bundleConfiguratorMode: {
                                  category: packagingCategory,
                                  tags: allowedPackagingTagNames
                                }
                              }
                              dispatch(setBundleConfiguratorMode(payload))
                            }
                          }}
                          role="button"
                          tabIndex={0}
                          aria-label="Select Packaging"
                          className="h-100 d-flex align-items-center"
                        >
                          <div className="card-body py-2">
                            <div className="text-center py-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                              </svg>
                            </div>
                            <h6 className="card-title text-center text-primary mt-3">
                              Select Packaging
                            </h6>
                          </div>
                        </div>
                        )
                  }
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow-none h-100">
                <div className="card-body">
                  <h6 className="card-title">Filling Material</h6>
                  {
                    fillingMaterialProduct
                      ? (
                        <div>
                          <img
                            className="card-img-top h-100 rounded"
                            src={fillingMaterialProduct?.pictures && fillingMaterialProduct.pictures.length > 0 ? fillingMaterialProduct.pictures[0].publicUrl : Placeholder}
                            alt={`Cart Product - ${fillingMaterialProduct.name}`}
                            style={{
                              objectFit: fillingMaterialProduct?.pictures && fillingMaterialProduct.pictures.length > 0 ? 'contain' : 'cover',
                              maxHeight: 200
                            }}
                            title={fillingMaterialProduct.name}
                          />
                          <p className="card-title">{fillingMaterialProduct.name}</p>
                          <p className="card-text">{formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(fillingMaterialProduct.graduatedPrices, fillingMaterialProduct.quantity, fillingMaterialProduct.netRetailPrice.amount) * fillingMaterialProduct.quantity)}</p>
                        </div>
                        )
                      : (
                        <div
                          onClick={() => {
                            dispatch(resetBundleConfiguratorProducts())
                            dismissModal('shoppingCartModal')
                            openModal('bundleConfiguratorProductPickerModal')
                            const payload = {
                              bundleConfiguratorMode: {
                                category: packagingCategory,
                                tags: allowedFillingMaterialTagNames
                              }
                            }
                            dispatch(setBundleConfiguratorMode(payload))
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              dispatch(resetBundleConfiguratorProducts())
                              dismissModal('shoppingCartModal')
                              openModal('bundleConfiguratorProductPickerModal')

                              const payload = {
                                bundleConfiguratorMode: {
                                  category: packagingCategory,
                                  tags: allowedFillingMaterialTagNames
                                }
                              }
                              dispatch(setBundleConfiguratorMode(payload))
                            }
                          }}
                          role="button"
                          tabIndex={0}
                          aria-label="Select Filling Material"
                          className="h-100 d-flex align-items-center"
                        >
                          <div className="card-body py-2">
                            <div className="text-center py-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                              </svg>
                            </div>
                            <h6 className="card-title text-center text-primary mt-3">
                              Select Filling Material
                            </h6>
                          </div>
                        </div>
                        )
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <div className="card shadow-none">
                <div className="card-body">
                  <h6 className="card-title">Add-ons</h6>
                  <div className="row g-4">
                    <div className="col">
                      <div className="card shadow-none h-100">
                        <div className="card-body">
                          <h6 className="card-title">Greeting Card</h6>
                          {
                            greetingCardProduct
                              ? (
                                <div>
                                  <img
                                    className="card-img-top h-100 rounded"
                                    src={greetingCardProduct?.pictures && greetingCardProduct.pictures.length > 0 ? greetingCardProduct.pictures[0].publicUrl : Placeholder}
                                    alt={`Cart Product - ${greetingCardProduct.name}`}
                                    style={{
                                      objectFit: greetingCardProduct?.pictures && greetingCardProduct.pictures.length > 0 ? 'contain' : 'cover',
                                      maxHeight: 200
                                    }}
                                    title={greetingCardProduct.name}
                                  />
                                  <p className="card-title">{greetingCardProduct.name}</p>
                                  <p className="card-text">{formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(greetingCardProduct.graduatedPrices, greetingCardProduct.quantity, greetingCardProduct.netRetailPrice.amount) * greetingCardProduct.quantity)}</p>
                                </div>
                                )
                              : (
                                <div
                                  onClick={() => {
                                    dispatch(resetBundleConfiguratorProducts())
                                    dismissModal('shoppingCartModal')
                                    openModal('bundleConfiguratorProductPickerModal')
                                    const payload = {
                                      bundleConfiguratorMode: {
                                        category: printProductsCategory,
                                        tags: allowedGreetingCardsTagNames
                                      }
                                    }
                                    dispatch(setBundleConfiguratorMode(payload))
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                      dispatch(resetBundleConfiguratorProducts())
                                      dismissModal('shoppingCartModal')
                                      openModal('bundleConfiguratorProductPickerModal')
                                      const payload = {
                                        bundleConfiguratorMode: {
                                          category: printProductsCategory,
                                          tags: allowedGreetingCardsTagNames
                                        }
                                      }
                                      dispatch(setBundleConfiguratorMode(payload))
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                  aria-label="Add Greeting Card"
                                  className="h-100 d-flex align-items-center"
                                >
                                  <div className="card-body py-2">
                                    <div className="text-center py-0">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                      </svg>
                                    </div>
                                    <h6 className="card-title text-center text-primary mt-3">
                                      Add Greeting Card
                                    </h6>
                                  </div>
                                </div>
                                )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card shadow-none h-100">
                        <div className="card-body">
                          <h6 className="card-title">Tissue Paper</h6>
                          {
                            tissuePaperProduct
                              ? (
                                <div>
                                  <img
                                    className="card-img-top h-100 rounded"
                                    src={tissuePaperProduct?.pictures && tissuePaperProduct.pictures.length > 0 ? tissuePaperProduct.pictures[0].publicUrl : Placeholder}
                                    alt={`Cart Product - ${tissuePaperProduct.name}`}
                                    style={{
                                      objectFit: tissuePaperProduct?.pictures && tissuePaperProduct.pictures.length > 0 ? 'contain' : 'cover',
                                      maxHeight: 200
                                    }}
                                    title={tissuePaperProduct.name}
                                  />
                                  <p className="card-title">{tissuePaperProduct.name}</p>
                                  <p className="card-text">{formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(tissuePaperProduct.graduatedPrices, tissuePaperProduct.quantity, tissuePaperProduct.netRetailPrice.amount) * tissuePaperProduct.quantity)}</p>
                                </div>
                                )
                              : (
                                <div
                                  onClick={() => {
                                    dispatch(resetBundleConfiguratorProducts())
                                    dismissModal('shoppingCartModal')
                                    openModal('bundleConfiguratorProductPickerModal')
                                    const payload = {
                                      bundleConfiguratorMode: {
                                        category: packagingCategory,
                                        tags: allowedTissuePaperTagNames
                                      }
                                    }
                                    dispatch(setBundleConfiguratorMode(payload))
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                      dispatch(resetBundleConfiguratorProducts())
                                      dismissModal('shoppingCartModal')
                                      openModal('bundleConfiguratorProductPickerModal')
                                      const payload = {
                                        bundleConfiguratorMode: {
                                          category: packagingCategory,
                                          tags: allowedTissuePaperTagNames
                                        }
                                      }
                                      dispatch(setBundleConfiguratorMode(payload))
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                  aria-label="Add Tissue Paper"
                                  className="h-100 d-flex align-items-center"
                                >
                                  <div className="card-body py-2">
                                    <div className="text-center py-0">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                      </svg>
                                    </div>
                                    <h6 className="card-title text-center text-primary mt-3">
                                      Add Tissue Paper
                                    </h6>
                                  </div>
                                </div>
                                )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-sm-4 mt-md-4 mt-lg-0">
          <div className="card shadow-none">
            <div className="card-body">
              <h6 className="card-title">Bundle Contents</h6>
              <div className="">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2">
                  {
                    bundleContentProducts.length > 0
                      ? (
                          bundleContentProducts.map((bundleContentProduct) => (
                            <div key={`${bundleContentProduct.id}-bundle-content-product`} className="col">
                              <div className="card shadow-none h-100">
                                <img
                                  className="card-img-top h-100 p-2 rounded"
                                  src={bundleContentProduct?.pictures && bundleContentProduct.pictures.length > 0 ? bundleContentProduct.pictures[0].publicUrl : Placeholder}
                                  alt={`Cart Product - ${bundleContentProduct.name}`}
                                  style={{
                                    objectFit: bundleContentProduct?.pictures && bundleContentProduct.pictures.length > 0 ? 'contain' : 'cover',
                                    maxHeight: 200
                                  }}
                                  title={bundleContentProduct.name}
                                />
                                <div className="card-body">
                                  <p className="card-title">{bundleContentProduct.name}</p>
                                  <p className="card-text">{formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(bundleContentProduct.graduatedPrices, bundleContentProduct.quantity, bundleContentProduct.netRetailPrice.amount) * bundleContentProduct.quantity)}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      : (
                        <div>
                          <p>No item has been added to the bundle.</p>
                        </div>
                        )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <button
            className="btn btn-outline-danger"
            onClick={handleRemoveBundle}
            disabled={shoppingCartBundles.length === 1}
          >
            Remove Bundle
          </button>
        </div>
      </div>
    </div>
  )
}

export default BundleConfiguratorItem
