import React from 'react'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { formatPrice } from '../../utils/formatPrice'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { generateAddressText } from '../../utils/generateAddressText'
import { TrashIcon } from '../icons/TrashIcon'
import { ShoppingCartAddress, ShoppingCartProduct } from '../../types'
import { setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { germanyTaxRate } from '../../constants/taxRates'
import { countriesObject } from '../../utils/countries'

interface ShoppingShippingDetailsProps {
  setSelectedAssignmentAddress: React.Dispatch<React.SetStateAction<ShoppingCartAddress | null>>
  shippingMode: 'inventory' | 'delivery' | null
  setShippingMode: React.Dispatch<React.SetStateAction<'inventory' | 'delivery' | null>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<ShoppingCartAddress> | null>>
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}
const ShoppingShippingDetails = ({
  setSelectedAssignmentAddress,
  shippingMode,
  setShippingMode,
  setSelectedAddress,
  setEditMode
}: ShoppingShippingDetailsProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)

  const dispatch = useAppDispatch()

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))

  const foundLeftOverProduct = (productId: string) => {
    return shoppingCartLeftOverProducts.find(product => product.id === productId)
  }

  const calculateShippingCost = (destinationCountry: string, totalBundles: number) => {
    const countryObject = countriesObject
      .find(country => (country.country.toUpperCase() === destinationCountry.toUpperCase() ||
      country.alpha2Code.toUpperCase() === destinationCountry.toUpperCase() ||
      country.alpha3Code.toUpperCase() === destinationCountry.toUpperCase() ||
      country.countryGerman.toUpperCase() === destinationCountry.toUpperCase()))

    if (!countryObject || !countryObject.shipping) {
      return 0
    }

    const { baseFee, perBundle } = countryObject.shipping
    let shippingCost = baseFee

    if (totalBundles > 1) {
      shippingCost += totalBundles * perBundle
    }

    return shippingCost
  }

  const calculateTotalPrices = (cartProducts: ShoppingCartProduct[], shippingAddresses: ShoppingCartAddress[]) => {
    const subtotal = cartProducts.reduce((accumulator, currentValue) => accumulator + (calculateGraduatedPrice(currentValue.graduatedPrices, currentValue.quantity, currentValue.netRetailPrice.amount) * currentValue.quantity), 0)
    const shippingTotal = shippingAddresses.reduce((accumulator, address) => {
      const totalBundleQuantity = address.assignedBundleIds.reduce((total, bundleId) => {
        const bundle = shoppingCartBundles.find(b => b.id === bundleId)
        return total + (bundle?.quantity || 1)
      }, 0)
      const shippingCost = calculateShippingCost(address.country || 'Germany', totalBundleQuantity)
      return accumulator + (shippingCost || 0)
    }, 0)
    const taxTotal = (subtotal + shippingTotal) * germanyTaxRate
    const total = subtotal + shippingTotal + taxTotal
    return {
      subtotal,
      taxTotal,
      shippingTotal,
      total
    }
  }

  const getTotalItems = (cartProducts: ShoppingCartProduct[]) => {
    return cartProducts.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
  }

  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div className="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
            <div className="horizontal-steps-content">
              <div
                className="step-item"
              >
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Your Cart
                </span>
              </div>
              <div className="step-item current">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Shipping Details
                </span>
              </div>
              <div
                className="step-item"
              >
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '50%' }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-6 mb-4">
          <div className="border rounded p-2">
            <div className="row">
              <div className="d-flex justify-content-between mt-2">
                <h6 className="fw-bold">Order Summary</h6>
                <p>{`${getTotalItems(shoppingCartProductsWithQuantity)} ${getTotalItems(shoppingCartProductsWithQuantity) === 1 ? 'item' : 'items'}`}</p>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="fw-semibold text-muted">Subtotal</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).subtotal)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="fw-semibold text-muted">Shipping</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).shippingTotal)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="fw-semibold text-muted">Tax</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).taxTotal)}</p>
              </div>
            </div>
            <div className="row border-top gx-3">
              <div className="d-flex justify-content-between my-2">
                <h6 className="fw-semibold text-muted">Total</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).total)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <h6 className="fw-bold">Shipping</h6>
          <div className="border p-2 rounded">
            <div className="row mb-4">
              <div className="col">
                <h6>1. Choose a shipping location</h6>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="inventoryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('inventory')
                      }
                    }}
                    checked={shippingMode === 'inventory'}
                    disabled
                  />
                  <label className="form-check-label" htmlFor="inventoryRadio">
                    Ship articles to inventory
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="deliveryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('delivery')
                      }
                    }}
                    checked={shippingMode === 'delivery'}
                  />
                  <label className="form-check-label" htmlFor="deliveryRadio">
                    Ship articles to delivery address
                  </label>
                </div>
              </div>
            </div>
            {
              shippingMode === 'delivery' && (
                <div className="row">
                  <div className="col">
                    <h6>2. Select delivery address</h6>
                    <p className="small mb-0">Select an address from the address book in order to assign articles.</p>
                    <button type="button"
                      className="btn btn-primary my-2"
                      onClick={() => {
                        setEditMode(false)
                        if (shoppingCartAddresses.length === 0) {
                          openModal('shoppingDeliveryAddressPickerModal')
                          dismissModal('shoppingShippingDetailsModal')
                        }
                      }}
                      disabled={shoppingCartAddresses.length === 1}
                    >
                      <i className="bi bi-plus-circle me-1"></i>Add Delivery Address
                    </button>
                    <div className="accordion-custom order-cart mt-2" id="accordionFlushShippingDetails">
                      {
                        shoppingCartAddresses.length > 0
                          ? (
                              shoppingCartAddresses.map((shoppingCartAddress, index) => (
                                <div key={`shopping-shipping-details-${shoppingCartAddress.id}`} className="accordion-item">
                                  <h2 className="accordion-header">
                                    <div className="d-flex">
                                      <div className="w-100">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse-${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse-${index}`}
                                        >
                                          <span className="small">
                                            {generateAddressText(shoppingCartAddress)}
                                            <br />
                                            <span className="small fw-bold">Shipping Date: {dayjs(shoppingCartAddress.shippingDate).local().format('LL')}</span>
                                          </span>
                                        </button>
                                      </div>
                                      {
                                        shoppingCartAddresses.length > 1 && (
                                          <div className="flex-shrink-1 d-flex align-items-center">
                                            <button
                                              className="btn btn-outline-secondary text-nowrap btn-sm m-2"
                                              type="button"
                                              onClick={() => {
                                                dismissModal('shoppingShippingDetailsModal')
                                                openModal('shoppingDetailsAssignArticlesModal')
                                                setSelectedAssignmentAddress(shoppingCartAddress)
                                              }}
                                            >
                                              Assign Articles
                                            </button>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </h2>
                                  <div id={`collapse-${index}`} className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                      <div>
                                        <button
                                          className="btn btn-outline-primary text-nowrap btn-sm me-2"
                                          type="button"
                                          onClick={() => {
                                            setSelectedAddress(shoppingCartAddress)
                                            setSelectedAssignmentAddress(shoppingCartAddress)
                                            openModal('shoppingDeliveryAddressEditorModal')
                                            dismissModal('shoppingShippingDetailsModal')
                                            setEditMode(true)
                                          }}
                                        >
                                          Edit Address
                                        </button>
                                        <button
                                          className="btn btn-outline-danger text-nowrap btn-sm"
                                          type="button"
                                          onClick={() => {
                                            const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === shoppingCartAddress.id)

                                            if (foundShoppingAddressIndex !== -1) {
                                              const updatedAddressesInCart = shoppingCartAddresses.filter(cartAddress => cartAddress.id !== shoppingCartAddress.id)

                                              const payload = {
                                                addresses: updatedAddressesInCart
                                              }
                                              dispatch(setShoppingAddresses(payload))
                                              setSelectedAssignmentAddress(null)
                                            }
                                          }}
                                        >
                                          Remove Address
                                        </button>
                                      </div>
                                      {
                                        shoppingCartAddresses.length > 1 && (
                                          <div className="table-responsive border border-bottom-0 rounded mt-3">
                                            <table className="table align-middle mb-0">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Assigned Articles</th>
                                                  <th scope="col" className="">Quantity</th>
                                                  <th scope="col" className="text-nowrap text-end">Actions</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  shoppingCartAddress?.assignedLeftOverProductIds.length > 0
                                                    ? (
                                                        shoppingCartAddress.assignedLeftOverProductIds.map(assignedLeftOverProductId => (
                                                          <tr key={`shopping-details-assigned-product-${assignedLeftOverProductId}`}>
                                                            <td scope="row">{foundLeftOverProduct(assignedLeftOverProductId)?.name}</td>
                                                            <td>{foundLeftOverProduct(assignedLeftOverProductId)?.quantity}</td>
                                                            <td className="text-end">
                                                              <div className="d-flex flex-row float-end">
                                                                <button
                                                                  type="button"
                                                                  title="Remove Assigned Article"
                                                                  className="btn btn-outline-danger btn-round"
                                                                  onClick={() => {
                                                                    const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === shoppingCartAddress.id)

                                                                    if (foundShoppingAddressIndex !== -1) {
                                                                      const updatedAddressesInCart = [...shoppingCartAddresses]
                                                                      const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
                                                                      const assignedLeftOverProductIds = updatedSelectedAssignmentAddress.assignedLeftOverProductIds

                                                                      const updatedAssignedLeftOverProductIds = assignedLeftOverProductIds.filter(leftOverProductId => leftOverProductId !== assignedLeftOverProductId)

                                                                      updatedAddressesInCart[foundShoppingAddressIndex] = {
                                                                        ...updatedAddressesInCart[foundShoppingAddressIndex],
                                                                        assignedLeftOverProductIds: updatedAssignedLeftOverProductIds
                                                                      }

                                                                      const payload = {
                                                                        addresses: updatedAddressesInCart
                                                                      }
                                                                      dispatch(setShoppingAddresses(payload))
                                                                      setSelectedAssignmentAddress(updatedAddressesInCart[foundShoppingAddressIndex])
                                                                    }
                                                                  }}
                                                                >
                                                                  <TrashIcon />
                                                                </button>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        ))
                                                      )
                                                    : (
                                                      <tr className="text-center">
                                                        <td colSpan={4}>No product has been assigned to this address yet.</td>
                                                      </tr>
                                                      )
                                                }
                                              </tbody>
                                            </table>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              ))
                            )
                          : (
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <div className="d-flex">
                                  <div className="w-100">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse-none"
                                      aria-expanded="false"
                                      aria-controls="collapse-none"
                                    >
                                      <span className="small">
                                      No delivery addresses selected
                                      </span>
                                    </button>
                                  </div>
                                  {
                                    shoppingCartAddresses.length > 1 && <div className="flex-shrink-1 d-flex align-items-center">
                                      <button
                                        className="btn btn-outline-secondary text-nowrap btn-sm m-2"
                                        type="button"
                                        disabled
                                      >
                                        Assign Articles
                                      </button>
                                    </div>
                                  }
                                </div>
                              </h2>
                              <div id="collapse-none" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                  <span className="small">Click the <span className="fw-semibold">&apos;Add Delivery Address&apos;</span> button to add addresses</span>
                                </div>
                              </div>
                            </div>
                            )
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingShippingDetails
