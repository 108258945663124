import React from 'react'
import { Formik } from 'formik'
import { number, object, string } from 'yup'
import Select from 'react-select'
import { Company, Customer } from '../../types'
import { resetCompanyError } from '../../store/reducers/api/companyReducer'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const CompanyEditor = ({
  id,
  initialCompany,
  save,
  customers
}: {
  id: string;
  initialCompany: Partial<Company>;
  save: Function;
  customers: Array<Customer>
}) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const isLoadingCustomers = useAppSelector((state) => state.dotnetApiCustomer.isLoading)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const companySchema = object({
    customerId: number().label('Customer Id')
      .typeError(({ label, type }) => `${label} must be a ${type}`)
      .nullable(),
    name: string()
      .required('Company Name is required')
      .max(64, 'Company Name is too long'),
    suffix: string().max(16, 'Company Suffix is too long').nullable(),
    email: string().email('Enter a valid email').required('Email is required'),
    domain: string().matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
      'Enter a valid domain name'
    ).required('Domain is required').nullable(),
    vat: string().max(64, 'VAT is too long').nullable()
  })

  const saveCompany = (id: string, company: Partial<Company>, signal: AbortSignal) => {
    dispatch(save({ id, token, company, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={companySchema}
        enableReinitialize
        initialValues={{
          ...initialCompany
        }}
        onSubmit={({ customerId, name, suffix, email, domain, vat }, actions) => {
          const controller = new AbortController()
          const signal = controller.signal
          const company = {
            customerId,
            name,
            suffix,
            email,
            domain,
            vat
          }

          if (token) {
            saveCompany(id, company, signal)
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="companyname" className="form-label">
                    Company Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      errors.name && touched.name && errors.name
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="companyname"
                    name="name"
                    placeholder=""
                  />
                  <div
                    id="validationCompanyNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="companysuffix" className="form-label">
                    Company Suffix
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.suffix || ''}
                    type="text"
                    className={`form-control ${
                      errors.suffix && touched.suffix && errors.suffix
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="companysuffix"
                    name="suffix"
                    placeholder=""
                    maxLength={16}
                  />
                  <div
                    id="validationCompanySuffixFeedback"
                    className="invalid-feedback"
                  >
                    {errors.suffix}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Admin Email
                  </label>
                  <input
                    onChange={(event) => {
                      handleChange(event)
                      dispatch(resetCompanyError())
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email && errors.email
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="email"
                    name="email"
                    placeholder=""
                    maxLength={128}
                  />
                  <div
                    id="validationEmailFeedback"
                    className="invalid-feedback"
                  >
                    {errors.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="domain" className="form-label">
                    Domain (example.com)
                  </label>
                  <input
                    aria-label="Domain"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.domain || ''}
                    type="text"
                    className={`form-control ${
                      errors.domain && touched.domain && errors.domain
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="domain"
                    name="domain"
                  />
                  <div
                    id="validationDomainFeedback"
                    className="invalid-feedback"
                  >
                    {errors.domain}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="vat" className="form-label">
                    VAT
                  </label>
                  <input
                    aria-label="vat"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vat || ''}
                    type="text"
                    className={`form-control ${
                      errors.vat && touched.vat && errors.vat
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="vat"
                    name="vat"
                  />
                  <div id="validationVatFeedback" className="invalid-feedback">
                    {errors.vat}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="customerId" className="form-label">
                    Customer Id
                  </label>
                  <Select
                    className={`${
                      ((errors.customerId) && touched.customerId)
                        ? 'is-invalid'
                        : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: (errors.customerId && touched.customerId) ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          borderColor: (errors.customerId && touched.customerId) ? '#dc3545' : provided.borderColor
                        }
                      })
                    }}
                    isClearable
                    inputId="customerId"
                    name="customerId"
                    aria-label="Customer Id"
                    options={customers}
                    getOptionLabel={(customer) => `${customer.address.company} - ${customer.id}`}
                    getOptionValue={(customer) => String(customer.id)}
                    onChange={(selectedOption) => setFieldValue('customerId', selectedOption?.id)}
                    onBlur={handleBlur}
                    isLoading={isLoadingCustomers}
                    value={customers.find((customer) => customer.id === values.customerId)}
                  />
                  <div id="validationCustomerIdFeedback" className="invalid-feedback">
                    {errors.customerId}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyEditor
