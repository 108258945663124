import React from 'react'

interface WelcomeModalProps {
  firstName?: string
  lastName?: string
}

const WelcomeModal = ({ firstName = '', lastName = '' }: WelcomeModalProps) => {
  const welcomeMessage = "We're thrilled to have you on board. Let's make your experience even better by personalising your profile. Completing it helps us tailor the BLT App just for you. Ready to get started?"

  return (
    <div className="modal fade" id="welcomeModal" tabIndex={-1} aria-labelledby="welcomeModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="welcomeModalLabel">Welcome to our platform, {`${firstName} ${lastName}`}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>{welcomeMessage}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Skip for now</button>
            <button type="button" className="btn btn-primary" data-bs-target="#landingModal" data-bs-toggle="modal">
              Start now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeModal
