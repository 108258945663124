import React, { ChangeEvent, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Progress from '../../../components/loaders/Progress'
import { countries } from '../../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  addCompanyAddress,
  addCompanyCostCenter,
  addCompanyUser,
  getCompanyById,
  getCompanyCostCenters,
  getCompanyInviteLink,
  getCompanyUsers,
  updateCompanyInviteLink,
  resetCompanyError,
  resetCompanyMessage,
  updateCompanyTheme,
  updateRoleOfEmployee,
  uploadCompanyLogo,
  getAllPagesForCompanyUsers,
  setExportCompanyId,
  setExportCompanyName,
  updateCompanyInviteDomainCheck
} from '../../../store/reducers/api/companyReducer'
import * as userRoles from '../../../constants/userRoles'
import {
  Address,
  AddressType,
  CostCenter,
  Module,
  Permission,
  Salutation,
  Theme,
  ThemeColorName,
  User
} from '../../../types'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import { editableRoles, rolesObject } from '../../../constants/userRoles'
import CompanyUserEditor from '../../../components/MyCompany/CompanyUserEditor'
import {
  resetUserMessage,
  updateUserById
} from '../../../store/reducers/api/usersReducer'
import AddressEditor from '../../../components/editors/AddressEditor'
import { errorStatusCodes } from '../../../constants/statusCodes'
import { setToast } from '../../../store/reducers/toastReducer'
import Pagination from '../../../components/Pagination'
import { dismissModal } from '../../../utils/dismissModal'
import CostCenterEditor from '../../../components/MyCompany/CostCenterEditor'
import {
  deleteACostCenterById,
  editACostCenterById,
  resetCostCenterError,
  resetCostCenterMessage
} from '../../../store/reducers/api/costCenterReducer'
import {
  COST_CENTER_CREATION_SUCCESS_MESSAGE,
  COMPANY_EMPLOYEE_UPDATE_SUCCESS_MESSAGE,
  ROLE_UPDATE_SUCCESS_MESSAGE,
  COMPANY_LOGO_UPLOAD_SUCCESS_MESSAGE,
  ADDRESS_ADDITION_SUCCESS_MESSAGE,
  ADDRESS_UPDATE_SUCCESS_MESSAGE,
  INVITE_DOMAIN_CHECK_SUCCESS_MESSAGE
} from '../../../constants/messages'
import Spinner from '../../../components/loaders/Spinner'
import * as appModules from '../../../constants/appModules'
import hasPermission from '../../../utils/checkPermissions'
import { READ, READWRITE } from '../../../constants/permissions'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import { UserIcon } from '../../../components/icons/UserIcon'
import DropZone from '../../../components/Dropzone/DropZone'
import {
  getDownloadURLFirebase,
  getMetadataFirebase,
  refFirebase,
  storageFirebase,
  uploadBytesResumableFirebase
} from '../../../config/firebaseConfig'
import ColorPicker from '../../../components/ColorPicker/ColorPicker'
import { getForegroundColor } from '../../../utils/generateForegroundColor'
import { getMyProfile } from '../../../store/reducers/api/profileReducer'
import Loader from './Loader'
import ModalBox from '../../../components/ModalBox'
import CSVDownloader from '../../../components/CSVExporter/CSVDownloader'
import { phoneValidationPattern } from '../../../constants/regexPatterns'
import { useCallbackPrompt } from '../../../utils/hooks/useCallbackPrompt'
import DialogBox from '../../../components/modals/DialogBox'
const firebaseStorageEnvironment = process.env.REACT_APP_FIREBASE_STORAGE_ENVIRONMENT || 'develop'

const defaultColors: Array<{ color: string; name: ThemeColorName }> = [
  {
    color: '#212529',
    name: 'backgroundColor'
  },
  {
    color: '#d3d4d4',
    name: 'foregroundColor'
  },
  {
    color: '#ff5129',
    name: 'primaryColor'
  },
  {
    color: '#2a314a',
    name: 'secondaryColor'
  },
  {
    color: '#ffffff',
    name: 'accentColor'
  }
]

interface SetColorProps {
  color: string;
  name: ThemeColorName;
}
interface ArrayOfThemesProps extends SetColorProps {
  index: number;
}
interface SetImageProps {
  url: string;
  filename: string;
  size: number;
  mimeType: string;
}

const companyAddressNames: { [key: string]: string } = {
  billing: 'Billing',
  billingAndDelivery: 'Billing and Delivery',
  delivery: 'Delivery',
  return: 'Return'
}

const CompanyDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isUpdatingUser = useAppSelector((state) => state.apiUsers.isLoading)
  const error = useAppSelector((state) => state.profile.error)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const isLoadingCompanyCostCenters = useAppSelector((state) => state.apiCompany.isLoadingCostCenters)
  const isLoadingInviteLink = useAppSelector((state) => state.apiCompany.isLoadingInviteLink)
  const isLoadingUsers = useAppSelector((state) => state.apiCompany.isLoadingUsers)
  const addingAddress = useAppSelector((state) => state.apiCompany.isLoading)
  const company = useAppSelector((state) => state.apiCompany.company)
  const address = useAppSelector((state) => state.apiCompany.address)
  const users = useAppSelector((state) => state.apiCompany.users)
  const costCenters = useAppSelector((state) => state.apiCompany.costCenters)
  const costCentersMetadata = useAppSelector((state) => state.apiCompany.costCenterMetadata)
  const usersMetadata = useAppSelector((state) => state.apiCompany.usersMetadata)
  const companyError = useAppSelector((state) => state.apiCompany.error)
  const companyMessage = useAppSelector((state) => state.apiCompany.message)
  const rolesInviteLinksAndCodes = useAppSelector((state) => state.apiCompany.rolesInviteLinksAndCodes)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const userMessage = useAppSelector((state) => state.apiUsers.message)
  const isLoadingCostCenter = useAppSelector((state) => state.apiCostCenter.isLoading)
  const costCenterMessage = useAppSelector((state) => state.apiCostCenter.message)
  const costCenterError = useAppSelector((state) => state.apiCostCenter.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [costCenterPerPage, setCostCenterPerPage] = useState(10)
  const [costCenterPage, setCostCenterPage] = useState(1)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [image, setImage] = useState<SetImageProps | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [colors, setColors] = useState<SetColorProps[]>(defaultColors)
  const [exportType, setExportType] = useState<'allPages' | 'currentPage'>('currentPage')

  const [exportData, setExportData] = useState<Array<any>>([])

  const [initialUser, setInitialUser] = useState<Partial<User>>({
    email: '',
    role: undefined
  })

  const [initialCostCenter, setInitialCostCenter] = useState<Partial<CostCenter>>({
    center: ''
  })
  const [selectedCompanyAddress, setSelectedCompanyAddress] = useState<Address | null>(null)
  const [selectedAddressType, setSelectedAddressType] = useState<AddressType | null>(null)
  const [copied, setCopied] = useState({
    text: '',
    role: '',
    type: ''
  })
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [cancelPressed, setCancelPressed] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation, confirmedNavigation] = useCallbackPrompt(showDialog)

  const token = currentUser?.token
  const userId = profile?.id

  const companyOwnerId = profile?.company?.owner?.id
  const role = profile?.role || userRoles.USER
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []
  const types = [
    { type: 'billing', label: 'Billing' },
    { type: 'delivery', label: 'Delivery' },
    { type: 'billingAndDelivery', label: 'Billing and Delivery' }
  ]

  const isOwner = companyOwnerId && userId === companyOwnerId

  const dispatch = useAppDispatch()

  const { companyId } = useParams()

  const userRoleSchema = object({
    role: string()
      .required('Role is required')
      .oneOf(editableRoles.map((role) => role.value))
  })

  const userSchema = object({
    salutation: string(),
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long'),
    username: string()
      .nullable()
      .min(2, 'Username is too short')
      .max(32, 'Username is too long')
      .matches(/^\S+$/, 'Enter a valid username'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().oneOf(countries)
  })

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street name is required')
      .max(32, 'Street name is too long'),
    zip: string()
      .required('Zip is required')
      .max(16, 'Zip is too long')
      .matches(/^[0-9]{2,16}$/, 'Enter a valid zip/postal code'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long'),
    type: string()
      .label('Type')
      .oneOf(types.map((type) => type.type))
      .required()
      .nullable()
  })

  const updateUserRole = (
    companyId: string,
    userId: string,
    token: string,
    user: any,
    signal: AbortSignal
  ) => {
    dispatch(updateRoleOfEmployee({ companyId, userId, token, user, signal }))
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleCostCenterShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setCostCenterPage(1)
    setCostCenterPerPage(Number(event.target.value))
  }

  const handleCostCenterPageChange = (page: number) => {
    setCostCenterPage(page)
  }

  const getInviteLink = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && companyId) {
      dispatch(getCompanyInviteLink({ id: companyId, token, signal }))
    }
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleUsersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && companyId && (isOwner || isAllowed(appModules.COMPANIES))) {
      dispatch(getCompanyUsers({ id: companyId, token, perPage, page, signal }))
    }
  }

  const handleCostCentersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && companyId && (isOwner || isAllowed(appModules.COSTCENTERS))) {
      dispatch(getCompanyCostCenters({ id: companyId, token, perPage: costCenterPerPage, page: costCenterPage, signal }))
    }
  }

  const handleFileUpload = (file: File) => {
    uploadCompanyImage(file)
  }

  const uploadCompanyImage = async (file: File) => {
    try {
      const fileSizeBytes = 5 * 1024 * 1024

      if (file && file.size > fileSizeBytes) {
        setUploadError('Select an image that is less than 5MB')
        return
      } else {
        setUploadError(null)
      }

      if (file && file.type.startsWith('image/')) {
        const imageFileName = fileNameGenerator(file?.name)

        const storageRefPath = `${firebaseStorageEnvironment}/companies/images/${imageFileName}`

        const storageRef = refFirebase(storageFirebase, storageRefPath)

        // upload file
        const uploadTask = uploadBytesResumableFirebase(storageRef, file)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadProgress(percentage)
          },
          (error) => {
            setUploadError(error.message)
            setUploadProgress(0)
            setUploadProgress(0)
          },
          () => {
            getDownloadURLFirebase(uploadTask.snapshot.ref).then((url) => {
              getMetadataFirebase(storageRef).then((metadata) => {
                const image = {
                  url,
                  filename: metadata.name,
                  size: metadata.size,
                  mimeType: metadata.contentType || file.type
                }
                setImage(image)
                setUploadError(null)
                setUploadProgress(0)
              })
            })
          }
        )
      }
    } catch (error: any) {
      setUploadError(error.message)
    }
  }

  const fileNameGenerator = (filename: string) => {
    const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
    return `${companyId}-${Date.now()}.${ext}`
  }

  const handleColorPickerChange = (
    color: string,
    index: number,
    name: ThemeColorName
  ) => {
    const controller = new AbortController()
    const signal = controller.signal
    const themeColors: Theme = {
      primaryColor: '',
      secondaryColor: '',
      backgroundColor: '',
      foregroundColor: '',
      accentColor: ''
    }
    let foregroundColor: string = '#ffffff'
    let accentColor: string = '#ffffff'
    setColors((prevState: SetColorProps[]) => {
      const foregroundIndex = prevState.findIndex((item) => item.name === 'foregroundColor')
      const accentIndex = prevState.findIndex((item) => item.name === 'accentColor')
      const updatedColors = [...prevState]
      updatedColors[index] = { ...prevState[index], color }
      if (name === 'backgroundColor') {
        foregroundColor = getForegroundColor(color)
        updatedColors[foregroundIndex] = { ...prevState[foregroundIndex], color: foregroundColor }
      }
      if (name === 'primaryColor') {
        accentColor = getForegroundColor(color)
        updatedColors[accentIndex] = { ...prevState[accentIndex], color: accentColor }
      }
      updatedColors.forEach((item) => {
        themeColors[item.name] = item.color
      })
      if (token && companyId) {
        dispatch(updateCompanyTheme({ token, id: companyId, company: { theme: themeColors }, signal }))
      }
      return updatedColors
    })
  }

  const handleThemeReset = () => {
    const controller = new AbortController()
    const signal = controller.signal
    token && companyId && dispatch(updateCompanyTheme({
      token,
      id: companyId,
      company: {
        theme: null
      },
      signal
    }))
    setColors(defaultColors)
  }

  const hasActiveSubscription = (): boolean => {
    if (role === userRoles.ADMIN) {
      return true
    }
    if (profile?.company?.subscriptions) {
      const now = dayjs().utc().toDate()
      return Boolean(profile.company.subscriptions.find(subscription => subscription.paymentStatus === 'paid' && (now >= dayjs(subscription.startDate).utc().toDate() && now <= dayjs(subscription.endDate).utc().toDate())))
    }
    return false
  }

  const getMappedUserData = () => {
    return users.map((user: User) => ({
      'User Id': user.id,
      Name: `${user.firstName} ${user.lastName}`,
      Company: company?.name,
      Email: user.email,
      'Date Created': dayjs(user.createdAt).format('DD/MM/YYYY'),
      Role: rolesObject[user.role],
      Verified: user.isVerified,
      Active: user.isActive
    }))
  }

  useEffect(() => {
    if (error && error.errors) {
      const type = error.statusCode === 404 ? 'info' : 'danger'
      const title = error.statusCode === 404 ? 'Information' : errorStatusCodes[error.statusCode]
      const payload = {
        title,
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (image && token) {
      const companyLogo = {
        logo: {
          url: image.url,
          filename: image.filename
        }
      }

      companyId && dispatch(uploadCompanyLogo({ token, id: companyId, company: companyLogo, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [image])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && (isOwner || isAllowed(appModules.COMPANIES))) {
      dispatch(getCompanyById({ id: companyId, token, signal }))
      dismissModal('companyAddressModal')
    }

    return () => {
      controller.abort()
    }
  }, [address, companyId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && (isOwner || isAllowed(appModules.COMPANIES))) {
      dispatch(getCompanyUsers({ id: companyId, token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && (isOwner || isAllowed(appModules.COSTCENTERS))) {
      dispatch(getCompanyCostCenters({ id: companyId, token, perPage: costCenterPerPage, page: costCenterPage, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [costCenterPerPage, costCenterPage])

  useEffect(() => {
    if (companyError && companyError?.errors?.message) {
      const isInvitation = companyError.errors.message.includes('invitation to register an account has been sent to')
      const title = isInvitation ? 'Invitation sent' : errorStatusCodes[companyError.statusCode]
      const message = companyError.errors.message
      const type = isInvitation ? 'success' : 'danger'
      const payload = {
        title,
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyError())
    }
  }, [companyError])

  useEffect(() => {
    if (costCenterError && costCenterError?.errors?.message) {
      const payload = {
        title: errorStatusCodes[costCenterError.statusCode],
        message: costCenterError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCostCenterError())
    }
  }, [costCenterError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [COMPANY_EMPLOYEE_UPDATE_SUCCESS_MESSAGE, ROLE_UPDATE_SUCCESS_MESSAGE]
    if (userMessage || (companyMessage && allowedMessages.includes(companyMessage))) {
      const payload = {
        title: 'Success',
        message: userMessage || companyMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetUserMessage())
      dispatch(resetCompanyMessage())
      dismissModal('confirmationModal')
      dismissModal('editUserModal')
      dismissModal('editUserRole')
      dismissModal('companyUserModal')
      if (token && companyId && (isOwner || isAllowed(appModules.COMPANIES))) {
        dispatch(getCompanyUsers({ id: companyId, token, perPage, page, signal }))
      }
    }
  }, [userMessage, companyMessage])

  useEffect(() => {
    const allowedMessages = [COMPANY_LOGO_UPLOAD_SUCCESS_MESSAGE, ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE]
    const controller = new AbortController()
    const signal = controller.signal

    if (companyMessage && allowedMessages.includes(companyMessage)) {
      const payload = {
        title: 'Success',
        message: companyMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyMessage())
      token && dispatch(getMyProfile({ token, signal }))
    }
  }, [companyMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (companyMessage === COST_CENTER_CREATION_SUCCESS_MESSAGE || costCenterMessage) {
      const payload = {
        title: 'Success',
        message: companyMessage || costCenterMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCostCenterMessage())
      dispatch(resetCompanyMessage())
      dismissModal('costCenterConfirmationModal')
      dismissModal('costCenterModal')
      dismissModal('costCenterEditModal')
      if (token && companyId && (isOwner || isAllowed(appModules.COSTCENTERS))) {
        dispatch(getCompanyCostCenters({ id: companyId, token, perPage: costCenterPerPage, page: costCenterPage, signal }))
      }
    }
  }, [companyMessage, costCenterMessage])

  useEffect(() => {
    if (profile?.company?.theme) {
      const arrayOfThemes: Array<ArrayOfThemesProps> = Object.entries(profile.company.theme).map(([key, value], index): any => ({ name: key, color: value, index }))
      setColors(arrayOfThemes)
    }
  }, [profile])

  useEffect(() => {
    if (role === userRoles.ADMIN) {
      if (company?.theme) {
        const arrayOfThemes: Array<ArrayOfThemesProps> = Object.entries(company.theme).map(([key, value], index): any => ({ name: key, color: value, index }))
        setColors(arrayOfThemes)
      } else {
        setColors(defaultColors)
      }
    }
  }, [company])

  useEffect(() => {
    if (company && company.addresses && company.addresses.length > 0) {
      if (!selectedAddressType) {
        const foundAddress = selectedCompanyAddress
          ? company.addresses.find((address) => address.type === selectedCompanyAddress.type)
          : company.addresses[0]
        if (foundAddress) {
          setSelectedCompanyAddress(foundAddress)
          setSelectedAddressType(foundAddress.type)
        }
      } else {
        const foundAddress = company.addresses.find((address) => address.type === selectedAddressType)
        foundAddress && setSelectedCompanyAddress(foundAddress)
      }
    } else {
      setSelectedCompanyAddress(null)
    }
  }, [company])

  useEffect(() => {
    const inviteLinkModalElement = document.getElementById('inviteLinkModal')
    const handleModalHidden = () => {
      setShowDialog(false)
      setCopied({
        text: '',
        role: '',
        type: ''
      })
    }

    if (inviteLinkModalElement) {
      inviteLinkModalElement.addEventListener('hidden.bs.modal', handleModalHidden)
    }

    return () => {
      if (inviteLinkModalElement) {
        inviteLinkModalElement.removeEventListener('hidden.bs.modal', handleModalHidden)
      }
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (companyMessage === INVITE_DOMAIN_CHECK_SUCCESS_MESSAGE && token && companyId) {
      dispatch(getCompanyInviteLink({ id: companyId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [companyMessage])

  useEffect(() => {
    if (cancelPressed) {
      setShowDialog(false)
      setCancelPressed(false)
    }

    if (confirmedNavigation) {
      dismissModal('inviteLinkModal')
    }
  }, [showDialog, showPrompt, cancelPressed, confirmedNavigation])

  return (
    <main className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand">
            <p className="h5">
              <i className="bi bi-building me-1"></i> Company Information
            </p>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              {companyId &&
                (isOwner || isAllowed(appModules.COMPANIES, READWRITE)) && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#companyAddressModal"
                    title={`${
                      selectedCompanyAddress ? 'Edit' : 'Add'
                    } Company Address`}
                    aria-label={`${
                      selectedCompanyAddress ? 'Edit' : 'Add'
                    } Company Address`}
                  >
                    <i
                      className={`bi ${
                        selectedCompanyAddress
                          ? 'bi-pencil-square'
                          : 'bi-plus-circle'
                      }`}
                    ></i>
                    <span className="ms-1 d-none d-md-inline-block">
                      {selectedCompanyAddress ? 'Edit' : 'Add'} Company Address
                    </span>
                  </button>
              )}
              {(isOwner ||
                isAllowed(appModules.COMPANYSUBSCRIPTIONS, READWRITE)) &&
                hasActiveSubscription() && (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#themeModal"
                    disabled={isLoading}
                  >
                    <i className="bi bi-palette"></i>
                    <span className="ms-1 d-none d-md-inline-block">
                      Theme Settings
                    </span>
                  </button>
              )}
            </ul>
          </div>
          {isLoading
            ? (<Progress marginTop />)
            : (<hr className="border border-primary border-1 opacity-50"></hr>)
          }
          <div className="mt-4">
            <div
              className="modal fade"
              id="companyAddressModal"
              tabIndex={-1}
              aria-labelledby="companyAddressModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="companyAddressModalLabel">
                      <i className="bi bi-pencil-square me-1"></i>{' '}
                      {selectedCompanyAddress ? 'Edit' : 'Add'}{' '}
                      {companyAddressNames[selectedAddressType as AddressType]}{' '}
                      Company Address
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {addingAddress && <Progress />}
                  <div className="modal-body">
                    <Formik
                      validationSchema={addressSchema}
                      enableReinitialize
                      initialValues={{
                        country: selectedCompanyAddress?.country || '',
                        city: selectedCompanyAddress?.city || '',
                        street: selectedCompanyAddress?.street || '',
                        zip: selectedCompanyAddress?.zip || '',
                        phone: selectedCompanyAddress?.phone || '',
                        addressAddition: selectedCompanyAddress?.addressAddition || '',
                        type: selectedCompanyAddress?.type || selectedAddressType || null
                      }}
                      onSubmit={(
                        {
                          country,
                          city,
                          street,
                          zip,
                          phone,
                          addressAddition,
                          type
                        },
                        actions
                      ) => {
                        const controller = new AbortController()
                        const signal = controller.signal
                        const address: Pick<Address, 'country' | 'city' | 'street' | 'zip' | 'phone' | 'addressAddition' | 'type'>
                        & { id?: string } = {
                          country,
                          city,
                          street,
                          zip,
                          phone,
                          addressAddition,
                          type
                        }
                        if (selectedCompanyAddress) {
                          address.id = selectedCompanyAddress.id
                        }
                        if (token && companyId) {
                          dispatch(
                            addCompanyAddress({
                              id: companyId,
                              token,
                              address,
                              signal
                            })
                          )
                        }
                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyCountry"
                                  className="form-label"
                                >
                                  Country
                                </label>
                                <select
                                  aria-label="Country"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.country}
                                  className={`form-select ${
                                    touched.country && errors.country
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyCountry"
                                  name="country"
                                  autoComplete="off"
                                >
                                  <option value="">Select Country</option>
                                  {countries.map(
                                    (country: string, index: number) => (
                                      <option key={index}>{country}</option>
                                    )
                                  )}
                                </select>
                                <div
                                  id="validationCompanyCountryFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.country || error?.errors?.country}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyCity"
                                  className="form-label"
                                >
                                  City
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  type="text"
                                  className={`form-control ${
                                    touched.city && errors.city
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyCity"
                                  name="city"
                                  placeholder="Enter city"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCompanyCityFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.city || error?.errors?.city}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyStreet"
                                  className="form-label"
                                >
                                  Street
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.street}
                                  type="text"
                                  className={`form-control ${
                                    touched.street && errors.street
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyStreet"
                                  name="street"
                                  placeholder="Enter street"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCompanyStreetFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.street || error?.errors?.street}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyZip"
                                  className="form-label"
                                >
                                  Zip
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.zip}
                                  type="text"
                                  className={`form-control ${
                                    touched.zip && errors.zip
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyZip"
                                  name="zip"
                                  placeholder="Enter Zip"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCompanyZipFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.zip || error?.errors?.zip}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyPhone"
                                  className="form-label"
                                >
                                  Phone
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  className={`form-control ${
                                    touched.phone && errors.phone
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  value={values.phone}
                                  id="companyPhone"
                                  name="phone"
                                  placeholder="Enter phone number"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCompanyPhoneFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.phone || error?.errors?.phone}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyAddressAddition"
                                  className="form-label"
                                >
                                  Address Addition
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.addressAddition}
                                  type="text"
                                  className={`form-control ${
                                    touched.addressAddition &&
                                    errors.addressAddition
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyAddressAddition"
                                  name="addressAddition"
                                  placeholder="Enter Address Addition"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCompanyAddressAdditionFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.addressAddition ||
                                    error?.errors?.addressAddition}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyAddressType"
                                  className="form-label"
                                >
                                  Type
                                </label>
                                <select
                                  aria-label="Company Address Type"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    handleChange(event)
                                    setSelectedAddressType(
                                      value as AddressType
                                    )
                                    const foundCompanyAddress =
                                      company?.addresses &&
                                      company?.addresses.find(
                                        (address) =>
                                          address.type ===
                                          (value !== '' ? value : null)
                                      )
                                    if (foundCompanyAddress) {
                                      setSelectedCompanyAddress(
                                        foundCompanyAddress
                                      )
                                    } else {
                                      setSelectedCompanyAddress(null)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.type || ''}
                                  className={`form-select ${
                                    errors.type && touched.type
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="companyAddressType"
                                  name="type"
                                  autoComplete="off"
                                >
                                  <option value="">Select Type</option>
                                  {types.map((type, index: number) => (
                                    <option key={index} value={type.type}>
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                                <div
                                  id="validationCompanyAddressTypeFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.type}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                              disabled={isSubmitting || isLoading}
                            >
                              <i className="bi bi-save"></i> Save
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                {isLoading
                  ? (<Loader repetition={5} />)
                  : (
                    <div className="border border-gray rounded px-2 py-1 h-100">
                      <table className="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <th className="text-muted">Name:</th>
                            <td className="text-muted">
                              {company?.name ||
                                profile?.company?.name ||
                                '---'}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-muted">Domain:</th>
                            <td className="text-muted">
                              {company?.domain ||
                                profile?.company?.domain ||
                                '---'}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-muted">Email:</th>
                            <td className="text-muted">
                              {company?.email ||
                                profile?.company?.email ||
                                '---'}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-muted">Phone:</th>
                            <td className="text-muted">
                              {company?.phone ||
                                profile?.company?.phone ||
                                '---'}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-muted">VAT:</th>
                            <td className="text-muted">
                              {company?.vat || profile?.company?.vat || '---'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    )}
              </div>

              {companyId && (isOwner || isAllowed(appModules.COMPANIES)) && (
                <div className="col-12 col-md-6">
                  {isLoading
                    ? (<Loader repetition={6} />)
                    : (
                      <div className="border border-gray rounded px-2 py-1 h-100">
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <th className="text-muted">Type:</th>
                              <td className="text-muted">
                                <select
                                  aria-label="Address Type"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    setSelectedAddressType(value as AddressType)
                                    const foundCompanyAddress =
                                      company?.addresses &&
                                      company?.addresses.find(
                                        (address) =>
                                          address.type ===
                                          (value !== '' ? value : null)
                                      )
                                    if (foundCompanyAddress) {
                                      setSelectedCompanyAddress(
                                        foundCompanyAddress
                                      )
                                    } else {
                                      setSelectedCompanyAddress(null)
                                    }
                                  }}
                                  value={
                                    selectedAddressType ||
                                    (selectedCompanyAddress?.type
                                      ? companyAddressNames[
                                          selectedCompanyAddress.type as AddressType
                                      ]
                                      : '')
                                  }
                                  className={
                                    'form-select form-select-sm m-0 py-0'
                                  }
                                  id="addressType"
                                  autoComplete="off"
                                >
                                  <option value="">Address Type</option>
                                  {types.map((type, index: number) => (
                                    <option key={index} value={type.type}>
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <th className="text-muted">Country:</th>
                              <td className="text-capitalize text-muted">
                                {selectedCompanyAddress?.country || '---'}
                              </td>
                            </tr>
                            <tr>
                              <th className="text-muted">City:</th>
                              <td className="text-capitalize text-muted">
                                {selectedCompanyAddress?.city || '---'}
                              </td>
                            </tr>
                            <tr>
                              <th className="text-muted">Street:</th>
                              <td className="text-capitalize text-muted">
                                {selectedCompanyAddress?.street || '---'}
                              </td>
                            </tr>
                            <tr>
                              <th className="text-muted">Address Addition:</th>
                              <td className="text-capitalize text-muted">
                                {selectedCompanyAddress?.addressAddition || '---'}
                              </td>
                            </tr>
                            <tr>
                              <th className="text-muted">Zip:</th>
                              <td className="text-capitalize text-muted">
                                {selectedCompanyAddress?.zip || '---'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {companyId && (isOwner || isAllowed(appModules.COMPANIES)) && (
        <>
          <div className="card mt-4">
            <div className="m-4">
              <div className="navbar navbar-expand mb-3">
                <p className="h5">
                  <i className="bi bi-people me-1"></i> Users
                </p>
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  {companyId &&
                    (isOwner || isAllowed(appModules.COMPANIES, READWRITE)) && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#inviteLinkModal"
                          title="Get Invite Link"
                          aria-label="Get Invite Link"
                          onClick={() => {
                            setShowDialog(true)
                            getInviteLink()
                          }}
                        >
                          <i className="bi bi-envelope-plus"></i>
                          <span className="ms-1 d-none d-md-inline-block">
                            Get Invite Link
                          </span>
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#companyUserModal"
                          title="Add User"
                          aria-label="Add User"
                          onClick={() => {
                            setInitialUser({
                              email: ''
                            })
                          }}
                        >
                          <i className="bi bi-plus-circle"></i>
                          <span className="ms-2 d-none d-md-inline-block">
                            Add User
                          </span>
                        </button>
                      </>
                  )}
                </ul>
                <button
                  type="button"
                  title="Export to CSV"
                  data-bs-toggle="modal"
                  data-bs-target="#exportToCSV"
                  onClick={() => {
                    setExportType('currentPage')
                    setExportData(getMappedUserData())
                  }}
                  aria-label="Export to CSV"
                  className="btn btn-outline-dark ms-2 ms-md-2"
                >
                  <i className="bi bi-filetype-csv"></i>
                </button>
                <button
                  type="button"
                  title="Refresh User"
                  aria-label="Refresh User"
                  className="btn btn-outline-dark ms-2 ms-md-2"
                  onClick={() => handleUsersRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </div>
              {isLoadingUsers
                ? (<Progress />)
                : (<hr className="border border-primary border-1 opacity-50"></hr>)
              }
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col" className="text-nowrap">
                        User ID
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col" className="text-nowrap">
                        Date Created
                      </th>
                      <th scope="col">Role</th>
                      <th scope="col">Verified</th>
                      <th scope="col">Active</th>
                      {companyId &&
                        (isOwner ||
                          isAllowed(appModules.COMPANIES, READWRITE)) && (
                          <th scope="col" className="text-center">
                            <div className="float-end">Actions</div>
                          </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingUsers
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => (
                            <SkeletonTableRow
                              key={n}
                              colSpan={
                                companyId &&
                                (isOwner ||
                                  isAllowed(appModules.COMPANIES, READWRITE))
                                  ? 8
                                  : 7
                              }
                              actionQuantity={
                                companyId &&
                                (isOwner ||
                                  isAllowed(appModules.COMPANIES, READWRITE))
                                  ? 3
                                  : 0
                              }
                            />
                          ))
                        )
                      : users.length > 0
                        ? (
                            users.map((user: User) => (
                              <tr
                                key={user.id}
                                className={
                                  initialUser.id === user.id ? 'table-primary' : ''
                                }
                              >
                                <td
                                  className="user-select-all"
                                  onClick={() =>
                                    navigator?.clipboard.writeText(String(user.id))
                                  }
                                >
                                  {String(user.id).substring(0, 8)}
                                </td>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td>{user.email}</td>
                                <td className="text-nowrap">
                                  {dayjs(user.createdAt).format('ll')}
                                </td>
                                <td>
                                  <span className={'badge text-bg-secondary'}>
                                    {rolesObject[user.role]}
                                  </span>
                                  {user.id === companyOwnerId && (
                                    <span className={'badge text-bg-info ms-1'}>
                                      {'Owner'}
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <span>
                                    {user.isVerified
                                      ? (
                                      <i className="bi bi-check-circle-fill text-success"></i>
                                        )
                                      : (
                                      <i className="bi bi-x-circle-fill text-danger"></i>
                                        )}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span>
                                    {user.isActive
                                      ? (
                                      <i className="bi bi-check-circle-fill text-success"></i>
                                        )
                                      : (
                                      <i className="bi bi-x-circle-fill text-danger"></i>
                                        )}
                                  </span>
                                </td>
                                {companyId &&
                                  (isOwner ||
                                    isAllowed(appModules.COMPANIES, READWRITE)) && (
                                    <td className="align-items-end">
                                      <div
                                        className="d-flex flex-row float-end"
                                        role="group"
                                        aria-label="Actions"
                                      >
                                        <button
                                          className="btn btn-outline-dark btn-round me-2"
                                          type="button"
                                          title="Edit User Role"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editUserRole"
                                          onClick={() => {
                                            setInitialUser(user)
                                          }}
                                          disabled={
                                            user.id === companyOwnerId ||
                                            user.id === userId ||
                                            user.role === userRoles.ADMIN
                                          }
                                        >
                                          <UserIcon />
                                        </button>
                                        <button
                                          className="btn btn-outline-dark btn-round me-2"
                                          type="button"
                                          title="Edit User"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editUserModal"
                                          onClick={() => {
                                            setInitialUser(user)
                                          }}
                                          disabled={
                                            !(
                                              user.id === userId ||
                                              role === userRoles.ADMIN
                                            )
                                          }
                                        >
                                          <PencilIcon />
                                        </button>
                                        <button
                                          className="btn btn-outline-danger btn-round"
                                          type="button"
                                          title="Remove User"
                                          data-bs-toggle="modal"
                                          data-bs-target="#confirmationModal"
                                          onClick={() => {
                                            setInitialUser(user)
                                          }}
                                          disabled={user.id === companyOwnerId}
                                        >
                                          <TrashIcon />
                                        </button>
                                      </div>
                                    </td>
                                )}
                              </tr>
                            ))
                          )
                        : (
                          <tr>
                            <td
                              colSpan={
                                companyId &&
                                (isOwner ||
                                  isAllowed(appModules.COMPANIES, READWRITE))
                                  ? 8
                                  : 7
                              }
                              className="text-center"
                            >
                              No users available yet
                            </td>
                          </tr>
                          )}
                  </tbody>
                </table>
              </div>
              <Pagination
                isLoading={isLoadingUsers}
                metadata={{
                  limit: usersMetadata.perPage,
                  total: usersMetadata.total,
                  offset: (usersMetadata.page - 1) * usersMetadata.perPage
                }}
                page={page}
                perPage={perPage}
                handlePageChange={handlePageChange}
                handleShowEntries={handleShowEntries}
                module="Users"
              />
            </div>

            <div
              className="modal fade"
              id="companyUserModal"
              tabIndex={-1}
              aria-labelledby="companyUserModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="companyUserModalLabel">
                      <i className={'bi bi-plus-circle me-1'}></i> {'Add User'}
                    </h5>
                    <button
                      type="button"
                      onClick={() => dispatch(resetCompanyError())}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {isLoadingUsers && <Progress />}
                  <div className="modal-body">
                    <CompanyUserEditor
                      id={String(companyId)}
                      initialUser={{
                        email: String(initialUser.email),
                        role: initialUser.role
                      }}
                      save={addCompanyUser}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="confirmationModal"
              tabIndex={-1}
              aria-labelledby="confirmationModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h5
                      className="modal-title text-danger"
                      id="confirmationModalLabel"
                    >
                      <i className="bi bi-trash text-danger me-2"></i>Confirm
                      Remove
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {isLoadingUsers && <Progress />}
                  <div className="modal-body">
                    <p>
                      Are you sure you want to remove
                      <span className="fw-bold">{` '${initialUser.email}' `}</span>
                      from the company?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        if (token && companyId !== null) {
                          const controller = new AbortController()
                          const signal = controller.signal
                          const user = {
                            email: initialUser.email,
                            actionType: 'remove'
                          }
                          dispatch(
                            addCompanyUser({
                              id: String(companyId),
                              token,
                              user,
                              signal
                            })
                          )
                        }
                      }}
                      disabled={isLoadingUsers}
                      data-bs-dismiss="modal"
                      aria-label="Remove"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="editUserRole"
              tabIndex={-1}
              aria-labelledby="editUserModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h5 className="modal-title" id="editUserModalLabel">
                      <i className="bi bi-pencil-square me-2"></i>Edit Employee
                      Role
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {isLoadingUsers && <Progress />}
                  <div className="modal-body">
                    <p>
                      Edit{' '}
                      <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                      with email<b>{` '${initialUser.email}'`}</b>
                    </p>
                    <Formik
                      validationSchema={userRoleSchema}
                      enableReinitialize
                      initialValues={{
                        role: initialUser?.role
                      }}
                      onSubmit={({ role }, actions) => {
                        const controller = new AbortController()
                        const signal = controller.signal
                        const user = {
                          role
                        }
                        const userId = initialUser.id
                        if (token && userId && companyId) {
                          updateUserRole(
                            companyId,
                            userId,
                            token,
                            user,
                            signal
                          )
                        }
                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <label
                                  htmlFor="employeeRole"
                                  className="form-label"
                                >
                                  User Role
                                </label>
                                <select
                                  aria-label="Type"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.role}
                                  className={`form-select ${
                                    errors.role && touched.role && errors.role
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="employeeRole"
                                  name="role"
                                >
                                  <option value="">Select Role</option>
                                  {editableRoles.map((role) => (
                                    <option key={role.value} value={role.value}>
                                      {role.text}
                                    </option>
                                  ))}
                                </select>
                                <div
                                  id="validationCampaignTypeFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.role}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                              disabled={isSubmitting || isLoadingUsers}
                            >
                              <i className="bi bi-save"></i> Save
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="editUserModal"
              tabIndex={-1}
              aria-labelledby="editUserLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h5 className="modal-title" id="editUserLabel">
                      <i className="bi bi-pencil-square me-2"></i>Edit Employee
                      Information
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {isUpdatingUser && <Progress />}
                  <div className="modal-body">
                    <p>
                      Edit{' '}
                      <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                      with email<b>{` '${initialUser.email}'`}</b>
                    </p>
                    <Formik
                      validationSchema={userSchema}
                      enableReinitialize
                      initialValues={{
                        salutation: initialUser?.salutation || '',
                        firstName: initialUser?.firstName || '',
                        lastName: initialUser?.lastName || ''
                      }}
                      onSubmit={(
                        { salutation, firstName, lastName },
                        actions
                      ) => {
                        const controller = new AbortController()
                        const signal = controller.signal
                        const user = {
                          salutation,
                          firstName,
                          lastName
                        }
                        const userId = initialUser.id
                        if (token && userId) {
                          dispatch(
                            updateUserById({ id: userId, token, user, signal })
                          )
                        }
                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="employeeSalutation"
                                  className="form-label"
                                >
                                  Salutation
                                </label>
                                <div className="input-group">
                                  <select
                                    aria-label="Salutation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.salutation}
                                    className={`form-select ${
                                      errors.salutation &&
                                      touched.salutation &&
                                      errors.salutation
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id="employeeSalutation"
                                    name="salutation"
                                    aria-describedby="button-addon2"
                                  >
                                    <option value="">Select Salutation</option>
                                    {salutations.map(
                                      (
                                        salutation: Salutation,
                                        index: number
                                      ) => (
                                        <option key={index}>
                                          {salutation.title}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div
                                  id="validationSalutationFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.salutation ||
                                    error?.errors?.salutation}
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">
                                <label
                                  htmlFor="employeeFirstname"
                                  className="form-label"
                                >
                                  First Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstName}
                                  type="text"
                                  className={`form-control ${
                                    errors.firstName &&
                                    touched.firstName &&
                                    errors.firstName
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="employeeFirstname"
                                  name="firstName"
                                  placeholder="Enter first name"
                                />
                                <div
                                  id="validationFirstNameFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.firstName || error?.errors?.firstName}
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">
                                <label
                                  htmlFor="employeeLastname"
                                  className="form-label"
                                >
                                  Last Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  type="text"
                                  className={`form-control ${
                                    errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="employeeLastname"
                                  name="lastName"
                                  placeholder="Enter last name"
                                />
                                <div
                                  id="validationLastNameFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.lastName || error?.errors?.lastName}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                              disabled={isSubmitting || isUpdatingUser}
                            >
                              <i className="bi bi-save"></i> Save
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                    <hr />
                    <div>
                      <AddressEditor
                        address={
                          initialUser?.addresses &&
                          initialUser.addresses.find(
                            (address) => address.type === 'delivery'
                          )
                        }
                        userId={String(initialUser?.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="inviteLinkModal"
            tabIndex={-1}
            aria-labelledby="inviteLinkModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="inviteLinkModalLabel">
                    Company Onboarding Invites
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {isLoadingInviteLink
                    ? (<Spinner />)
                    : (
                      <>
                        <div className="table-responsive">
                          <table className="table">
                            <caption>
                              <span className="fs-6 fw-light">
                                The <span className="fw-bold">link</span> is used to invite users who don&apos;t have accounts
                              </span><br />
                              <span className="fs-6 fw-light">
                                The <span className="fw-bold">code</span> is used to invite users who already have accounts
                              </span><br />
                              <span className="fs-6 fw-light">
                                The <span className="fw-bold">domain check</span> is used to match the user email against the primary or secondary domains
                              </span>
                            </caption>
                            <thead>
                              <tr>
                                <th scope="col">Role</th>
                                <th scope="col" className="text-nowrap col-1">Link</th>
                                <th scope="col" className="text-nowrap col-1">Code</th>
                                <th scope="col" className="text-nowrap col-1">Domain Check</th>
                                <th scope="col" className="col-1">Regenerate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row" className="text-nowrap">{rolesObject[userRoles.EMPLOYEE]}</th>
                                <td>
                                  {rolesInviteLinksAndCodes?.employee.shortInviteLink && <button
                                    type="button"
                                    onClick={() => {
                                      navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.employee.shortInviteLink)
                                      setCopied({
                                        text: rolesInviteLinksAndCodes?.employee.shortInviteLink,
                                        role: rolesObject[userRoles.EMPLOYEE],
                                        type: 'link'
                                      })
                                    }}
                                    className="btn btn-outline-secondary btn-sm me-1"
                                  >
                                    <i
                                      className="bi bi-copy"
                                      title="copy to clipboard"
                                    ></i>
                                  </button>}
                                </td>
                                <td>
                                  {rolesInviteLinksAndCodes?.employee.shortInviteCode && <button
                                    type="button"
                                    onClick={() => {
                                      navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.employee.shortInviteCode)
                                      setCopied({
                                        text: rolesInviteLinksAndCodes?.employee.shortInviteCode,
                                        role: rolesObject[userRoles.EMPLOYEE],
                                        type: 'code'
                                      })
                                    }}
                                    className="btn btn-outline-secondary btn-sm me-1"
                                  >
                                    <i
                                      className="bi bi-copy"
                                      title="copy to clipboard"
                                    ></i>
                                  </button>}
                                </td>
                                <td className="text-center">
                                  <div className="form-switch text-center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="employeeDomainCheck"
                                      onChange={() => {
                                        const controller = new AbortController()
                                        const signal = controller.signal
                                        if (rolesInviteLinksAndCodes && token && companyId) {
                                          dispatch(
                                            updateCompanyInviteDomainCheck({
                                              id: companyId,
                                              companyInviteToken: {
                                                roles: {
                                                  [userRoles.EMPLOYEE]: !rolesInviteLinksAndCodes.employee.isDomainCheckEnabled,
                                                  [userRoles.CAMPAIGNMANAGER]: rolesInviteLinksAndCodes.campaignManager.isDomainCheckEnabled,
                                                  [userRoles.COMPANYADMINISTRATOR]: rolesInviteLinksAndCodes.companyAdministrator.isDomainCheckEnabled
                                                }
                                              },
                                              token,
                                              signal
                                            })
                                          )
                                        }
                                      }}
                                      checked={rolesInviteLinksAndCodes?.employee.isDomainCheckEnabled}
                                    />
                                      <label className="form-check-label" htmlFor="employeeDomainCheck">
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCopied({
                                        text: '',
                                        role: '',
                                        type: ''
                                      })
                                      const controller = new AbortController()
                                      const signal = controller.signal
                                      if (token && companyId) {
                                        dispatch(
                                          updateCompanyInviteLink({
                                            id: companyId,
                                            companyInviteToken: { roles: [userRoles.EMPLOYEE] },
                                            token,
                                            signal
                                          })
                                        )
                                      }
                                    }}
                                    className="btn btn-outline-danger btn-sm me-1"
                                  >
                                    <i
                                      className="bi bi-arrow-clockwise"
                                      title="Regenerate invite token"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="text-nowrap">{rolesObject[userRoles.CAMPAIGNMANAGER]}</th>
                                <td>
                                  {rolesInviteLinksAndCodes?.campaignManager.shortInviteLink && <button
                                      type="button"
                                      onClick={() => {
                                        navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.campaignManager.shortInviteLink)
                                        setCopied({
                                          text: rolesInviteLinksAndCodes?.campaignManager.shortInviteLink,
                                          role: rolesObject[userRoles.CAMPAIGNMANAGER],
                                          type: 'link'
                                        })
                                      }}
                                      className="btn btn-outline-secondary btn-sm me-1"
                                    >
                                      <i
                                        className="bi bi-copy"
                                        title="copy to clipboard"
                                      ></i>
                                  </button>}
                                </td>
                                <td>
                                  {rolesInviteLinksAndCodes?.campaignManager.shortInviteCode && <button
                                      type="button"
                                      onClick={() => {
                                        navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.campaignManager.shortInviteCode)
                                        setCopied({
                                          text: rolesInviteLinksAndCodes?.campaignManager.shortInviteCode,
                                          role: rolesObject[userRoles.CAMPAIGNMANAGER],
                                          type: 'code'
                                        })
                                      }}
                                      className="btn btn-outline-secondary btn-sm me-1"
                                    >
                                      <i
                                        className="bi bi-copy"
                                        title="copy to clipboard"
                                      ></i>
                                  </button>}
                                </td>
                                <td>
                                  <div className="form-switch text-center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="campaignManagerDomainCheck"
                                      onChange={() => {
                                        const controller = new AbortController()
                                        const signal = controller.signal
                                        if (rolesInviteLinksAndCodes && token && companyId) {
                                          dispatch(
                                            updateCompanyInviteDomainCheck({
                                              id: companyId,
                                              companyInviteToken: {
                                                roles: {
                                                  [userRoles.EMPLOYEE]: rolesInviteLinksAndCodes.employee.isDomainCheckEnabled,
                                                  [userRoles.CAMPAIGNMANAGER]: !rolesInviteLinksAndCodes.campaignManager.isDomainCheckEnabled,
                                                  [userRoles.COMPANYADMINISTRATOR]: rolesInviteLinksAndCodes?.companyAdministrator.isDomainCheckEnabled
                                                }
                                              },
                                              token,
                                              signal
                                            })
                                          )
                                        }
                                      }}
                                      checked={rolesInviteLinksAndCodes?.campaignManager.isDomainCheckEnabled}
                                    />
                                      <label className="form-check-label" htmlFor="campaignManagerDomainCheck">
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCopied({
                                        text: '',
                                        role: '',
                                        type: ''
                                      })
                                      const controller = new AbortController()
                                      const signal = controller.signal
                                      if (token && companyId) {
                                        dispatch(
                                          updateCompanyInviteLink({
                                            id: companyId,
                                            companyInviteToken: { roles: [userRoles.CAMPAIGNMANAGER] },
                                            token,
                                            signal
                                          })
                                        )
                                      }
                                    }}
                                    className="btn btn-outline-danger btn-sm me-1"
                                  >
                                    <i
                                      className="bi bi-arrow-clockwise"
                                      title="Regenerate invite token"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="text-nowrap">{rolesObject[userRoles.COMPANYADMINISTRATOR]}</th>
                                <td>
                                  {rolesInviteLinksAndCodes?.companyAdministrator.shortInviteLink && <button
                                      type="button"
                                      onClick={() => {
                                        navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.companyAdministrator.shortInviteLink)
                                        setCopied({
                                          text: rolesInviteLinksAndCodes?.companyAdministrator.shortInviteLink,
                                          role: rolesObject[userRoles.COMPANYADMINISTRATOR],
                                          type: 'link'
                                        })
                                      }}
                                      className="btn btn-outline-secondary btn-sm me-1"
                                    >
                                      <i
                                        className="bi bi-copy"
                                        title="copy to clipboard"
                                      ></i>
                                  </button>}
                                </td>
                                <td>
                                  {rolesInviteLinksAndCodes?.companyAdministrator.shortInviteCode && <button
                                      type="button"
                                      onClick={() => {
                                        navigator?.clipboard.writeText(rolesInviteLinksAndCodes?.companyAdministrator.shortInviteCode)
                                        setCopied({
                                          text: rolesInviteLinksAndCodes?.companyAdministrator.shortInviteCode,
                                          role: rolesObject[userRoles.COMPANYADMINISTRATOR],
                                          type: 'code'
                                        })
                                      }}
                                      className="btn btn-outline-secondary btn-sm me-1"
                                    >
                                      <i
                                        className="bi bi-copy"
                                        title="copy to clipboard"
                                      ></i>
                                  </button>}
                                </td>
                                <td>
                                  <div className="form-switch text-center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="companyAdministratorDomainCheck"
                                      onChange={() => {
                                        const controller = new AbortController()
                                        const signal = controller.signal
                                        if (rolesInviteLinksAndCodes && token && companyId) {
                                          dispatch(
                                            updateCompanyInviteDomainCheck({
                                              id: companyId,
                                              companyInviteToken: {
                                                roles: {
                                                  [userRoles.EMPLOYEE]: rolesInviteLinksAndCodes.employee.isDomainCheckEnabled,
                                                  [userRoles.CAMPAIGNMANAGER]: rolesInviteLinksAndCodes.campaignManager.isDomainCheckEnabled,
                                                  [userRoles.COMPANYADMINISTRATOR]: !rolesInviteLinksAndCodes.companyAdministrator.isDomainCheckEnabled
                                                }
                                              },
                                              token,
                                              signal
                                            })
                                          )
                                        }
                                      }}
                                      checked={rolesInviteLinksAndCodes?.companyAdministrator.isDomainCheckEnabled}
                                    />
                                      <label className="form-check-label" htmlFor="companyAdministratorDomainCheck">
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCopied({
                                        text: '',
                                        role: '',
                                        type: ''
                                      })
                                      const controller = new AbortController()
                                      const signal = controller.signal
                                      if (token && companyId) {
                                        dispatch(
                                          updateCompanyInviteLink({
                                            id: companyId,
                                            companyInviteToken: { roles: [userRoles.COMPANYADMINISTRATOR] },
                                            token,
                                            signal
                                          })
                                        )
                                      }
                                    }}
                                    className="btn btn-outline-danger btn-sm me-1"
                                  >
                                    <i
                                      className="bi bi-arrow-clockwise"
                                      title="Regenerate invite token"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="overflow-scroll pb-3">
                          {
                            copied.role && (<span>
                              {`Copied ${copied.type} for ${(copied.role).toLowerCase()}: `}<span className="text-primary user-select-all">{copied.text}</span>
                              </span>)
                          }
                        </div>
                      </>
                      )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {companyId && (isOwner || isAllowed(appModules.COSTCENTERS)) && (
        <>
          <div className="card mt-4">
            <div className="m-4">
              <div className="navbar navbar-expand mb-3">
                <p className="h5">
                  <i className="bi bi-building me-1"></i> Cost Center
                </p>
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  {companyId &&
                    (isOwner ||
                      isAllowed(appModules.COSTCENTERS, READWRITE)) && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#costCenterModal"
                          onClick={() => {
                            setInitialCostCenter({
                              center: ''
                            })
                          }}
                        >
                          <i className="bi bi-plus-circle"></i>
                          <span className="ms-1 d-none d-md-inline-block">
                            Add Cost Center
                          </span>
                        </button>
                      </>
                  )}
                  <button
                    type="button"
                    title="Refresh Cost Center"
                    aria-label="Refresh Cost Center"
                    className="btn btn-outline-dark ms-2 ms-md-2"
                    onClick={() => handleCostCentersRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              </div>
              {isLoadingCompanyCostCenters || isLoadingCostCenter
                ? (
                <Progress />
                  )
                : (
                <hr className="border border-primary border-1 opacity-50"></hr>
                  )}
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Center</th>
                      <th scope="col">Date Created</th>
                      <th scope="col" className="text-center">
                        <div className="float-end">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingCompanyCostCenters
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => (
                        <SkeletonTableRow
                          key={n}
                          colSpan={3}
                          actionQuantity={2}
                        />
                          ))
                        )
                      : costCenters.length > 0
                        ? (
                            costCenters.map((costCenter: CostCenter) => (
                              <tr
                                key={costCenter.id}
                                className={
                                  initialCostCenter.id === costCenter.id
                                    ? 'table-primary'
                                    : ''
                                }
                              >
                                <td>{costCenter.center}</td>
                                <td className="text-nowrap">
                                  {dayjs(costCenter.createdAt).format('ll')}
                                </td>
                                <td className="text-center">
                                  <div
                                    className="d-flex flex-row float-end"
                                    role="group"
                                    aria-label="Actions"
                                  >
                                    <button
                                      className="btn btn-outline-dark btn-round me-2"
                                      type="button"
                                      title="Edit Cost Center"
                                      data-bs-toggle="modal"
                                      data-bs-target="#costCenterEditModal"
                                      onClick={() => {
                                        setInitialCostCenter(costCenter)
                                      }}
                                      disabled={
                                        !(
                                          isOwner ||
                                          isAllowed(appModules.COSTCENTERS, READWRITE)
                                        )
                                      }
                                    >
                                      <PencilIcon />
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Cost Center"
                                      data-bs-toggle="modal"
                                      data-bs-target="#costCenterConfirmationModal"
                                      onClick={() => {
                                        setInitialCostCenter(costCenter)
                                      }}
                                      disabled={
                                        !(
                                          isOwner ||
                                          isAllowed(appModules.COSTCENTERS, READWRITE)
                                        )
                                      }
                                    >
                                      <TrashIcon />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )
                        : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No cost centers available yet
                            </td>
                          </tr>
                          )}
                  </tbody>
                </table>
              </div>
              <Pagination
                isLoading={isLoadingCompanyCostCenters}
                metadata={{
                  limit: costCentersMetadata.perPage,
                  total: costCentersMetadata.total,
                  offset:
                    (costCentersMetadata.page - 1) *
                    costCentersMetadata.perPage
                }}
                page={costCenterPage}
                perPage={costCenterPerPage}
                handlePageChange={handleCostCenterPageChange}
                handleShowEntries={handleCostCenterShowEntries}
                module="CostCenters"
              />
            </div>
          </div>

          <div
            className="modal fade"
            id="costCenterModal"
            tabIndex={-1}
            aria-labelledby="costCenterModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="costCenterModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Add Cost Center'}
                  </h5>
                  <button
                    type="button"
                    onClick={() => dispatch(resetCompanyError())}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {(isLoadingCompanyCostCenters || isLoadingCostCenter) && (
                  <Progress />
                )}
                <div className="modal-body">
                  <CostCenterEditor
                    id={String(companyId)}
                    initialCostCenter={{ center: initialCostCenter.center }}
                    save={addCompanyCostCenter}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="costCenterEditModal"
            tabIndex={-1}
            aria-labelledby="costCenterEditModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="costCenterEditModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Edit Cost Center'}
                  </h5>
                  <button
                    type="button"
                    onClick={() => dispatch(resetCompanyError())}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {(isLoadingCompanyCostCenters || isLoadingCostCenter) && (
                  <Progress />
                )}
                <div className="modal-body">
                  <CostCenterEditor
                    id={String(initialCostCenter.id)}
                    initialCostCenter={{ center: initialCostCenter.center }}
                    save={editACostCenterById}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="costCenterConfirmationModal"
            tabIndex={-1}
            aria-labelledby="costCenterConfirmationModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <h5
                    className="modal-title text-danger"
                    id="costCenterConfirmationModalLabel"
                  >
                    <i className="bi bi-trash text-danger me-2"></i>Confirm
                    Delete
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {isLoadingCompanyCostCenters && <Progress />}
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete
                    <span className="fw-bold">{` '${initialCostCenter.center}' `}</span>
                    cost center?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (token && companyId !== null) {
                        const controller = new AbortController()
                        const signal = controller.signal
                        dispatch(
                          deleteACostCenterById({
                            id: String(initialCostCenter.id),
                            token,
                            signal
                          })
                        )
                      }
                    }}
                    disabled={isLoadingCompanyCostCenters}
                    data-bs-dismiss="modal"
                    aria-label="Delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="themeModal"
        tabIndex={-1}
        aria-labelledby="themeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="themeModalLabel">
                Company Theme
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <div className="col border border-gray rounded p-3 mb-3">
                <div className="d-flex h-100 justify-content-center">
                  <DropZone
                    handleUpload={handleFileUpload}
                    uploadProgress={uploadProgress}
                    uploadError={uploadError}
                  />
                </div>
              </div>
              <div className="col border border-gray rounded p-3">
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <h6>Select Theme Color</h6>
                  </div>
                </div>
                <div className="d-flex h-100 flex-column">
                  {colors &&
                    colors
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(
                        (
                          item: { color: string; name: ThemeColorName },
                          index: number
                        ) => {
                          if (
                            item.name !== 'secondaryColor' &&
                            item.name !== 'accentColor'
                          ) {
                            return (
                              <ColorPicker
                                onChange={handleColorPickerChange}
                                color={item.color}
                                name={item.name}
                                index={index}
                                key={index}
                              />
                            )
                          } else {
                            return null
                          }
                        }
                      )}
                </div>
                <div className="d-flex align-items-center mt-2">
                  <button
                    type="button"
                    title="Reset To Default"
                    aria-label="Reset To Default"
                    className="btn btn-outline-dark btn-sm me-2 p-0"
                    style={{
                      width: '25px',
                      height: '25px'
                    }}
                    onClick={() => handleThemeReset()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                  <span className="small text-secondary">Reset To Default</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalBox
        modalHeader="Export Users To CSV"
        id="exportToCSV"
      >
        <Formik
          initialValues={{
            export: ''
          }}
          onSubmit={async (values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="currentPage"
                    checked={exportType === 'currentPage'}
                    className="form-check-input"
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('currentPage')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">Current Page</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="allPages"
                    checked={exportType === 'allPages'}
                    className="form-check-input"
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('allPages')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">All Pages</span>
                </label>
              </div>
            </form>
          )}
        </Formik>
        <div className="mt-4 text-end">
          {exportType !== 'allPages'
            ? (
              <CSVDownloader
                btnText="Export"
                data={exportData}
                fileName={`Company users export ${company?.name || ''} ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  dispatch(getAllPagesForCompanyUsers(true))
                  dispatch(setExportCompanyId(company?.id))
                  dispatch(setExportCompanyName(company?.name))
                  dismissModal('exportToCSV')
                }}
              >
                Export
              </button>
              )}
        </div>
      </ModalBox>
      <DialogBox
        showDialog={typeof showPrompt === 'boolean' ? showPrompt : false}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </main>
  )
}

export default CompanyDetails
