import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import Navbar from '../components/layout/Navbar'
import SideNav from '../components/layout/SideNav'
import Toast from '../components/Toast'
import { useAppSelector } from '../store/hooks'
import ServiceWorkerToast from '../components/ServiceWorkerToast'
import MaintenanceModeAlert from '../components/MaintenanceModeAlert'
import CSVExporter from '../components/CSVExporter'

const PrivateRoutes = ({ isAllowed = true, redirectPath = '/', children }: { isAllowed?: boolean, redirectPath?: string, children?: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const location = useLocation()
  const closeSideNav = () => {
    const sidebarElement = document.body.querySelector('#sidebarToggle')
    if (sidebarElement) {
      document.body.classList.remove('sb-sidenav-toggled')
    }
  }

  return currentUser
    ? (
      <div className="sb-nav-fixed">
        <Navbar />
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <SideNav />
          </div>
          <div id="layoutSidenav_content" onClick={closeSideNav}>
            <main>
              <CSVExporter/>
              <Toast />
              <MaintenanceModeAlert />
              <ServiceWorkerToast />
              {
                !isAllowed && (<Navigate to={redirectPath} replace state={{ from: location }} />)
              }
              {
                children || <Outlet />
              }
            </main>
          </div>
        </div>
      </div>
      )
    : (
      <Navigate to="/login" replace state={{ from: location }} />
      )
}

export default PrivateRoutes
