import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  ADDRESS_ADDITION_SUCCESS_MESSAGE, CAMPAIGN_CREATION_SUCCESS_MESSAGE,
  COMPANY_DELETION_SUCCESS_MESSAGE, COMPANY_DOMAIN_VERIFICATION_STATUS_SUCCESS_MESSAGE,
  COMPANY_UPDATE_SUCCESS_MESSAGE, ROLE_UPDATE_SUCCESS_MESSAGE,
  COMPANY_EMPLOYEE_UPDATE_SUCCESS_MESSAGE,
  COST_CENTER_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CREATION_SUCCESS_MESSAGE,
  LEGAL_TEXT_CREATION_SUCCESS_MESSAGE,
  ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE,
  COMPANY_CREATION_SUCCESS_MESSAGE,
  COMPANY_LOGO_UPLOAD_SUCCESS_MESSAGE,
  INVITE_TOKEN_REGENERATION_SUCCESS_MESSAGE,
  COMPANY_THEME_SUCCESS_MESSAGE,
  ADDRESS_UPDATE_SUCCESS_MESSAGE,
  INVITE_DOMAIN_CHECK_SUCCESS_MESSAGE
} from '../../../constants/messages'
import CompanyService from '../../../services/api/CompanyService'
import { AccessPermission, ApiCompanyState, Company } from '../../../types'
import * as statusCodes from '../../../constants/statusCodes'
import { CAMPAIGNMANAGER, COMPANYADMINISTRATOR, EMPLOYEE } from '../../../constants/userRoles'

const initialState: ApiCompanyState = {
  isLoading: false,
  isLoadingCampaigns: false,
  isLoadingCostCenters: false,
  isLoadingInviteLink: false,
  isLoadingUsers: false,
  error: null,
  company: null,
  message: null,
  companies: [],
  createdCompany: null,
  address: null,
  addresses: [],
  campaign: null,
  campaigns: [],
  campaignsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  costCenter: null,
  costCenters: [],
  product: null,
  products: [],
  user: null,
  users: [],
  legalText: null,
  legalTexts: [],
  accessPermissions: [],
  accessPermission: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  costCenterMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  productMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  legalTextMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  accessPermissionMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  usersMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  addressesMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  inviteLink: null,
  inviteCode: null,
  rolesInviteLinksAndCodes: null,
  theme: null,
  exportCompanyId: '',
  exportCompanyName: '',
  areAllPagesForCompanyUsers: false
}

export const getCompanies = createAsyncThunk('api/companies/get', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanies(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createACompany = createAsyncThunk('api/company/post', async ({ token, company, signal }: { token: string, company: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.createACompany(token, company, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCompanyById = createAsyncThunk('api/company/update', async ({ id, token, company, signal }: { id: string, token: string, company: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateCompanyById(id, token, company, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyAddress = createAsyncThunk('api/company/post/address', async ({ id, token, address, signal }: { id: string, token: string, address: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyAddress(id, token, address, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyById = createAsyncThunk('api/company/get', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteCompanyById = createAsyncThunk('api/company/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.deleteCompanyById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyCampaign = createAsyncThunk('api/company/post/campaign', async ({ id, token, campaign, signal }: { id: string, token: string, campaign: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyCampaign(id, token, campaign, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyCampaigns = createAsyncThunk('api/company/get/campaigns', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyCampaigns(id, token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const requestDomainVerification = createAsyncThunk('api/company/domain/requestVerification', async ({ id, token, signal }: { id:string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.requestDomainVerification(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const verifyDomain = createAsyncThunk('api/company/domain/verify', async ({ id, token, signal }: { id:string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.verifyDomain(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyUser = createAsyncThunk('api/company/post/user', async ({ id, token, user, signal }: { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyUser(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyUsers = createAsyncThunk('api/company/get/users', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyUsers(id, token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateRoleOfEmployee = createAsyncThunk('api/company/user/update/role', async ({ companyId, userId, token, user, signal }: { companyId: string, userId: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateRoleOfEmployee(companyId, userId, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateEmployeeEmailVerification = createAsyncThunk('api/company/user/update/email-verification', async ({ companyId, userId, token, user, signal }: { companyId: string, userId: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateEmployeeEmailVerification(companyId, userId, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateDataOfEmployee = createAsyncThunk('api/company/user/update/data', async ({ companyId, userId, token, user, signal }: { companyId: string, userId: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateDataOfEmployee(companyId, userId, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addAddressToEmployee = createAsyncThunk('api/company/user/add/address', async ({ companyId, userId, token, address, signal }: { companyId: string, userId: string, token: string, address: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addAddressToEmployee(companyId, userId, token, address, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCompanyDomainVerificationStatus = createAsyncThunk('api/company/domain/verification-status/', async ({ companyId, token, company, signal }: { companyId: string, token: string, company: { isDomainVerified: boolean }, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateCompanyDomainVerificationStatus(companyId, token, company, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyAddresses = createAsyncThunk('api/company/get/addresses', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyAddresses(id, token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyCostCenter = createAsyncThunk('api/company/post/cost-centers', async ({ id, token, costCenter, signal }: { id: string, token: string, costCenter: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyCostCenter(id, token, costCenter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyCostCenters = createAsyncThunk('api/company/get/cost-centers', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyCostCenters(id, token, perPage, page, '', signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyProduct = createAsyncThunk('api/company/post/product', async ({ id, token, product, signal }: { id: string, token: string, product: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyProduct(id, token, product, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyProducts = createAsyncThunk('api/company/get/products', async ({ id, token, perPage, page, search, signal, orderBy, filter }: { id: string, token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy?: string, filter?: string }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyProducts(id, token, perPage, page, search, signal, orderBy, filter)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyLegalText = createAsyncThunk('api/company/post/legal-texts', async ({ id, token, costCenter, signal }: { id: string, token: string, costCenter: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyLegalText(id, token, costCenter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyLegalTexts = createAsyncThunk('api/company/get/legal-texts', async ({ id, token, perPage, page, filter, signal }: { id: string, token: string, perPage: number, page: number, filter: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyLegalTexts(id, token, perPage, page, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyInviteLink = createAsyncThunk('api/company/get/invite-link', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyInviteLink(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyAccessPermissions = createAsyncThunk('api/company/get/access-permissions', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyAccessPermissions(id, token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyAccessPermission = createAsyncThunk('api/company/post/access-permissions', async ({ id, token, accessPermission, signal }: { id: string, token: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.addCompanyAccessPermission(id, token, accessPermission, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCompanyInviteLink = createAsyncThunk('api/company/patch/invite-link', async ({ id, token, companyInviteToken, signal }: { id: string, token: string, companyInviteToken: { roles: string[] }, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateCompanyInviteLink(id, token, companyInviteToken, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCompanyInviteDomainCheck = createAsyncThunk('api/company/patch/invite-domain-check', async ({ id, token, companyInviteToken, signal }: { id: string, token: string, companyInviteToken: { roles: { [EMPLOYEE]: boolean, [CAMPAIGNMANAGER]: boolean, [COMPANYADMINISTRATOR]: boolean } }, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateCompanyInviteDomainCheck(id, token, companyInviteToken, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const uploadCompanyLogo = createAsyncThunk('api/company/patch/company-logo', async ({ token, id, company, signal }: { token: string, id: string, company: Pick<Company, 'logo'>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.uploadCompanyLogo(token, id, company, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
export const updateCompanyTheme = createAsyncThunk('api/company/patch/company-theme', async ({ token, id, company, signal }: { token: string, id: string, company: Pick<Company, 'theme'>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.updateCompanyTheme(token, id, company, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
const companySlice = createSlice({
  name: 'api/company',
  initialState,
  reducers: {
    resetCompanyError: (state) => {
      state.error = null
    },
    resetCompanyMessage: (state) => {
      state.message = null
    },
    setExportCompanyId: (state, { payload }) => {
      state.exportCompanyId = payload
    },
    getAllPagesForCompanyUsers: (state, { payload }) => {
      state.areAllPagesForCompanyUsers = payload
    },
    setExportCompanyName: (state, { payload }) => {
      state.exportCompanyName = payload
    },
    resetCompanyData: (state) => {
      state.error = null
      state.message = null
      state.company = null
      state.companies = []
      state.createdCompany = null
      state.address = null
      state.addresses = []
      state.campaign = null
      state.campaigns = []
      state.user = null
      state.users = []
      state.theme = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false
        state.companies = action.payload.companies
        state.metadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanies.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createACompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createACompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.createdCompany = action.payload.company
        state.message = COMPANY_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(createACompany.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.address = action.payload.address
        state.message = action.payload.statusCode === statusCodes.CREATED ? ADDRESS_ADDITION_SUCCESS_MESSAGE : ADDRESS_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyById.pending, (state) => {
        state.isLoading = true
        state.company = null
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.isLoading = false
        state.company = action.payload.company
        state.error = null
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteCompanyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCompanyById.fulfilled, (state) => {
        state.isLoading = false
        state.message = COMPANY_DELETION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(deleteCompanyById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateCompanyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompanyById.fulfilled, (state, action) => {
        state.isLoading = false
        state.company = action.payload.company
        state.message = COMPANY_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateCompanyById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyCampaign.pending, (state) => {
        state.isLoadingCampaigns = true
      })
      .addCase(addCompanyCampaign.fulfilled, (state, action) => {
        state.isLoadingCampaigns = false
        state.campaign = action.payload.campaign
        state.message = CAMPAIGN_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyCampaign.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingCampaigns = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyCampaigns.pending, (state) => {
        state.isLoadingCampaigns = true
      })
      .addCase(getCompanyCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.campaigns
        state.campaignsMetadata = action.payload.meta
        state.isLoadingCampaigns = false
        state.error = null
      })
      .addCase(getCompanyCampaigns.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingCampaigns = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyUser.pending, (state) => {
        state.isLoadingUsers = true
      })
      .addCase(addCompanyUser.fulfilled, (state, action) => {
        state.isLoadingUsers = false
        state.user = action.payload.user
        state.message = COMPANY_EMPLOYEE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingUsers = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyUsers.pending, (state) => {
        state.isLoadingUsers = true
      })
      .addCase(getCompanyUsers.fulfilled, (state, action) => {
        state.isLoadingUsers = false
        state.users = action.payload.users
        state.usersMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyUsers.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingUsers = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateRoleOfEmployee.pending, (state) => {
        state.isLoadingUsers = true
      })
      .addCase(updateRoleOfEmployee.fulfilled, (state, action) => {
        state.isLoadingUsers = false
        const {
          user
        } = action.payload
        state.user = user
        state.message = ROLE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateRoleOfEmployee.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingUsers = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateEmployeeEmailVerification.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateEmployeeEmailVerification.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.error = null
      })
      .addCase(updateEmployeeEmailVerification.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateDataOfEmployee.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDataOfEmployee.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.error = null
      })
      .addCase(updateDataOfEmployee.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addAddressToEmployee.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addAddressToEmployee.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          address
        } = action.payload
        state.address = address
        state.error = null
      })
      .addCase(addAddressToEmployee.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(requestDomainVerification.pending, (state) => {
        state.isLoading = true
      })
      .addCase(requestDomainVerification.fulfilled, (state, action) => {
        state.isLoading = false
        state.company = action.payload.company
        state.error = null
      })
      .addCase(requestDomainVerification.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(verifyDomain.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyDomain.fulfilled, (state, action) => {
        state.isLoading = false
        state.company = action.payload.company
        state.error = null
      })
      .addCase(verifyDomain.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateCompanyDomainVerificationStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompanyDomainVerificationStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.company = action.payload.company
        state.message = COMPANY_DOMAIN_VERIFICATION_STATUS_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateCompanyDomainVerificationStatus.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyAddresses.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyAddresses.fulfilled, (state, action) => {
        state.isLoading = false
        state.addresses = action.payload.addresses
        state.addressesMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyAddresses.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyCostCenter.pending, (state) => {
        state.isLoadingCostCenters = true
      })
      .addCase(addCompanyCostCenter.fulfilled, (state, action) => {
        state.isLoadingCostCenters = false
        state.costCenter = action.payload.costCenter
        state.message = COST_CENTER_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyCostCenter.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyCostCenters.pending, (state) => {
        state.isLoadingCostCenters = true
      })
      .addCase(getCompanyCostCenters.fulfilled, (state, action) => {
        state.isLoadingCostCenters = false
        state.costCenters = action.payload.costCenters
        state.costCenterMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyCostCenters.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingCostCenters = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload.product
        state.message = PRODUCT_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyProduct.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyProducts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action.payload.products
        state.productMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyProducts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyLegalText.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyLegalText.fulfilled, (state, action) => {
        state.isLoading = false
        state.legalText = action.payload.legalText
        state.message = LEGAL_TEXT_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyLegalText.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyLegalTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyLegalTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.legalTexts = action.payload.legalTexts
        state.legalTextMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyLegalTexts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyInviteLink.pending, (state) => {
        state.isLoadingInviteLink = true
      })
      .addCase(getCompanyInviteLink.fulfilled, (state, action) => {
        state.isLoadingInviteLink = false
        state.inviteLink = action.payload.company.shortInviteLink
        state.inviteCode = action.payload.company.shortInviteCode
        state.rolesInviteLinksAndCodes = action.payload.company.roles
        state.error = null
        state.message = null
      })
      .addCase(getCompanyInviteLink.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingInviteLink = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyAccessPermissions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyAccessPermissions.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermissions = action.payload.accessPermissions
        state.accessPermissionMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCompanyAccessPermissions.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyAccessPermission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyAccessPermission.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermission = action.payload.accessPermission
        state.message = ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCompanyAccessPermission.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateCompanyInviteLink.pending, (state) => {
        state.isLoadingInviteLink = true
      })
      .addCase(updateCompanyInviteLink.fulfilled, (state, action) => {
        state.isLoadingInviteLink = false
        state.inviteLink = action.payload.company.inviteLink
        state.inviteCode = action.payload.company.inviteCode
        state.rolesInviteLinksAndCodes = action.payload.company.roles
        state.message = INVITE_TOKEN_REGENERATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateCompanyInviteLink.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingInviteLink = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateCompanyInviteDomainCheck.pending, (state) => {
        state.isLoadingInviteLink = true
      })
      .addCase(updateCompanyInviteDomainCheck.fulfilled, (state, action) => {
        state.isLoadingInviteLink = false
        state.message = INVITE_DOMAIN_CHECK_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateCompanyInviteDomainCheck.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingInviteLink = false
        }
        state.error = action.payload
      })
    builder.addCase(uploadCompanyLogo.pending, (state, action) => {
      state.isLoading = true
    }).addCase(uploadCompanyLogo.fulfilled, (state, action) => {
      state.company = action.payload.company
      state.isLoading = false
      state.message = COMPANY_LOGO_UPLOAD_SUCCESS_MESSAGE
    }).addCase(uploadCompanyLogo.rejected, (state, action) => {
      if (action.payload !== undefined) {
        state.isLoading = false
      }
      state.error = action.payload
    })
    builder.addCase(updateCompanyTheme.pending, (state, action) => {
      state.isLoading = true
      state.message = null
    }).addCase(updateCompanyTheme.fulfilled, (state, action) => {
      state.theme = action.payload.company.theme
      state.company = action.payload.company
      state.message = COMPANY_THEME_SUCCESS_MESSAGE
      state.isLoading = false
    }).addCase(updateCompanyTheme.rejected, (state, action) => {
      if (action.payload !== undefined) {
        state.isLoading = false
      }
      state.error = action.payload
    })
  }
})

export const { resetCompanyError, resetCompanyData, resetCompanyMessage, getAllPagesForCompanyUsers, setExportCompanyId, setExportCompanyName } = companySlice.actions

const { reducer } = companySlice
export default reducer
