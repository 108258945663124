import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { getAllOrders, resetApiOrderData } from '../../store/reducers/api/orderReducer'
import { getAllJtlShippingMethods } from '../../store/reducers/api/shippingMethodReducer'
import TableHead from '../../components/Orders/Table/TableHead'
import TableBody from '../../components/Orders/Table/TableBody'
import {
  COMPANY_DOMAIN_UNVERIFIED_MESSAGE,
  EMAIL_UNVERIFIED_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import useDebounce from '../../utils/hooks/useDebounce'
import * as userRoles from '../../constants/userRoles'
import Pagination from '../../components/Pagination'
import Progress from '../../components/loaders/Progress'
import PendingOrders from '../../components/PendingOrders'
import { useNavigate, useParams } from 'react-router-dom'
import WelcomeModal from '../../components/Onboarding/WelcomeModal'
import LandingModal from '../../components/Onboarding/LandingModal'
import { getNormalizedOrderData } from '../../utils/getNormalizedExportData'
import OrderExportModal from '../../components/Orders/OrderExport/OrderExportModal'

dayjs.extend(localizedFormat)

const AllOrders = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiOutbound.isLoading)
  const orders = useAppSelector((state) => state.apiOutbound.orders)
  const metadata = useAppSelector((state) => state.apiOutbound.metadata)
  const error = useAppSelector((state) => state.apiOutbound.error)
  const onboardingProgress = useAppSelector((state) => state.onboarding.onboardingProgress)
  const totalPendingTasks = useAppSelector((state) => state.onboarding.totalPendingTasks)
  const token = currentUser?.token
  const firstName = currentUser?.firstName
  const lastName = currentUser?.lastName

  const storedPage = 1
  const storedPerPage = 10

  const [perPage, setPerPage] = useState(storedPerPage)
  const [page, setPage] = useState(storedPage)
  const [filter, setFilter] = useState('')
  const [exportType, setExportType] = useState<'allPages' | 'currentPage'>('currentPage')
  const [exportData, setExportData] = useState<Array<any>>([])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [selectedOutboundIds, setSelectedOutboundIds] = useState<Array<string>>([])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { outboundId } = useParams()

  const checked = selectedOutboundIds.length !== 0 && (selectedOutboundIds.length === orders.filter((order) => order.status !== 'Shipped').length)

  const columns = [
    {
      name: 'Select',
      className: 'cursor-pointer',
      onChange: () => {
        if (checked) {
          setSelectedOutboundIds([])
        } else {
          if (orders.filter((order) => order.status !== 'Shipped').map((order) => order.outboundId).length === 0) {
            const payload = {
              title: 'Info',
              message: 'Select orders that have not yet been shipped',
              type: 'info',
              isVisible: true,
              timestamp: dayjs().format('LT')
            }
            dispatch(setToast(payload))
          } else {
            setSelectedOutboundIds(orders.filter((order) => order.status !== 'Shipped').map((order) => order.outboundId))
          }
        }
      },
      checked
    },
    {
      name: 'Name',
      className: ''
    },
    {
      name: 'Details',
      className: ''
    },
    {
      name: 'Status',
      className: ''
    },
    {
      name: 'Date Shipped',
      className: ''
    },
    {
      name: 'Date Delivered',
      className: ''
    },
    {
      name: 'Created By',
      className: ''
    },
    {
      name: 'Total Boxes',
      className: 'text-center'
    },
    {
      name: 'Actions',
      className: 'text-end'
    }
  ]

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleShippingOrdersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    const search = outboundId ? (page !== 1 && (setPage(1), navigate('')), outboundId) : debouncedSearchTerm

    if (token) {
      dispatch(getAllOrders({ token, perPage, page, search, filter, signal }))
    }
  }

  const filterSchema = object({
    email: string().email('Enter a valid Email'),
    lastName: string()
      .min(2, 'Enter a valid Last Name')
      .max(32, 'Last Name is too long'),
    city: string()
      .min(2, 'Enter a valid City Name')
      .max(32, 'City Name is too long'),
    company: string()
      .min(2, 'Enter a valid Company Name')
      .max(32, 'Company Name is too long')
  })

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  useEffect(() => {
    dispatch(resetApiOrderData())
    const controller = new AbortController()
    const signal = controller.signal
    const search = outboundId ? ((page !== 1 && (setPage(1), navigate(''))), outboundId) : debouncedSearchTerm

    if (token) {
      dispatch(getAllOrders({ token, perPage, page, search, filter, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, filter, debouncedSearchTerm, outboundId])

  useEffect(() => {
    if (error && error?.errors.message === EMAIL_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: "Please verify your email address in the Profile section or by pressing the 'Get Started' button",
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }

    if (error && error?.errors.message === COMPANY_DOMAIN_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: "Please verify your company domain in the 'My Company' section",
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (currentUser?.token) {
      dispatch(
        getAllJtlShippingMethods({
          token: currentUser?.token,
          perPage: 50,
          page: 1,
          signal
        })
      )
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        {onboardingProgress !== 100 && (
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#welcomeModal"
            >
              <i className="bi bi-person me-1"></i>
              <span className="fw-semibold">Get Started</span>
              <span className="badge rounded-pill bg-danger ms-1">
                {totalPendingTasks}
              </span>
            </button>
          </div>
        )}
        <div className="card d-none">
          <div className="row p-4">
            <div className="col-xl-3 col-md-6">
              <div className="card bg-sent text-dark my-2 border-0">
                <div className="card-body text-center">
                  <h2>0</h2>
                  <p>boxes sent</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card bg-inprogress text-dark my-2 border-0">
                <div className="card-body text-center">
                  <h2>0</h2>
                  <p>in progress</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card bg-delivered text-dark my-2 border-0">
                <div className="card-body text-center">
                  <h2>0</h2>
                  <p>delivered</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card bg-returns text-dark my-2 border-0">
                <div className="card-body text-center">
                  <h2>0</h2>
                  <p>returns</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <PendingOrders />
        </div>
        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-0">
              <p className="h5">
                <i className="bi bi-truck me-1"></i> Shipping Orders
              </p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: ''
                    }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      if (page !== 1) {
                        setPage(1)
                        navigate('')
                      }
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            maxLength={24}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search && touched.search && errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="btnNavbarSearch"
                            name="search"
                            autoComplete="on"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarSearch"
                            type="submit"
                            disabled={isSubmitting}
                            title="Search"
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  data-bs-toggle="collapse"
                  data-bs-target="#filterOptions"
                  aria-expanded="false"
                  aria-controls="filterOptions"
                  title="Filter"
                  aria-label="Filter"
                >
                  <i className="fas fa-filter"></i>
                </button>
                <button
                  type="button"
                  title="Export to CSV"
                  data-bs-toggle="modal"
                  data-bs-target="#exportToCSV"
                  onClick={() => {
                    setExportType('currentPage')
                    const csvData = getNormalizedOrderData(orders)
                    setExportData(csvData)
                  }}
                  aria-label="Export to CSV"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                >
                  <i className="bi bi-filetype-csv"></i>
                </button>
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                  onClick={() => handleShippingOrdersRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </ul>
            </div>
            <div className="accordion" id="filterAccordion">
              <div>
                <div
                  id="filterOptions"
                  className="accordion-collapse collapse"
                  data-bs-parent="#filterAccordion"
                >
                  <div className="accordion-body">
                    <Formik
                      validationSchema={filterSchema}
                      enableReinitialize
                      initialValues={{
                        email: '',
                        lastName: '',
                        city: '',
                        company: ''
                      }}
                      onSubmit={(
                        { email, lastName, city, company },
                        actions
                      ) => {
                        const filterByEmail = email
                          ? `filter[email]=${email}`
                          : ''
                        const filterByLastName = lastName
                          ? `filter[lastname]=${lastName}`
                          : ''
                        const filterByCity = city ? `filter[city]=${city}` : ''
                        const filterByCompany = company
                          ? `filter[company]=${company}`
                          : ''

                        const filterString = [
                          filterByEmail,
                          filterByLastName,
                          filterByCity,
                          filterByCompany
                        ]
                          .filter((filter: string) => filter)
                          .join('&')

                        setFilter(filterString)
                        if (page !== 1) {
                          setPage(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  type="email"
                                  className={`form-control ${
                                    errors.email &&
                                    touched.email &&
                                    errors.email
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputEmail"
                                  name="email"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationEmailFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.email}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputLastName"
                                  className="form-label"
                                >
                                  Last Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  type="text"
                                  className={`form-control ${
                                    errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputLastName"
                                  name="lastName"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationLastNameFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.lastName}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputCity"
                                  className="form-label"
                                >
                                  City
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  type="text"
                                  className={`form-control ${
                                    errors.city && touched.city && errors.city
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputCity"
                                  name="city"
                                  autoComplete="on"
                                />
                                <div
                                  id="validationCityFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.city}
                                </div>
                              </div>
                            </div>
                            {currentUser?.role === userRoles.ADMIN && (
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="inputCompany"
                                    className="form-label"
                                  >
                                    Company
                                  </label>
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company}
                                    type="text"
                                    className={`form-control ${
                                      errors.company &&
                                      touched.company &&
                                      errors.company
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id="inputCompany"
                                    name="company"
                                    autoComplete="on"
                                  />
                                  <div
                                    id="validationCompanyFeedback"
                                    className="invalid-feedback"
                                  >
                                    {errors.company}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-secondary me-2"
                              disabled={isSubmitting}
                              onClick={() => {
                                resetForm()
                                setFilter('')
                                if (page !== 1) {
                                  setPage(1)
                                  navigate('')
                                }
                              }}
                            >
                              Clear
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              {`Apply Filter${
                                values.email && values.lastName ? 's' : ''
                              }`}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-2"></div>
            {isLoading
              ? (<Progress marginTop />)
              : (<hr className="border border-primary border-1 opacity-50"></hr>)
            }
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <TableHead columns={columns} />
                <TableBody
                  orders={orders}
                  isLoading={isLoading}
                  type="shipping-order"
                  colSpan={columns.length}
                  selectedOutboundIds={selectedOutboundIds}
                  setSelectedOutboundIds={setSelectedOutboundIds}
                />
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: (metadata.page - 1) * metadata.perPage
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              module="Orders"
              isTrackingPage
            />
          </div>
        </div>
      </div>
      {/* Modals */}
      <WelcomeModal firstName={firstName} lastName={lastName} />
      <LandingModal />
      <OrderExportModal exportType={exportType} setExportType={setExportType} exportData={exportData} component="orders" />
    </main>
  )
}

export default AllOrders
