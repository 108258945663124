import React from 'react'
import Progress from './loaders/Progress'
type ModalBoxProps = {
  id?: string
  ariaLabelledBy?: string
  hidden?: boolean,
  staticBackdrop?: boolean
  modalHeaderIcon?: React.ReactNode,
  modalHeader?: string,
  isLoading?: boolean,
  children?: React.ReactNode
  modalSize?: 'modal-sm'| 'modal-lg'| 'modal-xl'
}
const ModalBox = ({ id, ariaLabelledBy, hidden, staticBackdrop, modalHeaderIcon, modalHeader, isLoading, children, modalSize }: ModalBoxProps) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      aria-labelledby={ariaLabelledBy}
      aria-hidden={hidden}
      data-bs-backdrop={staticBackdrop && 'static'}
    >
    <div className={`modal-dialog ${modalSize}`}>
      <div className="modal-content">
        <div className="modal-header text-center">
          <h5 className="modal-title">
            {modalHeaderIcon} {modalHeader}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {isLoading && <Progress />}
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  </div>)
}

export default ModalBox
