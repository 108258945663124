import { ShoppingCartAddress } from '../types'

export const generateAddressText = (address: ShoppingCartAddress): string => {
  const formattedAddress: any = {
    companyName: address.companyName ?? '',
    firstName: address.firstName ?? '',
    lastName: address.lastName ?? '',
    street: address.street ?? '',
    zip: address.zip ?? '',
    city: address.city ?? '',
    country: address.country ?? ''
  }

  const addressComponents = []
  for (const key in formattedAddress) {
    if (formattedAddress[key]) {
      addressComponents.push(formattedAddress[key])
    }
  }

  return `${addressComponents.join(', ')}`
}
