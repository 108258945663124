import React, { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import {
  resetShoppingCartBundles,
  resetShoppingCartLeftOverProducts,
  setIsBulkOrder,
  setShoppingAddresses,
  setShoppingCartBundles,
  setShoppingCartLeftOverProducts
} from '../../../store/reducers/shoppingCartReducer'
import BundleConfiguratorItem from './BundleConfiguratorItem'
import { formatPrice } from '../../../utils/formatPrice'
import { calculateGraduatedPrice } from '../../../utils/calculateGraduatedPrice'
import Placeholder from '../../../assets/images/placeholder.png'
import { ShoppingCartProduct } from '../../../types'

const BundleConfigurator = () => {
  const dispatch = useAppDispatch()
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)

  const handleCreateBundle = () => {
    if (shoppingCartProducts.length > 0) {
      const payload = {
        bundles: [{
          id: uuidv1(),
          products: shoppingCartProducts.map(product => ({ ...product, quantity: 1 })),
          quantity: 1,
          isComplete: false
        }]
      }
      dispatch(setShoppingCartBundles(payload))
    }
  }

  const handleAddAnotherBundle = () => {
    if (shoppingCartProducts.length > 0) {
      const newBundle = {
        id: uuidv1(),
        products: [...shoppingCartLeftOverProducts.map(product => ({ ...product, quantity: 1 }))],
        quantity: 1,
        isComplete: false
      }
      const updatedBundles = [...shoppingCartBundles, newBundle]
      dispatch(setShoppingCartBundles({ bundles: updatedBundles }))
    }
  }

  const getLeftOverProducts = () => {
    const totalBundleProducts = shoppingCartBundles.flatMap((shoppingCartBundle) => {
      return shoppingCartBundle.products.map(product => ({
        ...product,
        quantity: product.quantity * shoppingCartBundle.quantity
      }))
    }).reduce((acc: ShoppingCartProduct[], product) => {
      const existingProduct = acc.find((p: ShoppingCartProduct) => p.id === product.id)
      if (existingProduct) {
        existingProduct.quantity += product.quantity
      } else {
        acc.push(product)
      }
      return acc
    }, [])

    const leftOverProducts = shoppingCartProducts
      .filter((shoppingCartProduct) => {
        const foundBundleProduct = totalBundleProducts.find((totalBundleProduct) => totalBundleProduct.id === shoppingCartProduct.id)
        return !foundBundleProduct || ((shoppingCartProduct.quantity - foundBundleProduct.quantity) > 0)
      })
      .map((filteredShoppingCartProduct) => {
        const foundBundleProduct = totalBundleProducts.find((totalBundleProduct) => totalBundleProduct.id === filteredShoppingCartProduct.id)

        return {
          ...filteredShoppingCartProduct,
          quantity: foundBundleProduct
            ? filteredShoppingCartProduct.quantity - foundBundleProduct.quantity
            : filteredShoppingCartProduct.quantity
        }
      })

    const payload = {
      leftOverProducts
    }
    dispatch(setShoppingCartLeftOverProducts(payload))
  }

  const updateLeftOverProductIdsAndBundleIdsInShippingAddress = () => {
    if (shoppingCartAddresses.length === 1) {
      const shoppingCartAddress = shoppingCartAddresses[0]
      const updatedShoppingCartAddress = {
        ...shoppingCartAddress,
        assignedLeftOverProductIds: shoppingCartLeftOverProducts.map((product) => product.id),
        assignedBundleIds: shoppingCartBundles.map((bundle) => bundle.id)
      }
      const payload = {
        addresses: [updatedShoppingCartAddress]
      }
      dispatch(setShoppingAddresses(payload))
    }
  }

  const removeLeftOverProductIdsAndBundleIdsFromShoppingCartAddresses = () => {
    const updatedShippingAddresses = shoppingCartAddresses.map(address => ({
      ...address,
      assignedLeftOverProductIds: [],
      assignedBundleIds: []
    }))
    const payload = {
      addresses: updatedShippingAddresses
    }
    dispatch(setShoppingAddresses(payload))
  }

  useEffect(() => {
    if (shoppingCartBundles.length > 0) {
      getLeftOverProducts()
      updateLeftOverProductIdsAndBundleIdsInShippingAddress()
    } else {
      dispatch(resetShoppingCartLeftOverProducts())
      removeLeftOverProductIdsAndBundleIdsFromShoppingCartAddresses()
    }
  }, [shoppingCartProducts, shoppingCartBundles])

  useEffect(() => {
    if (shoppingCartBundles.length > 0) {
      dispatch(setIsBulkOrder(false))
    } else {
      dispatch(setIsBulkOrder(true))
    }
  }, [shoppingCartBundles.length])

  useEffect(() => {
    if (shoppingCartProducts.length === 0) {
      dispatch(resetShoppingCartBundles())
      removeLeftOverProductIdsAndBundleIdsFromShoppingCartAddresses()
    }
  }, [shoppingCartProducts.length])

  useEffect(() => {
    updateLeftOverProductIdsAndBundleIdsInShippingAddress()
  }, [shoppingCartLeftOverProducts, shoppingCartAddresses.length])

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <div className="border rounded p-2">
            <h6 className="fw-bold mb-2">Would you like to order in bulk or create a bundle?</h6>
            <p className="mb-3">You can select products and combine them into a customised bundle</p>
            <div className="d-flex gap-2">
              <button
                className={`btn ${isBulkOrder ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => {
                  dispatch(resetShoppingCartBundles())
                  dispatch(setIsBulkOrder(true))
                }}
                disabled={shoppingCartProducts.length === 0}
              >
                Order in Bulk
              </button>
              <button
                className={`btn ${isBulkOrder ? 'btn-outline-primary' : 'btn-primary'}`}
                onClick={() => {
                  handleCreateBundle()
                  dispatch(setIsBulkOrder(false))
                }}
                disabled={shoppingCartProducts.length === 0}
              >
                Create a Bundle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="container">
          {shoppingCartBundles.map((shoppingCartBundle, index) => (
            <BundleConfiguratorItem key={`${shoppingCartBundle.id}-bundle-configurator-item`} shoppingCartBundleId={shoppingCartBundle.id} index={index} />
          ))}
        </div>
      </div>
      {shoppingCartLeftOverProducts.length > 0 && (
        <div className="row">
          <div className="col">
            <div className="card shadow-none">
              <div className="card-body">
                <h6 className="card-title">Leftover Products</h6>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                  {shoppingCartLeftOverProducts.map((product) => (
                    <div key={product.id} className="col">
                      <div className="card h-100">
                        <img
                          src={product.pictures && product.pictures.length > 0 ? product.pictures[0].publicUrl : Placeholder}
                          className="card-img-top"
                          alt={product.name}
                          style={{ objectFit: 'cover', height: '200px' }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{product.name}</h5>
                          <p className="card-text">
                            {formatPrice('EUR', navigator.language).format(
                              calculateGraduatedPrice(product.graduatedPrices, product.quantity, product.netRetailPrice.amount) * product.quantity
                            )}
                          </p>
                          <p className="card-text">Quantity: {product.quantity}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(shoppingCartBundles.length > 0 && shoppingCartLeftOverProducts.length > 0) && (
        <div className="row mt-4 d-none">
          <div className="col">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleAddAnotherBundle()}
            >
              Add Another Bundle
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default BundleConfigurator
